import {ICoin} from '../types/coin';
import {AxiosError} from 'axios';

export const convertPrice = (price: number, coin: ICoin) => {
    let amount = price / coin.priceInUsd;
    const pow = Math.pow(10, coin.decimals);
    amount = Math.ceil(amount * pow) / pow;
    return amount.toFixed(coin.decimals);
};

export const toFixedFloor = (num: number, fixed: number) => {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
};

export const getErrorMessageFromAxios = (error: AxiosError | string) => {
    let message = '';
    if (typeof error === 'string') {
        message = error;
    } else {
        const responseErrors: any[] = Object.values(
            error.response?.data?.errors || [],
        );
        if (
            responseErrors.length &&
            typeof error.response?.data?.errors !== 'string' &&
            (typeof responseErrors[0][0] === 'string' ||
                typeof responseErrors[0] === 'string')
        ) {
            message =
                typeof responseErrors[0][0] === 'string'
                    ? responseErrors[0][0]
                    : typeof responseErrors[0] === 'string'
                    ? responseErrors[0]
                    : '';
        } else {
            message = error.response?.data?.message;
        }
    }
    return message;
};
