import React, {useState, useEffect} from 'react';
import {
    Grid,
    makeStyles,
    Typography,
    Box,
    ButtonGroup,
    MenuItem,
    IconButton,
} from '@material-ui/core';
import {HeadingTab} from './HeadingTab';
import Button from '../../components/Button';
import PlanItem from '../../components/PlanItem';
import Table from '../../components/Table';
import SelectBox from '../../components/SelectBox';

import {pushTo} from '../../helpers/history';
import {convertPrice} from '../../helpers/common';
import {PATH} from '../../constants/path';
import {findIndex} from 'lodash';

import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {
    IMiningPlanRadiantPlan,
    IMiningPlanRadiantMark,
    IMiningPlan,
} from '../../types/plan';
import {ITempOrder} from '../../types/order';
import {ICoin} from '../../types/coin';
import {isEmpty, isNull, isArray} from 'lodash';
import {isJsonString} from '../../helpers/validate';
import TrashIcon from '../../assets/images/icons/trash.png';
import {MOBILE_WIDTH} from '../../constants/common';

const useStyles = makeStyles((theme) => ({
    selectPlanContainer: {
        '& .plan-heading': {
            textAlign: 'center',
            '& .title': {
                fontSize: 32,
                fontWeight: 'bold',
                letterSpacing: 0.8,
                color: theme.palette.neutral.white,
                marginBottom: 20,
                '&.mobile': {
                    fontSize: 24,
                },
            },
            '& .sub-title': {
                fontSize: 14,
                fontWeight: 500,
                lineHeight: 1.71,
                letterSpacing: 0.24,
                color: theme.palette.neutral.gray,
                marginTop: 17,
                marginBottom: 22,
            },
            '& .plan-selector': {
                boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
                border: `2px solid ${theme.palette.neutral.lighter}`,
                '&.mobile': {
                    maxWidth: '100%',
                    overflow: 'auto',
                    '& button': {
                        fontSize: `14px !important`,
                        minWidth: 172,
                        height: 43,
                    },
                },
                '& button': {
                    fontSize: `20px !important`,
                    minWidth: 291,
                    height: 54,
                    letterSpacing: `0.5px !important`,
                    lineHeight: `1.5 !important`,
                    fontWeight: 'bold !important',
                    backgroundColor: theme.palette.neutral.main,
                    color: theme.palette.neutral.white,
                    borderRadius: 0,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                    },
                    '&.active': {
                        backgroundColor: theme.palette.primary.main,
                    },
                },
            },
            '& .MuiButtonGroup-groupedContainedPrimary:not(:last-child)': {
                borderColor: theme.palette.neutral.lighter,
            },
        },
        '& .plan-details': {
            position: 'relative',
            marginTop: 48,
            padding: '19px 33px 35px',
            borderRadius: 4,
            backgroundColor: theme.palette.neutral.hashrate,
            '&.mobile': {
                padding: '12px 16px 16px',
                '& .title-wrapper': {
                    '& .title': {
                        height: 32,
                    },
                },
                '& .item': {
                    marginTop: 16,
                    flexDirection: 'column',
                    padding: 16,
                    '& .plan-thumbnail': {
                        width: '100%',
                        marginRight: 0,
                        marginTop: 6,
                        marginBottom: 17,
                    },
                    '& .plan': {
                        width: '100%',
                        '& .title': {
                            fontSize: 16,
                            textAlign: 'center',
                        },
                        '& .sub-title': {
                            fontSize: 14,
                            marginBottom: 0,
                        },
                    },
                    '& .power-slider': {
                        paddingBottom: 25,
                    },
                    '& .power-overview': {
                        marginTop: 16,
                        flexDirection: 'column',
                        '& .power-value': {
                            textAlign: 'center',
                            marginBottom: 16,
                        },
                    },
                },
            },
            '& .title-wrapper': {
                textAlign: 'center',
                position: 'absolute',
                width: '100%',
                left: 0,
                top: -20,
                '& .title': {
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: 1.88,
                    letterSpacing: 0.4,
                    color: theme.palette.primary.dark,
                    backgroundColor: theme.palette.neutral.white,
                    minWidth: 197,
                    height: 40,
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 4,
                },
            },
            '& .item': {
                marginTop: 32,
                borderRadius: 4,
                backgroundColor: theme.palette.neutral.dark,
                padding: 32,
                display: 'flex',
                justifyContent: 'center',
                '& .plan-thumbnail': {
                    width: '25%',
                    marginRight: 32,
                    '& img': {
                        width: '100%',
                    },
                },
                '& .plan': {
                    width: '75%',
                    '& .title': {
                        fontSize: 20,
                        fontWeight: 'bold',
                        lineHeight: 1.5,
                        letterSpacing: 0.5,
                        color: theme.palette.neutral.white,
                        marginBottom: 12,
                    },
                    '& .sub-title': {
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: 1.5,
                        letterSpacing: 0.5,
                        color: theme.palette.neutral.white,
                        marginBottom: 24,
                    },
                },
                '& .power-slider': {
                    paddingBottom: 52,
                    borderBottom: `1px solid ${theme.palette.neutral.purple3}`,
                    position: 'relative',
                    '& .power-range': {
                        color: theme.palette.neutral.white,
                        '& .MuiSlider-rail': {
                            height: 4,
                            opacity: 1,
                            borderRadius: 2,
                        },
                        '& .MuiSlider-thumb': {
                            height: 22,
                            width: 22,
                            color: theme.palette.neutral.white,
                        },
                        '& svg': {
                            width: 20,
                            height: 20,
                            color: theme.palette.primary.main,
                        },
                    },
                    '& .power-label': {
                        position: 'absolute',
                        top: 35,
                        fontSize: 12,
                        fontWeight: 500,
                        lineHeight: 1.67,
                        letterSpacing: 0.38,
                        color: theme.palette.neutral.white,
                        whiteSpace: 'nowrap',
                        '&.power-min': {
                            left: 0,
                        },
                        '&.power-max': {
                            right: 0,
                        },
                    },
                },
                '& .power-overview': {
                    marginTop: 32,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '& .power-value': {
                        '& .value': {
                            fontSize: 20,
                            fontWeight: 'bold',
                            lineHeight: 1.5,
                            letterSpacing: 0.5,
                            color: theme.palette.primary.main,
                            marginBottom: 8,
                        },
                        '& .price': {
                            fontSize: 14,
                            fontWeight: 500,
                            lineHeight: 1.71,
                            letterSpacing: 0.14,
                            color: theme.palette.neutral.white,
                        },
                    },
                    '& .add-plan-btn': {
                        width: 282,
                        height: 44,
                        backgroundColor: theme.palette.primary.dark,
                    },
                },
            },
        },
        '& .visibility': {
            display: 'flex',
        },
        '& .hidden': {
            display: 'none',
        },
        '& .second-section': {
            marginTop: 13,
            '& .title': {
                fontSize: 32,
                fontWeight: 'bold',
                letterSpacing: 0.6,
                color: theme.palette.neutral.white,
                '&.mobile': {
                    fontSize: 24,
                },
            },
        },
        '& .order-sumary': {
            '& .total': {
                height: 64,
                borderRadius: 4,
                backgroundColor: theme.palette.neutral.hashrate,
                marginTop: 16,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .text': {
                    marginLeft: 37,
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: 1.5,
                    letterSpacing: 0.3,
                    color: theme.palette.neutral.gray,
                },
                '& .price': {
                    marginRight: 19,
                    fontSize: 20,
                    fontWeight: 'bold',
                    lineHeight: 1.5,
                    letterSpacing: 0.5,
                    color: theme.palette.neutral.white,
                },
            },
        },
        '& .btn-go-order': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .coin-selection': {
            '& > div': {
                padding: '2px 15px !important',
            },
        },
    },
    removePlan: {
        padding: 0,
    },
}));

export const SelectPlans = () => {
    const classes = useStyles();
    const {plans, coins} = useSelector((state: StoreState) => state);
    const [planIndex, setPlanIndex] = useState(0);
    const [miningPlans, setMiningPlans] = useState<IMiningPlan[]>([]);
    const [selectedCoin, setSelectedCoin] = useState<ICoin>();
    const [orders, setOrders] = useState<ITempOrder[]>([]);

    const onSubmit = () => {
        // set current order to session
        sessionStorage.setItem('orders', JSON.stringify(orders));
        /// set selectedCoin
        sessionStorage.setItem('payWithCoin', JSON.stringify(selectedCoin));
        /// go to Review Purchase page
        pushTo(PATH.reviewPurchase);
    };

    const onChangePower = (
        planIndex: number,
        radiantIndex: number,
        radiantPlanIndex: number,
        value: number | number[],
    ) => {
        const newMiningPlans = [...miningPlans];
        const currentPower = typeof value === 'object' ? value[0] : value;
        newMiningPlans[planIndex].radiants[radiantIndex].plans[
            radiantPlanIndex
        ].selectedPower = currentPower;
        setMiningPlans(newMiningPlans);
    };
    const addToOrder = (plan: IMiningPlanRadiantPlan) => {
        const newOrders = [...orders];
        const mark = plan.marks.find(
            (item: IMiningPlanRadiantMark) => item.value === plan.selectedPower,
        ) || {value: 0, price: 0};
        const curOrderIndex = findIndex(
            orders,
            (order) => order.planId === plan.id,
        );

        if (curOrderIndex !== -1 && newOrders[curOrderIndex]) {
            /// set current power
            newOrders[curOrderIndex].power = mark.value;
            newOrders[curOrderIndex].price = mark.price;
            /// remove order if power is zero
            if (mark.value <= 0) {
                newOrders.splice(curOrderIndex, 1);
            }
            return setOrders(newOrders);
        }

        newOrders.push({
            planId: plan.id,
            planName: plan.name,
            price: mark.price,
            power: plan.selectedPower,
            powerUnit: selectedCoin?.powerUnit ?? 'N/A',
            priceUnit: selectedCoin?.code ?? 'N/A',
            period: plan.period,
            coinId: plan.coinId,
            maintenanceFee: plan.maintenanceFee,
            actFlag: plan.actFlag,
            algorithm: plan.algorithm,
            image: plan.image,
            type: plan.type,
            coinName: plan.coinName,
        });
        setOrders(newOrders);
    };
    const calculateTotalPrice = () => {
        if (!selectedCoin) {
            return 0.0;
        }
        const priceTotal = orders.reduce((total: number, order) => {
            if (order.power > 0) {
                return total + order.price;
            }
            return total;
        }, 0);
        return convertPrice(priceTotal, selectedCoin);
    };
    const handleChangeSelectedCoin = (event: React.ChangeEvent<any>) => {
        let coinId = event.target.value;
        let selectedCoin = coins.find((coin: ICoin) => coin.id === coinId);
        if (selectedCoin) {
            setSelectedCoin(selectedCoin);
        }
    };

    const removeTempOrder = (record: ITempOrder) => {
        const newOrders = [...orders];
        const curOrderIndex = newOrders.findIndex(
            (order) => order.planId === record.planId,
        );
        if (curOrderIndex !== -1 && newOrders[curOrderIndex]) {
            newOrders.splice(curOrderIndex, 1);
        }
        setOrders(newOrders);
    };

    useEffect(() => {
        if (!isEmpty(plans)) {
            setMiningPlans(plans);
        }
    }, [plans]);

    //// set active first coin
    useEffect(() => {
        if (!isEmpty(coins)) {
            /// get from session
            let sessionPayWithCoin = sessionStorage.getItem('payWithCoin');
            if (
                !isNull(sessionPayWithCoin) &&
                isJsonString(sessionPayWithCoin)
            ) {
                const payWithCoin: ICoin = JSON.parse(sessionPayWithCoin);
                if (payWithCoin) {
                    retrieveOrderFromSession(payWithCoin);
                    return setSelectedCoin(payWithCoin);
                }
            }
            /// get first plan
            const firstCoin = coins[0];
            if (firstCoin) {
                retrieveOrderFromSession(firstCoin);
                setSelectedCoin(firstCoin);
            }
        }
    }, [coins]);

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    const retrieveOrderFromSession = (coin: ICoin) => {
        /// get from session
        let sessionOrders = sessionStorage.getItem('orders');
        if (!isNull(sessionOrders) && isJsonString(sessionOrders)) {
            sessionOrders = JSON.parse(sessionOrders);
            if (isArray(sessionOrders)) {
                const differentSelectedCoin = sessionOrders.findIndex(
                    (item: ITempOrder) => item.priceUnit !== coin.code,
                );
                if (differentSelectedCoin === -1) {
                    setOrders(sessionOrders);
                }
            }
        }
    };

    return (
        <Grid container spacing={3} className={classes.selectPlanContainer}>
            <Grid item xs={12}>
                <HeadingTab value={1} />
            </Grid>
            <Grid item xs={12}>
                <Box className="plan-heading">
                    <Typography
                        variant="h1"
                        className={`title ${isMobile ? 'mobile' : ''}`}
                    >
                        Choose Plans
                    </Typography>
                    <ButtonGroup
                        variant="contained"
                        color="primary"
                        className={`plan-selector ${isMobile ? 'mobile' : ''}`}
                    >
                        {miningPlans.map((plan, index) => (
                            <Button
                                className={planIndex === index ? 'active' : ''}
                                key={index}
                                onClick={() => setPlanIndex(index)}
                            >
                                {plan.name}
                            </Button>
                        ))}
                    </ButtonGroup>
                    <Typography className="sub-title">
                        You can choose multiple plans at the same time
                    </Typography>
                </Box>
            </Grid>
            {miningPlans.map((plan, index) => (
                <Grid
                    item
                    xs={12}
                    className={planIndex === index ? 'visibility' : 'hidden'}
                    key={index}
                >
                    <Grid container spacing={3}>
                        {plan.radiants.map((radiant, radiantIndex) => (
                            <Grid item xs={12} key={radiantIndex}>
                                <Box
                                    className={`plan-details ${
                                        isMobile ? 'mobile' : ''
                                    }`}
                                >
                                    <Box className="title-wrapper">
                                        <Typography className="title">
                                            {radiant.name}
                                        </Typography>
                                    </Box>
                                    {radiant.plans.map(
                                        (radiantPlan, radiantPlanIndex) => (
                                            <PlanItem
                                                plan={radiantPlan}
                                                key={radiantPlanIndex}
                                                onChangePower={(
                                                    _event,
                                                    value,
                                                ) =>
                                                    onChangePower(
                                                        index,
                                                        radiantIndex,
                                                        radiantPlanIndex,
                                                        value,
                                                    )
                                                }
                                                addToOrder={addToOrder}
                                                orders={orders}
                                                selectedCoin={selectedCoin}
                                            />
                                        ),
                                    )}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12} className="second-section">
                <Typography
                    variant="h1"
                    className={`title ${isMobile ? 'mobile' : ''}`}
                >
                    Order Sumary
                </Typography>
            </Grid>
            <Grid item xs={12} className="order-sumary">
                <Table
                    dynamicHeight
                    columns={[
                        {
                            name: 'planName',
                            title: 'Plan',
                        },
                        {
                            name: 'power',
                            title: 'Hashpower',
                            renderer: (record: ITempOrder, _value: number) => {
                                return `${record.power} ${record.powerUnit}`;
                            },
                        },
                        {
                            name: 'price',
                            title: 'Price',
                            renderer: (record: ITempOrder, _value: number) => {
                                if (selectedCoin) {
                                    return `${convertPrice(
                                        record.price,
                                        selectedCoin,
                                    )} ${selectedCoin.code}`;
                                }
                                return `${record.price} ${record.priceUnit}`;
                            },
                        },
                        {
                            name: 'price',
                            title: '',
                            renderer: (record: ITempOrder, _value: number) => {
                                return (
                                    <IconButton
                                        className={classes.removePlan}
                                        onClick={() => removeTempOrder(record)}
                                    >
                                        <img
                                            src={TrashIcon}
                                            alt="remove plan"
                                        />
                                    </IconButton>
                                );
                            },
                        },
                    ]}
                    data={orders}
                    hideIndex
                    noDataTitle="No plan selected"
                />
                <Box className="total">
                    <Typography className="text">Total</Typography>
                    <Typography className="price">
                        {calculateTotalPrice()} {selectedCoin?.code ?? 'N/A'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} className="second-section">
                <Typography
                    variant="h1"
                    className={`title ${isMobile ? 'mobile' : ''}`}
                >
                    Select payment type
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item md={6} className="coin-selection">
                        <SelectBox
                            value={selectedCoin?.id ?? ''}
                            fullWidth
                            handleChange={handleChangeSelectedCoin}
                        >
                            {coins.map((coin, index) => (
                                <MenuItem value={coin.id} key={index}>
                                    {coin.name}
                                </MenuItem>
                            ))}
                        </SelectBox>
                    </Grid>
                    <Grid item md={6}>
                        <Button
                            fullWidth
                            disabled={isEmpty(orders)}
                            onClick={onSubmit}
                            color="primary"
                            variant="contained"
                            className="btn-go-order"
                        >
                            Continue to order review
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
