import React, {useState} from 'react';
import {PageLayout} from '../../components/PageLayout';
import Button from '../../components/Button';
import {requestContactService} from '../../middlewares/user';
import {Grid, makeStyles, Box, Typography, TextField} from '@material-ui/core';
import {find} from 'lodash';
import CustomerServiceImg from '../../assets/images/customer-service.png';
import {MOBILE_WIDTH} from '../../constants/common';

const useStyles = makeStyles((theme) => ({
    settingTitle: {
        '& .title': {
            textAlign: 'center',
            fontFamily: 'Poppins',
            fontSize: 32,
            fontWeight: 'bold',
            letterSpacing: 0.8,
            color: theme.palette.neutral.white,
            marginTop: 60,
            marginBottom: 24,
            '&.mobile': {
                marginTop: 24,
                marginBottom: 24,
                fontSize: 24,
            },
        },
    },
    contentWrapper: {
        backgroundColor: theme.palette.neutral.hashrate,
        '& .d-none': {
            display: 'none',
        },
        '& .tab-container': {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: '0 33px',
            '& h3': {
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: 20,
                fontWeight: 'bold',
                lineHeight: 1.5,
                letterSpacing: 0.5,
                color: theme.palette.neutral.gray,
                borderBottom: `1px solid ${theme.palette.neutral.timber}`,
                width: '100%',
                padding: '15px 0px 30px',
                cursor: 'pointer',
                '&.active': {
                    color: theme.palette.primary.main,
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                },
            },
        },
        '& .content': {
            padding: '22px 24px',
            borderRadius: 8,
            border: `2px solid ${theme.palette.neutral.purple1}`,
            margin: 15,
            height: 517,
            '&.mobile': {
                height: 'auto',
                '& .contact-title': {
                    fontSize: 20,
                },
                '& .form-submit': {
                    marginBottom: 0,
                    textAlign: 'center',
                },
            },
            '& .contact-title': {
                fontSize: 24,
                fontWeight: 'bold',
                lineHeight: 1.5,
                letterSpacing: 0.4,
                color: theme.palette.neutral.white,
            },
            '& .contact-subtitle': {
                fontSize: 16,
                fontWeight: 500,
                lineHeight: 1.75,
                letterSpacing: 0.3,
                color: theme.palette.neutral.white,
                margin: '16px 0 20px',
            },
            '& .form-submit': {
                marginTop: 24,
                marginBottom: 14,
                width: '100%',
                textAlign: 'right',
                '& .submit-btn': {
                    backgroundColor: theme.palette.primary.dark,
                    width: 200,
                    height: 44,
                },
            },
        },
        '& .image': {
            margin: 15,
            height: 565,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            paddingLeft: '10%',
            '& img': {
                width: '100%',
                maxWidth: 384,
            },
        },
    },
    formGroup: {
        marginBottom: 16,
        width: '100%',
        '& label': {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.neutral.gray,
            fontSize: 12,
            marginBottom: 8,
            display: 'inline-block',
        },
        '& small': {
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.semantic.main,
        },
    },
    helperText: {
        color: theme.palette.semantic.main,
    },
}));

const initState = {
    subject: {
        value: '',
        error: '',
    },
    message: {
        value: '',
        error: '',
    },
};

export const CustomerService = () => {
    const classes = useStyles();
    const [state, setState] = useState(initState);

    const handleSubmit = () => {
        requestContactService({
            subject: state.subject.value,
            content: state.message.value,
        }).then(() => {
            setState(initState);
        });
    };

    const onChangeText = (key: 'subject' | 'message') => (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const value = event.target.value;
        setState({
            ...state,
            [key]: {
                error: value === '' ? `Please input your ${key}` : '',
                value: value,
            },
        });
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && disableSubmit === false) {
            event.preventDefault();
            handleSubmit();
        }
    };

    const disableSubmit = Boolean(
        find(state, (el) => el.value === '' || el.error !== ''),
    );

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <PageLayout>
            <Grid container spacing={3} className={classes.settingTitle}>
                <Grid item xs={12}>
                    <Typography
                        variant="h2"
                        className={`title ${isMobile ? 'mobile' : ''}`}
                    >
                        Customer Service
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3} justify="center">
                <Grid item md={10} className={classes.contentWrapper}>
                    <Grid container spacing={3} justify="center">
                        <Grid item md={6} className={isMobile ? 'd-none' : ''}>
                            <Box className="image">
                                <img
                                    src={CustomerServiceImg}
                                    alt="customer service area"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box
                                className={`content ${
                                    isMobile ? 'mobile' : ''
                                }`}
                            >
                                <Typography
                                    variant="h3"
                                    className="contact-title"
                                >
                                    Contact Us
                                </Typography>
                                <Typography className="contact-subtitle">
                                    We are happy to receive your questions and
                                    will respond as soon as possible
                                </Typography>
                                <Box className={classes.formGroup}>
                                    <label>Your Subject</label>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        value={state.subject.value}
                                        error={
                                            state.subject.error ? true : false
                                        }
                                        onChange={onChangeText('subject')}
                                        onKeyPress={onKeyPress}
                                        placeholder="Eg: Your Subject"
                                    />
                                    {state.subject.error ? (
                                        <small className={classes.helperText}>
                                            {state.subject.error}
                                        </small>
                                    ) : null}
                                </Box>
                                <Box className={classes.formGroup}>
                                    <label>Your Message</label>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        value={state.message.value}
                                        error={
                                            state.message.error ? true : false
                                        }
                                        onChange={onChangeText('message')}
                                        onKeyPress={onKeyPress}
                                        multiline
                                        rows={6}
                                        placeholder="Eg: Input your messages here"
                                    />
                                    {state.message.error ? (
                                        <small className={classes.helperText}>
                                            {state.message.error}
                                        </small>
                                    ) : null}
                                </Box>
                                <Box className="form-submit">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="submit-btn"
                                        disabled={disableSubmit}
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PageLayout>
    );
};
