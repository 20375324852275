import React, {useState} from 'react';
import {Grid, makeStyles, TextField, Box, Typography} from '@material-ui/core';
import {find} from 'lodash';
import {changePasswordMiddleware} from '../../middlewares/user';
import Button from '../../components/Button';
import {MOBILE_WIDTH} from '../../constants/common';

const useStyles = makeStyles((theme) => ({
    formGroup: {
        marginBottom: 20,
        width: '100%',
        paddingBottom: '0px !important',
        '& label': {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.neutral.gray,
            fontSize: 12,
            marginBottom: 8,
            display: 'block',
        },
        '& small': {
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.semantic.main,
        },
        '& .submit-button': {
            width: 250,
            marginTop: 27,
            backgroundColor: theme.palette.primary.dark,
        },
    },
    helperText: {
        color: theme.palette.semantic.main,
    },
    changePasswordContainer: {
        '& .wrapper': {
            borderRadius: 8,
            border: `2px solid ${theme.palette.neutral.purple1}`,
            padding: '34px 64px',
            '& .title': {
                fontSize: 24,
                fontWeight: 'bold',
                lineHeight: 1.5,
                letterSpacing: 0.6,
                color: theme.palette.neutral.white,
                textAlign: 'center',
                marginBottom: 34,
            },
            '&.mobile': {
                padding: '17px 14px',
            },
        },
    },
}));

export const ChangePassword = () => {
    const classes = useStyles();
    const [state, setState] = useState({
        oldPassword: {value: '', error: ''},
        password: {value: '', error: ''},
        repassword: {value: '', error: ''},
    });

    const onChangeText = (key: string) => (event: React.ChangeEvent<any>) => {
        const fieldValue = event.target.value;
        if (key === 'oldPassword') {
            setState({
                ...state,
                oldPassword: {
                    error:
                        fieldValue.length < 6
                            ? 'Current password is too short'
                            : '',
                    value: fieldValue,
                },
            });
        }

        if (key === 'password') {
            setState({
                ...state,
                password: {
                    error: validatePassword(fieldValue),
                    value: fieldValue,
                },
                repassword: {
                    error: !state.repassword.value
                        ? ''
                        : fieldValue !== state.repassword.value
                        ? 'Password not match'
                        : '',
                    value: state.repassword.value,
                },
            });
        }
        if (key === 'repassword') {
            setState({
                ...state,
                repassword: {
                    error: !state.repassword.value
                        ? ''
                        : fieldValue !== state.password.value
                        ? 'Password not match'
                        : '',
                    value: fieldValue,
                },
            });
        }
    };

    const resetState = () =>
        setState({
            oldPassword: {value: '', error: ''},
            password: {value: '', error: ''},
            repassword: {value: '', error: ''},
        });

    const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && disableSubmit === false) {
            event.preventDefault();
            handleSubmit();
        }
    };

    const validatePassword = (str: string) => {
        let message =
            'Password should have at leats 8 letters , 1 lowercase letter, 1 number, 1 uppercase letter and 1 special character';
        if (
            str.match(/[a-z]/g) &&
            str.match(/[A-Z]/g) &&
            str.match(/[0-9]/g) &&
            str.match(/[^a-zA-Z\d]/g) &&
            str.length >= 8
        ) {
            message = '';
        }

        if (str.match(/^[^\s]+(\s+[^\s]+)*$/) === null) {
            message = "Your password can't start or end with a blank space";
        }

        return message;
    };

    const handleSubmit = () => {
        changePasswordMiddleware({
            oldPassword: state.oldPassword.value,
            newPassword: state.password.value,
        }).then(() => {
            resetState();
        });
    };

    const disableSubmit = Boolean(
        find(state, (el) => el.value === '' || el.error !== ''),
    );

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <Grid
            container
            spacing={3}
            justify="center"
            className={classes.changePasswordContainer}
        >
            <Grid item md={7}>
                <Box className={`wrapper ${isMobile ? 'mobile' : ''}`}>
                    <Typography className="title" variant="h3">
                        Change Password
                    </Typography>
                    <Box className={classes.formGroup}>
                        <label>Current Password</label>
                        <TextField
                            fullWidth
                            type="password"
                            variant="outlined"
                            value={state.oldPassword.value}
                            error={state.oldPassword.error ? true : false}
                            onChange={onChangeText('oldPassword')}
                            onKeyPress={onKeyPress}
                            placeholder="Current Password"
                        />
                        {state.oldPassword.error ? (
                            <small className={classes.helperText}>
                                {state.oldPassword.error}
                            </small>
                        ) : null}
                    </Box>
                    <Box className={classes.formGroup}>
                        <label>New Password</label>
                        <TextField
                            fullWidth
                            type="password"
                            variant="outlined"
                            value={state.password.value}
                            error={state.password.error ? true : false}
                            onChange={onChangeText('password')}
                            onKeyPress={onKeyPress}
                            placeholder="Input Your Password"
                        />
                        {state.password.error ? (
                            <small className={classes.helperText}>
                                {state.password.error}
                            </small>
                        ) : null}
                    </Box>
                    <Box className={classes.formGroup}>
                        <label>Confirm Password</label>
                        <TextField
                            fullWidth
                            type="password"
                            variant="outlined"
                            value={state.repassword.value}
                            error={state.repassword.error ? true : false}
                            onChange={onChangeText('repassword')}
                            onKeyPress={onKeyPress}
                            placeholder="Input Your Password"
                        />
                        {state.repassword.error ? (
                            <small className={classes.helperText}>
                                {state.repassword.error}
                            </small>
                        ) : null}
                    </Box>
                    <Box
                        className={classes.formGroup}
                        style={{textAlign: 'center'}}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={disableSubmit}
                            className="submit-button"
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};
