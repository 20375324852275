import React from 'react';
import {
    Checkbox as MuiCheckbox,
    FormControlLabelProps,
    FormControlLabel,
} from '@material-ui/core';

interface Props extends Omit<FormControlLabelProps, 'control'> {
    variant?: 'primary' | 'secondary' | 'default';
    fullWidth?: boolean;
    onChange: any;
}

export const Checkbox = (props: Props) => {
    const {variant, onChange, checked, fullWidth, style, ...other} = props;

    return (
        <FormControlLabel
            control={
                <MuiCheckbox
                    checked={checked}
                    onChange={onChange}
                    color={variant || 'primary'}
                />
            }
            style={{
                width: fullWidth ? 'calc(100% - 40px)' : undefined,
                justifyContent: fullWidth ? 'space-between' : undefined,
                padding: fullWidth ? '0 20px' : undefined,
                marginLeft: 0,
                ...style,
            }}
            {...other}
        />
    );
};
