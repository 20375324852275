import React, {useState} from 'react';
import {
    createStyles,
    Theme,
    withStyles,
    WithStyles,
    makeStyles,
} from '@material-ui/core/styles';
import Dialog, {DialogProps} from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {MOBILE_WIDTH} from '../constants/common';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        '& .MuiDialog-paper': {
            borderRadius: 4,
            border: `solid 2px ${theme.palette.neutral.lighter}`,
            backgroundColor: theme.palette.neutral.hashrate,
            '& .MuiDialogTitle-root': {
                '& button': {
                    padding: 16,
                    right: 0,
                    top: 0,
                },
            },
            '& .MuiDialogContent-root': {
                padding: '0 32px 32px',
            },
        },
    },
    dialogMobileWrapper: {
        '& .MuiDialog-paper': {
            borderRadius: 4,
            border: `solid 2px ${theme.palette.neutral.lighter}`,
            backgroundColor: theme.palette.neutral.hashrate,
            '& .MuiDialogTitle-root': {
                '& button': {
                    padding: 16,
                    right: 0,
                    top: 0,
                },
            },
            '& .MuiDialogContent-root': {
                padding: '0 11px 19px',
            },
        },
    },
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    onClose?: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const {classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    style={{color: '#FFF'}}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

interface IModalProps {
    open: boolean;
    handleClose?: () => void;
    children: any;
    maxWidth?:
        | false
        | 'xs'
        | 'sm'
        | 'md'
        | 'lg'
        | 'xl'
        | (() => false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined)
        | undefined;
}

const Modal = (props: IModalProps) => {
    const {open, handleClose, children, maxWidth} = props;
    const [size] = useState<DialogProps['maxWidth']>(
        maxWidth ? maxWidth : 'sm',
    );
    const classes = useStyles();
    const isMobile = window.outerWidth <= MOBILE_WIDTH;
    return (
        <Dialog
            fullWidth={true}
            onClose={handleClose}
            maxWidth={size}
            aria-labelledby="customized-dialog-title"
            open={open}
            className={
                isMobile ? classes.dialogMobileWrapper : classes.dialogWrapper
            }
        >
            {handleClose ? (
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                />
            ) : null}
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};
export default Modal;
