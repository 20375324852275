import React, {useState, useEffect} from 'react';
import {Grid, Typography, makeStyles, Box, TextField} from '@material-ui/core';
import {
    activeTwoFactor,
    deactiveTwoFactor,
    getUserProfileMiddleware,
} from '../../middlewares/user';
import Button from '../../components/Button';
import CopyButton from '../../components/CopyButton';
import {TwoFactor} from '../../types/user';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {MOBILE_WIDTH} from '../../constants/common';

const useStyles = makeStyles((theme) => ({
    qrCodeContainer: {
        '& .wrapper': {
            borderRadius: 8,
            border: `2px solid ${theme.palette.neutral.purple1}`,
            padding: 32,
            height: 482,
            '&.mobile': {
                height: 'auto',
            },
            '& .title': {
                fontFamily: 'Poppins',
                fontSize: 24,
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.5,
                letterSpacing: 0.1,
                color: theme.palette.neutral.white,
                marginBottom: 6,
            },
            '& .sub-title': {
                fontFamily: 'Poppins',
                fontSize: 14,
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.57,
                letterSpacing: 0.24,
                color: theme.palette.neutral.white,
            },
            '& .qr-content': {
                textAlign: 'center',
                marginTop: 50,
                marginBottom: 27,
                '& img': {
                    width: 217,
                    borderRadius: 4,
                },
            },
        },
    },
    qrcodeTextWrapper: {
        '& .wrapper': {
            borderRadius: 8,
            border: `2px solid ${theme.palette.neutral.purple1}`,
            padding: 32,
            marginTop: 20,
            '&.mobile': {
                marginTop: 30,
            },
            '&.last': {
                padding: '32px 32px 15px',
            },
            '& .title': {
                fontFamily: 'Poppins',
                fontSize: 14,
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.57,
                letterSpacing: 0.24,
                color: theme.palette.neutral.white,
                marginBottom: 16,
                '&.password': {
                    marginBottom: 12,
                    marginTop: 17,
                },
            },
            '&:first-child': {
                marginTop: 0,
            },
        },
        '& .mt20': {
            marginTop: 20,
        },
    },
    formGroup: {
        marginBottom: 0,
        width: '100%',
        paddingBottom: '0px !important',
        '& label': {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.neutral.gray,
            fontSize: 12,
            marginBottom: 8,
            display: 'block',
        },
        '& .flexible': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .your-code': {
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.67,
                letterSpacing: 0.38,
                color: theme.palette.neutral.lighter,
                backgroundColor: theme.palette.neutral.purple2,
                fontSize: 14,
                padding: '0 16px',
                marginRight: 24,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: 44,
            },
            '& .copy-button': {
                minWidth: 'auto',
                width: 119,
                backgroundColor: theme.palette.primary.dark,
            },
        },
        '& .submit-button': {
            width: 250,
            marginTop: 27,
            backgroundColor: theme.palette.primary.dark,
        },
    },
    helperText: {
        color: theme.palette.semantic.main,
    },
}));

export const TwoFactorAuth = () => {
    const classes = useStyles();

    const {userProfile, userTwoFactor} = useSelector(
        (state: StoreState) => state,
    );

    const [twoFactor, setTwoFactor] = useState<TwoFactor>({
        manualEntryKey: '',
        qrCodeSetupImageUrl: '',
    });
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const isTwoFactorEnabled = userProfile?.twoFactorEnabled;

    useEffect(() => {
        if (userProfile && userTwoFactor) {
            if (userProfile.twoFactorEnabled === false) {
                setTwoFactor(userTwoFactor);
            }
        }
        // eslint-disable-next-line
    }, [userProfile, userTwoFactor]);

    const handleSubmit = () => {
        if (userProfile?.twoFactorEnabled === true) {
            deactiveTwoFactor(
                {
                    twoFactorCode: code,
                    password,
                },
                () => {
                    setCode('');
                    setPassword('');
                    getUserProfileMiddleware();
                },
            );
            return;
        }
        activeTwoFactor(
            {
                twoFactorCode: code,
                password,
            },
            () => {
                setCode('');
                setPassword('');
                getUserProfileMiddleware();
            },
        );
    };

    const copyTwoFactorCode = () => {
        if (!twoFactor) {
            return; /// do nothing
        }
        navigator.clipboard.writeText(twoFactor.manualEntryKey);
    };

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <Grid container spacing={3}>
            {isTwoFactorEnabled ? (
                <Grid item xs={12} className={classes.qrcodeTextWrapper}>
                    <Box className={`wrapper ${isMobile ? 'mobile' : ''}`}>
                        <Typography className="title">
                            2Factor Authentication is enabled. Please enter your
                            Confirmation Code below to deactivate 2Factor
                            Authentication:
                        </Typography>
                        <Box className={classes.formGroup}>
                            <label>Google Authenticator Code</label>
                            <TextField
                                fullWidth
                                type="text"
                                id="google-authentication-code"
                                variant="outlined"
                                value={code}
                                onChange={(event) =>
                                    setCode(event.target.value)
                                }
                                placeholder="Google Authenticator Code"
                            />
                        </Box>
                        <Box className="mt20">
                            <Box className={classes.formGroup}>
                                <label>Your Password</label>
                                <TextField
                                    fullWidth
                                    type="password"
                                    variant="outlined"
                                    value={password}
                                    onChange={(event) =>
                                        setPassword(event.target.value)
                                    }
                                    inputProps={{
                                        autocomplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    placeholder="Confirm password"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item md={6} className={classes.qrCodeContainer}>
                            <Box className="wrapper">
                                <Typography variant="h3" className="title">
                                    Scan QR code first by 2 Factor
                                </Typography>
                                <Typography className="sub-title">
                                    To use 2 Factor Authentication you are able
                                    to just scan QR code
                                </Typography>
                                {twoFactor.qrCodeSetupImageUrl && (
                                    <Box className="qr-content">
                                        <img
                                            src={twoFactor.qrCodeSetupImageUrl}
                                            alt="qr-code"
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                        <Grid item md={6} className={classes.qrcodeTextWrapper}>
                            <Box className="wrapper">
                                <Typography className="title">
                                    To use 2Factor Authentication, enter the
                                    following code manually into your Google
                                    Authenticator app:
                                </Typography>
                                <Box className={classes.formGroup}>
                                    <label>Google Authenticator Code</label>
                                    <Box className="flexible">
                                        <Typography className="your-code">
                                            {twoFactor.manualEntryKey ?? 'N/A'}
                                        </Typography>
                                        <CopyButton
                                            variant="contained"
                                            disableElevation
                                            color="primary"
                                            onClick={copyTwoFactorCode}
                                            className="copy-button"
                                        >
                                            Copy
                                        </CopyButton>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                className={`wrapper last ${
                                    isMobile ? 'mobile' : ''
                                }`}
                            >
                                <Typography className="title">
                                    2Factor Authentication is not enabled yet.
                                    Please enter your Confirmation Code below to
                                    activate 2Factor Authentication:
                                </Typography>
                                <Box className={classes.formGroup}>
                                    <label>Google Authenticator Code</label>
                                    <TextField
                                        fullWidth
                                        id="google-authentication-code"
                                        type="text"
                                        variant="outlined"
                                        value={code}
                                        onChange={(event) =>
                                            setCode(event.target.value)
                                        }
                                        placeholder="Google Authenticator Code"
                                    />
                                </Box>
                                <Typography className="title password">
                                    Re-enter your password to make sure it is
                                    you
                                </Typography>
                                <Box className={classes.formGroup}>
                                    <label>Your Password</label>
                                    <TextField
                                        fullWidth
                                        type="password"
                                        variant="outlined"
                                        value={password}
                                        onChange={(event) =>
                                            setPassword(event.target.value)
                                        }
                                        inputProps={{
                                            autocomplete: 'new-password',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        placeholder="Confirm password"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid
                item
                xs={12}
                className={classes.formGroup}
                style={{textAlign: 'center'}}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    className="submit-button"
                    disabled={code === ''}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};
