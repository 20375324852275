export const PATH = {
    home: '/',
    login: '/login',
    signUp: '/sign-up',
    forgotPassword: '/forgot',
    resetPassword: '/reset',
    verifyEmail: '/verify',
    dashboard: '/dashboard',
    settings: '/settings',
    generalInformation: '/settings/general',
    twoFactorAuth: '/settings/2factor-auth',
    changePassword: '/settings/change-password',
    purchase: '/upgrade-hashpower',
    referral: '/referral',
    selectPlansPurchase: '/upgrade-hashpower/select-plans',
    reviewPurchase: '/upgrade-hashpower/review',
    paymentPurchase: '/upgrade-hashpower/payment',
    confirmPurchase: '/upgrade-hashpower/confirmation',
    affiliate: '/affiliate-program',
    myOrder: '/my-orders',
    activity: '/activity',
    myWallet: '/my-wallet',
    wallets: '/wallet-management',
    login2fa: '/login2fa',
    customerservice: '/customer-service',
};
