import React from 'react';
import {Box, Typography, Slider} from '@material-ui/core';
import Button from './Button';
import AirbnbThumb from './AirbnbThumb';
import PlanThumbnailImg from '../assets/images/plan-thumbnail.png';
import {IMiningPlanRadiantPlan} from '../types/plan';
import {ITempOrder} from '../types/order';
import {ICoin} from '../types/coin';
import {head, last} from 'lodash';
import {convertPrice} from '../helpers/common';

interface IProps {
    plan: IMiningPlanRadiantPlan;
    onChangePower?: (
        _event: React.ChangeEvent<any>,
        value: number | number[],
    ) => void;
    addToOrder: (plan: IMiningPlanRadiantPlan) => void;
    orders: ITempOrder[];
    selectedCoin?: ICoin;
}

const PlanItem = (props: IProps) => {
    const {plan, onChangePower, addToOrder, orders, selectedCoin} = props;
    const minPower = head(plan.marks)?.value ?? 0;
    const maxPower = last(plan.marks)?.value ?? 1;
    const powerData = plan.marks.find(
        (mark) => mark.value === plan.selectedPower,
    );

    const planOrdered = orders.find((order) => order.planId === plan.id);
    const isDisableAddPlan =
        plan.selectedPower === 0
            ? planOrdered
                ? false
                : true
            : planOrdered
            ? planOrdered.power === plan.selectedPower
                ? true
                : false
            : false;

    return (
        <Box className="item">
            <Box className="plan-thumbnail">
                <img
                    src={plan.image ?? PlanThumbnailImg}
                    alt="plan thumbnail"
                />
            </Box>
            <Box className="plan">
                <Box>
                    <Typography className="title">{plan.name}</Typography>
                    <Typography className="sub-title">
                        {plan.algorithm} Mining Algorithm
                    </Typography>
                </Box>
                <Box className="power-slider">
                    <Slider
                        ThumbComponent={(defaultProps) =>
                            AirbnbThumb({
                                defaultProps,
                            })
                        }
                        min={minPower}
                        max={maxPower}
                        marks={plan.marks}
                        value={plan.selectedPower}
                        step={null}
                        valueLabelDisplay="on"
                        className="power-range"
                        onChange={onChangePower}
                    />
                    <Typography className="power-label power-min">
                        {minPower} {plan.powerUnit}
                    </Typography>
                    <Typography className="power-label power-max">
                        {maxPower} {plan.powerUnit}
                    </Typography>
                </Box>
                <Box className="power-overview">
                    <Box className="power-value">
                        <Typography className="value">
                            {plan.selectedPower} {plan.powerUnit}
                        </Typography>
                        {powerData && selectedCoin ? (
                            <Typography className="price">
                                {convertPrice(powerData.price, selectedCoin)}{' '}
                                {selectedCoin.code} - {powerData.price} USD
                            </Typography>
                        ) : null}
                    </Box>
                    <Button
                        className="add-plan-btn"
                        disabled={isDisableAddPlan}
                        onClick={(_e) => addToOrder(plan)}
                    >
                        {planOrdered
                            ? plan.selectedPower > 0
                                ? 'Update Plan'
                                : 'Remove Plan'
                            : 'Add Plan'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default PlanItem;
