import React, {useState} from 'react';
import {
    Typography,
    Button,
    makeStyles,
    Box,
    TextField,
    CircularProgress,
} from '@material-ui/core';
import {isValidEmail} from '../../helpers/validate';
import {find} from 'lodash';
import {Checkbox} from '../../components/Checkbox';
import {signUpMiddleware} from '../../middlewares/auth';
import {ReCaptcha} from '../../components/ReCAPTCHA';
import {AuthLayout} from '../../components/AuthLayout';
import {pushTo} from '../../helpers/history';
import {PATH} from '../../constants/path';
import {MOBILE_WIDTH} from '../../constants/common';

const styles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.neutral.hashrate,
        maxWidth: 482,
        padding: '40px 35px 1px',
    },
    containerMobile: {
        backgroundColor: theme.palette.neutral.hashrate,
        padding: '40px 15px 1px',
    },
    formTitle: {
        height: 45,
        fontFamily: 'Poppins',
        fontSize: 32,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.8,
        textAlign: 'center',
        color: theme.palette.neutral.white,
    },
    formSubTitle: {
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0.3,
        color: theme.palette.neutral.white,
    },
    formGroup: {
        marginBottom: 32,
        width: '100%',
        '& label': {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.neutral.gray,
            fontSize: 14,
            marginBottom: 8,
            display: 'inline-block',
        },
        '& small': {
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.semantic.main,
            display: 'inline-block',
            marginTop: 4,
        },
        '& .MuiCheckbox-root': {
            color: theme.palette.neutral.lighter,
            padding: 0,
            marginRight: 14,
            '&.Mui-checked': {
                color: theme.palette.neutral.lighter,
            },
        },
        '& .hightlight': {
            color: theme.palette.neutral.gray,
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    formHeight: {
        minHeight: 227,
    },
    helperText: {
        color: theme.palette.semantic.main,
    },
    helperPasswordText: {
        color: `${theme.palette.neutral.lighter} !important`,
    },
    disableButtonColor: {
        height: 48,
        color: theme.palette.neutral.white,
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.88,
        letterSpacing: 0.4,
        '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.5,
        },
    },
    forgotButtonColor: {
        height: 48,
        color: theme.palette.neutral.white,
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.88,
        letterSpacing: 0.4,
        border: 'none',
    },
    signUpLink: {
        maxWidth: 482,
        textAlign: 'center',
        marginTop: 32,
        color: theme.palette.neutral.white,
        '& p': {
            fontSize: 16,
            '& span': {
                color: theme.palette.primary.main,
                cursor: 'pointer',
            },
        },
    },
    flexible: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: 999999,
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
    },
    checkbox: {
        display: 'flex !important',
        '& span:last-child': {
            lineHeight: 1.67,
        },
    },
    errorMessage: {
        padding: '15px 16px 13px',
        borderRadius: 4,
        border: '2px solid #f5525a',
        backgroundColor: 'rgba(245, 82, 90, 0.2)',
        margin: '32px 0',
        '& .text': {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.57,
            letterSpacing: 0.24,
            color: theme.palette.neutral.white,
        },
    },
}));

export const SignUp = () => {
    const classes = styles();
    const [state, setState] = useState({
        email: {
            value: '',
            error: '',
        },
        password: {
            value: '',
            error: '',
        },
        repassword: {
            value: '',
            error: '',
        },
        reCaptcha: {
            value: '',
            error: '',
        },
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isVerify, setIsVerify] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [checkBoxState, setCheckboxState] = useState({
        readPrivacy: false,
        subscribe: false,
    });

    const handleSubmit = () => {
        setIsLoading(true);
        setErrorMessage('');
        signUpMiddleware({
            email: state.email.value.trim().toLocaleLowerCase(),
            password: state.password.value,
            googleReCaptchaResponse: state.reCaptcha.value,
            subscribe: checkBoxState.subscribe,
        })
            .then(() => {
                setIsLoading(false);
                setIsVerify(true);
            })
            .catch((error) => {
                setErrorMessage(error);
                setIsLoading(false);
            });
    };

    const onChangeText = (key: 'email' | 'password' | 'repassword') => (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const fieldValue = event.target.value;

        switch (key) {
            case 'email':
                setState({
                    ...state,
                    [key]: {
                        error:
                            isValidEmail(fieldValue) === false
                                ? 'Invalid Email'
                                : '',
                        value: fieldValue,
                    },
                });
                break;
            case 'password':
                setState({
                    ...state,
                    password: {
                        error: validatePassword(fieldValue),
                        value: fieldValue,
                    },
                    repassword: {
                        error: !state.repassword.value
                            ? ''
                            : fieldValue !== state.repassword.value
                            ? 'Password not match'
                            : '',
                        value: state.repassword.value,
                    },
                });
                break;
            case 'repassword':
                setState({
                    ...state,
                    repassword: {
                        error: !state.repassword.value
                            ? ''
                            : fieldValue !== state.password.value
                            ? 'Password not match'
                            : '',
                        value: fieldValue,
                    },
                });
        }
    };

    const validatePassword = (str: string) => {
        let message =
            'Password should have at leats 8 letters , 1 lowercase letter, 1 number, 1 uppercase letter and 1 special character';
        if (
            str.match(/[a-z]/g) &&
            str.match(/[A-Z]/g) &&
            str.match(/[0-9]/g) &&
            str.match(/[^a-zA-Z\d]/g) &&
            str.length >= 8
        ) {
            message = '';
        }
        if (str.match(/^[^\s]+(\s+[^\s]+)*$/) === null) {
            message = "Your password can't start or end with a blank space";
        }

        if (str === '') {
            message = '';
        }

        return message;
    };

    const toggleCheckbox = (key: 'readPrivacy' | 'subscribe') => () => {
        setCheckboxState({
            ...checkBoxState,
            [key]: !checkBoxState[key],
        });
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && disableSubmit === false) {
            event.preventDefault();
            handleSubmit();
        }
    };

    const onChangeReCaptcha = (token: string | null) =>
        setState({
            ...state,
            reCaptcha: {
                value: token || '',
                error: '',
            },
        });

    const disableSubmit =
        Boolean(find(state, (el) => el.value === '' || el.error !== '')) ||
        checkBoxState.readPrivacy === false;

    const goToLogin = () => {
        pushTo(PATH.login);
    };

    const gotoPrivacyPage = (event: React.ChangeEvent<any>) => {
        event.stopPropagation();
        event.preventDefault();
        window.open('/pdf/Expert-Mining-Privacy-Policy.pdf', '_blank');
    };

    const isMobile = window.innerWidth <= MOBILE_WIDTH;

    return (
        <AuthLayout>
            <Box>
                <Box
                    className={
                        isMobile ? classes.containerMobile : classes.container
                    }
                >
                    <Box className={classes.formGroup}>
                        <Typography className={classes.formTitle}>
                            Sign Up
                        </Typography>
                    </Box>
                    {errorMessage !== '' ? (
                        <Box className={classes.errorMessage}>
                            <Typography className="text">
                                {errorMessage}
                            </Typography>
                        </Box>
                    ) : null}
                    {isVerify ? (
                        <Box>
                            <Box className={classes.formGroup}>
                                <Typography className={classes.formSubTitle}>
                                    Please check your e-mail Inbox and click on
                                    the verification link to activate your
                                    registration.
                                </Typography>
                            </Box>
                            <Box className={classes.formHeight}></Box>
                        </Box>
                    ) : (
                        <Box>
                            <Box className={classes.formGroup}>
                                <label>Email</label>
                                <TextField
                                    fullWidth
                                    type="email"
                                    variant="outlined"
                                    value={state.email.value}
                                    error={state.email.error ? true : false}
                                    onChange={onChangeText('email')}
                                    onKeyPress={onKeyPress}
                                    placeholder="Eg: example@gmail.com"
                                />
                                {state.email.error ? (
                                    <small className={classes.helperText}>
                                        {state.email.error}
                                    </small>
                                ) : null}
                            </Box>

                            <Box className={classes.formGroup}>
                                <label>Password</label>
                                <TextField
                                    fullWidth
                                    type="password"
                                    variant="outlined"
                                    value={state.password.value}
                                    error={state.password.error ? true : false}
                                    onChange={onChangeText('password')}
                                    onKeyPress={onKeyPress}
                                />
                                {state.password.error ? (
                                    <small className={classes.helperText}>
                                        {state.password.error}
                                    </small>
                                ) : state.password.value === '' ? (
                                    <small
                                        className={classes.helperPasswordText}
                                    >
                                        Password should have at leats 8 letters
                                        , 1 lowercase letter, 1 number, 1
                                        uppercase letter and 1 special character
                                    </small>
                                ) : null}
                            </Box>
                            <Box className={classes.formGroup}>
                                <label>Confirm Password</label>
                                <TextField
                                    fullWidth
                                    type="password"
                                    variant="outlined"
                                    value={state.repassword.value}
                                    error={
                                        state.repassword.error ? true : false
                                    }
                                    onChange={onChangeText('repassword')}
                                    onKeyPress={onKeyPress}
                                />
                                {state.repassword.error ? (
                                    <small className={classes.helperText}>
                                        {state.repassword.error}
                                    </small>
                                ) : null}
                            </Box>

                            <Box className={classes.formGroup}>
                                <Checkbox
                                    label={
                                        <span>
                                            I’ve read and understood the{' '}
                                            <span
                                                className="hightlight"
                                                onClick={gotoPrivacyPage}
                                            >
                                                Privacy Policy.
                                            </span>
                                        </span>
                                    }
                                    checked={checkBoxState.readPrivacy}
                                    onChange={toggleCheckbox('readPrivacy')}
                                    className={classes.checkbox}
                                />
                                <Checkbox
                                    label="I would like to receive occasional e-mails with discounts and offers"
                                    checked={checkBoxState.subscribe}
                                    onChange={toggleCheckbox('subscribe')}
                                    className={classes.checkbox}
                                />
                            </Box>

                            <Box className={classes.formGroup}>
                                <ReCaptcha
                                    onChange={onChangeReCaptcha}
                                    containerStyle={{marginTop: 20}}
                                />
                            </Box>
                        </Box>
                    )}

                    <Box className={classes.formGroup}>
                        <Button
                            fullWidth
                            className={classes.disableButtonColor}
                            variant="contained"
                            color="primary"
                            onClick={isVerify ? goToLogin : handleSubmit}
                            disabled={isVerify ? false : disableSubmit}
                        >
                            {isVerify ? 'Go To Log In' : 'Sign Up'}
                        </Button>
                    </Box>
                </Box>

                <Box className={classes.signUpLink}>
                    <Typography>
                        Already have an account?{' '}
                        <span onClick={goToLogin}>Log In here</span>
                    </Typography>
                </Box>
            </Box>
            {isLoading ? (
                <Box className={classes.flexible}>
                    <CircularProgress size={100} />
                </Box>
            ) : null}
        </AuthLayout>
    );
};
