import {store} from '..';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {ICoin} from '../types/coin';
import {setCoinAction} from '../actions/coin';
import {fireErrorNotification} from '../helpers/notification';

export const getCoin = () => {
    Axios.get('/Coin')
        .then((response: AxiosResponse<ICoin[]>) => {
            store.dispatch(setCoinAction(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
