import React, {useEffect} from 'react';
import {verifyEmailMiddleware} from '../../middlewares/auth';
import {Box, Typography, Button, makeStyles} from '@material-ui/core';
import {AuthLayout} from '../../components/AuthLayout';
import {pushTo} from '../../helpers/history';
import {PATH} from '../../constants/path';
import {MOBILE_WIDTH} from '../../constants/common';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.neutral.hashrate,
        maxWidth: 482,
        padding: '40px 35px 1px',
    },
    containerMobile: {
        backgroundColor: theme.palette.neutral.hashrate,
        padding: '40px 15px 1px',
    },
    formTitle: {
        height: 45,
        fontFamily: 'Poppins',
        fontSize: 32,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.8,
        textAlign: 'center',
        color: theme.palette.neutral.white,
        marginBottom: 50,
    },
    formSubTitle: {
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0.3,
        color: theme.palette.neutral.white,
    },
    disableButtonColor: {
        height: 48,
        color: theme.palette.neutral.white,
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.88,
        letterSpacing: 0.4,
        '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.5,
        },
    },
    formGroup: {
        marginBottom: 32,
        width: '100%',
        '& label': {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.neutral.gray,
            fontSize: 14,
            marginBottom: 8,
            display: 'inline-block',
        },
    },
    formHeight: {
        minHeight: 227,
    },
}));

export const VerifyEmail = ({location}) => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token') || '';
    const id = urlParams.get('id') || '';
    const classes = useStyles();

    useEffect(() => {
        verifyEmailMiddleware({
            id,
            token,
        });
    }, [id, token]);

    const goToLogin = () => {
        pushTo(PATH.login);
    };

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <AuthLayout>
            <Box>
                <Box
                    className={
                        isMobile ? classes.containerMobile : classes.container
                    }
                >
                    <Box className={classes.formGroup}>
                        <Typography className={classes.formTitle}>
                            Verify Email
                        </Typography>
                    </Box>
                    <Box className={classes.formGroup}>
                        <Typography className={classes.formSubTitle}>
                            Verify e-mail successfully.
                        </Typography>
                    </Box>
                    <Box className={classes.formHeight}></Box>
                    <Box className={classes.formGroup}>
                        <Button
                            fullWidth
                            className={classes.disableButtonColor}
                            variant="contained"
                            color="primary"
                            onClick={goToLogin}
                        >
                            Go To Log In
                        </Button>
                    </Box>
                </Box>
            </Box>
        </AuthLayout>
    );
};
