import React, {useState} from 'react';
import {
    Box,
    Typography,
    Button,
    makeStyles,
    TextField,
    InputAdornment,
    IconButton,
    Modal,
    CircularProgress,
} from '@material-ui/core';
import {isValidEmail} from '../../helpers/validate';
import {
    loginMiddleware,
    verifyTwoFactor,
    resendVerificationEmail,
} from '../../middlewares/auth';
import {ReCaptcha} from '../../components/ReCAPTCHA';
import {find} from 'lodash';
import {pushTo} from '../../helpers/history';
import {PATH} from '../../constants/path';
import {AuthLayout} from '../../components/AuthLayout';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CloseIcon from '@material-ui/icons/Close';
import {MOBILE_WIDTH} from '../../constants/common';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.neutral.hashrate,
        maxWidth: 482,
        padding: '40px 35px 1px',
    },
    containerMobile: {
        backgroundColor: theme.palette.neutral.hashrate,
        padding: '40px 15px 1px',
    },
    formTitle: {
        height: 45,
        fontFamily: 'Poppins',
        fontSize: 32,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.8,
        textAlign: 'center',
        color: theme.palette.neutral.white,
    },
    formGroup: {
        marginBottom: 32,
        width: '100%',
        '& label': {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.neutral.gray,
            fontSize: 14,
            marginBottom: 8,
            display: 'inline-block',
        },
        '& small': {
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.semantic.main,
        },
    },
    helperText: {
        color: theme.palette.semantic.main,
    },
    disableButtonColor: {
        height: 48,
        color: theme.palette.neutral.white,
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.88,
        letterSpacing: 0.4,
        '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.5,
        },
    },
    forgotButtonColor: {
        height: 48,
        color: theme.palette.neutral.white,
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.88,
        letterSpacing: 0.4,
        border: 'none',
        '&:hover': {
            backgroundColor: theme.palette.neutral.dark,
        },
    },
    signUpLink: {
        maxWidth: 482,
        textAlign: 'center',
        marginTop: 32,
        color: theme.palette.neutral.white,
        '& p': {
            fontSize: 16,
            '& span': {
                color: theme.palette.primary.main,
                cursor: 'pointer',
            },
        },
    },
    twoFactorAuthenTitle: {
        fontFamily: 'Poppins',
        fontSize: 32,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.8,
        textAlign: 'center',
        color: theme.palette.neutral.white,
        marginBottom: 12,
    },
    twoFactorAuthenSubTitle: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.71,
        letterSpacing: 0.24,
        textAlign: 'center',
        color: theme.palette.neutral.gray,
        marginBottom: 40,
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxWidth: 520,
        backgroundColor: theme.palette.neutral.darker,
        border: `2px solid ${theme.palette.neutral.lighter}`,
        borderRadius: 4,
        padding: '15px 15px 0',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '& .close-button': {
            textAlign: 'right',
            '& button': {
                color: theme.palette.neutral.white,
                padding: 0,
            },
        },
        '& .modal-body': {
            padding: '15px 15px 0',
        },
    },
    googleAuthenCodeInput: {
        '& input': {
            color: theme.palette.neutral.lighter,
            backgroundColor: theme.palette.neutral.main,
        },
    },
    flexible: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: 999999,
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
    },
    errorMessage: {
        padding: '15px 16px 13px',
        borderRadius: 4,
        border: '2px solid #f5525a',
        backgroundColor: 'rgba(245, 82, 90, 0.2)',
        margin: '32px 0',
        '& .text': {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.57,
            letterSpacing: 0.24,
            color: theme.palette.neutral.white,
        },
    },
    href: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

export const Login = () => {
    const classes = useStyles();
    const [state, setState] = useState({
        email: {value: '', error: ''},
        password: {value: '', error: ''},
        reCaptcha: {value: '', error: ''},
        showPassword: {value: false, error: ''},
    });
    const [errorMessage, setErrorMessage] = useState<any>('');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [secretCode, setSecretCode] = useState('');
    const [twoFactorCode, setTwoFactorCode] = useState({
        value: '',
        error: '',
    });
    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const resendEmail = () => {
        setErrorMessage('');
        resendVerificationEmail({
            email: state.email.value,
        });
    };

    const handleSubmit = () => {
        setIsLoading(true);
        setErrorMessage('');
        loginMiddleware({
            email: state.email.value.trim().toLocaleLowerCase(),
            password: state.password.value,
            googleReCaptchaResponse: state.reCaptcha.value,
        })
            .then((response: any) => {
                if (response.twoFactorActivated) {
                    setIsLoading(false);
                    setSecretCode(response.secretCode);
                    handleOpen();
                    return;
                }
                pushTo(PATH.dashboard);
            })
            .catch((error: any) => {
                if (error === 'Email is unverified') {
                    error = (
                        <span>
                            Email is unverified. Didn't receive verification
                            email?{' '}
                            <span
                                className={classes.href}
                                onClick={resendEmail}
                            >
                                Resend
                            </span>{' '}
                        </span>
                    );
                }
                setErrorMessage(error);
                setIsLoading(false);
            });
    };

    const onChangeText = (key: string) => (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        let error = '';
        if (key === 'password' && event.target.value.length < 6) {
            error = 'Password too short';
        }
        if (key === 'email' && isValidEmail(event.target.value) === false) {
            error = 'Invalid email';
        }
        setErrorMessage('');
        setState({
            ...state,
            [key]: {
                value: event.target.value,
                error,
            },
        });
    };

    const onChangeReCaptcha = (token: string | null) =>
        setState({
            ...state,
            reCaptcha: {
                value: token || '',
                error: '',
            },
        });

    const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && disableSubmit === false) {
            event.preventDefault();
            handleSubmit();
        }
    };

    const disableSubmit = Boolean(
        find(state, (el) => el.value === '' || el.error !== ''),
    );

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: {
                value: !state.showPassword.value,
                error: '',
            },
        });
    };
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
    };

    const onChangeAuthenCodeText = (event: React.ChangeEvent<any>) => {
        setTwoFactorCode({
            value: event.target.value,
            error: '',
        });
    };

    const onKeyAuthenCodePress = (
        event: React.KeyboardEvent<HTMLDivElement>,
    ) => {
        if (event.key === 'Enter' && disableSubmit === false) {
            event.preventDefault();
            handleAuthenCodeSubmit();
        }
    };
    const disableAuthenCodeSubmit = Boolean(
        twoFactorCode.value === '' || twoFactorCode.error !== '',
    );

    const handleAuthenCodeSubmit = () => {
        setIsLoading(false);
        verifyTwoFactor({
            token: secretCode,
            twoFactorCode: twoFactorCode.value,
        })
            .then(() => {
                pushTo(PATH.dashboard);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const goToSignup = () => {
        pushTo(PATH.signUp);
    };
    const goToForgotPassword = () => {
        pushTo(PATH.forgotPassword);
    };
    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <AuthLayout>
            <Box className="mb100">
                <Box
                    className={
                        isMobile ? classes.containerMobile : classes.container
                    }
                >
                    <Box className={classes.formGroup}>
                        <Typography className={classes.formTitle}>
                            Log In
                        </Typography>
                    </Box>
                    {errorMessage !== '' ? (
                        <Box className={classes.errorMessage}>
                            <Typography className="text">
                                {errorMessage}
                            </Typography>
                        </Box>
                    ) : null}

                    <Box className={classes.formGroup}>
                        <label>Email</label>
                        <TextField
                            fullWidth
                            type="email"
                            variant="outlined"
                            value={state.email.value}
                            error={state.email.error ? true : false}
                            onChange={onChangeText('email')}
                            onKeyPress={onKeyPress}
                            placeholder="Eg: example@gmail.com"
                        />
                        {state.email.error ? (
                            <small className={classes.helperText}>
                                {state.email.error}
                            </small>
                        ) : null}
                    </Box>

                    <Box className={classes.formGroup}>
                        <label>Password</label>
                        <TextField
                            fullWidth
                            type={
                                state.showPassword.value ? 'text' : 'password'
                            }
                            variant="outlined"
                            value={state.password.value}
                            error={state.password.error ? true : false}
                            onChange={onChangeText('password')}
                            onKeyPress={onKeyPress}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                        >
                                            {state.showPassword.value ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {state.password.error ? (
                            <small className={classes.helperText}>
                                {state.password.error}
                            </small>
                        ) : null}
                    </Box>
                    <Box className={classes.formGroup}>
                        <ReCaptcha
                            onChange={onChangeReCaptcha}
                            containerStyle={{marginTop: 20}}
                        />
                    </Box>
                    <Box className={classes.formGroup}>
                        <Button
                            fullWidth
                            className={classes.disableButtonColor}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={disableSubmit}
                        >
                            Log in
                        </Button>
                    </Box>
                    <Box className={classes.formGroup}>
                        <Button
                            fullWidth
                            className={classes.forgotButtonColor}
                            variant="outlined"
                            onClick={goToForgotPassword}
                        >
                            Forgot Password
                        </Button>
                    </Box>
                </Box>
                <Box className={classes.signUpLink}>
                    <Typography>
                        Don't have an account yet?{' '}
                        <span onClick={goToSignup}>Sign up here</span>
                    </Typography>
                </Box>
            </Box>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="google-authentication"
                aria-describedby="google-authentication-code"
            >
                <Box className={classes.paper}>
                    <Box className="close-button">
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box className="modal-body">
                        <Typography
                            variant="h4"
                            className={classes.twoFactorAuthenTitle}
                        >
                            2Factor Authentication
                        </Typography>
                        <Typography className={classes.twoFactorAuthenSubTitle}>
                            Please check your Google Authetication Code on 2FA
                            app
                        </Typography>
                        <Box className={classes.formGroup}>
                            <label>Google Authentication Code</label>
                            <TextField
                                fullWidth
                                className={classes.googleAuthenCodeInput}
                                value={twoFactorCode.value}
                                error={twoFactorCode.error ? true : false}
                                onChange={onChangeAuthenCodeText}
                                onKeyPress={onKeyAuthenCodePress}
                                placeholder="Input Code"
                            />
                            {twoFactorCode.error ? (
                                <small className={classes.helperText}>
                                    {twoFactorCode.error}
                                </small>
                            ) : null}
                        </Box>
                        <Box className={classes.formGroup}>
                            <Button
                                fullWidth
                                className={classes.disableButtonColor}
                                variant="contained"
                                color="primary"
                                onClick={handleAuthenCodeSubmit}
                                disabled={disableAuthenCodeSubmit}
                            >
                                Log in
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            {isLoading ? (
                <Box className={classes.flexible}>
                    <CircularProgress size={100} />
                </Box>
            ) : null}
        </AuthLayout>
    );
};
