import {Action} from 'redux';
import {IPlan} from '../types/plan';

export const SET_PLAN = 'SET_PLAN';
export type SET_PLAN = typeof SET_PLAN;

export interface ISetPlanAction extends Action<SET_PLAN> {
    payload: IPlan[];
}

export const setPlanAction = (payload: IPlan[]): ISetPlanAction => {
    return {
        type: SET_PLAN,
        payload,
    };
};
