export const DEFAULT_WALLETS = [
    {
        address: '',
        coin: {
            actFlag: true,
            createAt: '0001-01-01T00:00:00+00:00',
            updateAt: null,
            id: '454a410b-010e-4ff9-94eb-8444796d3caa',
            code: 'ETH',
            name: 'Ethereum',
            image: null,
            isBaseAsset: true,
            feeWithdraw: 0.0001,
            minimumWithdraw: 0.0001,
            frozenFlag: false,
        },
    },
    {
        address: '',
        coin: {
            actFlag: true,
            createAt: '0001-01-01T00:00:00+00:00',
            updateAt: null,
            id: '61f08ba8-b11d-47e9-b696-37b8bf8d153d',
            code: 'BTC',
            name: 'Bitcoin',
            image: null,
            isBaseAsset: true,
            feeWithdraw: 0.0001,
            minimumWithdraw: 0.0001,
            frozenFlag: false,
        },
    },
];
