import {store} from '..';
import {setUserProfileAction} from '../actions/auth';
import {
    setBalanceAction,
    setMiningDataAction,
    setMiningRewardAction,
    setWalletAction,
    setTwoFactorAction,
    setEmptyChartData,
} from '../actions/user';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {
    UserProfile,
    IBalance,
    IMiningData,
    IMiningReward,
    IMiningChartRequest,
} from '../types/user';
import {
    fireErrorNotification,
    fireSuccessNotification,
} from '../helpers/notification';
import {IWallet, TwoFactor} from '../types/user';

export const getUserProfileMiddleware = () => {
    Axios.get('/user').then((response: AxiosResponse<{data: UserProfile}>) => {
        store.dispatch(setUserProfileAction(response.data.data));
    });
};

export const updateUserProfileMiddleware = (userProfile: UserProfile) => {
    Axios.put('/user', userProfile)
        .then((response: AxiosResponse) => {
            getUserProfileMiddleware();
            fireSuccessNotification(response.data.message);
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const changePasswordMiddleware = (data: {
    oldPassword: string;
    newPassword: string;
}) => {
    return new Promise((resolve, reject) => {
        Axios.post('/auth/changePassword', data)
            .then((response: AxiosResponse) => {
                resolve(true);
                fireSuccessNotification(response.data.message);
            })
            .catch((error: AxiosError) => {
                fireErrorNotification(error);
                reject(error);
            });
    });
};

export const getUserWalletsMiddleware = () => {
    Axios.get('/user/wallets').then(
        (response: AxiosResponse<{data: IWallet[]}>) => {
            store.dispatch(setWalletAction(response.data.data));
        },
    );
};

export const updateUserWalletsMiddleware = (wallets: IWallet[]) => {
    const body = wallets.map((wallet) => {
        return {coinCode: wallet.coin.code, address: wallet.address};
    });
    Axios.post('/user/wallets', body)
        .then((response: AxiosResponse) => {
            fireSuccessNotification(response.data.message);
            getUserWalletsMiddleware();
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const get2FactorAuthKey = () => {
    Axios.get('/user/GetTwoFactor')
        .then((response: AxiosResponse<{data: TwoFactor}>) => {
            store.dispatch(setTwoFactorAction(response.data.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const activeTwoFactor = (
    params: {
        twoFactorCode: string;
        password: string;
    },
    callback: () => void,
) => {
    Axios.post('/user/ActiveTwoFactor', params)
        .then((response: AxiosResponse) => {
            fireSuccessNotification(response.data.message);
            callback();
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const deactiveTwoFactor = (
    params: {
        twoFactorCode: string;
        password: string;
    },
    callback: () => void,
) => {
    Axios.post('/user/DeactiveTwoFactor', params)
        .then((response: AxiosResponse) => {
            fireSuccessNotification(response.data.message);
            callback();
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const getBalances = () => {
    Axios.get('/User/Balances')
        .then((response: AxiosResponse<{data: IBalance[]}>) => {
            store.dispatch(setBalanceAction(response.data.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const getMiningData = () => {
    //ByPlanType
    Axios.get('/User/Dashboard')
        .then((response: AxiosResponse<{data: IMiningData[]}>) => {
            store.dispatch(setMiningDataAction(response.data.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const setLoaddedMiningData = () => {
    store.dispatch(setEmptyChartData());
};

export const getMiningReward = (params: IMiningChartRequest) => {
    const {coinCode, startDate, endDate, currency, range} = params;
    Axios.post(`/User/MiningRewardsByDate`, {
        coinCode,
        startDate,
        endDate,
    })
        .then((response: AxiosResponse<{data: IMiningReward[]}>) => {
            store.dispatch(
                setMiningRewardAction({
                    coinCode,
                    startDate,
                    endDate,
                    currency,
                    range,
                    data: response.data.data,
                }),
            );
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const requestContactService = (params: {
    subject: string;
    content: string;
}) => {
    return new Promise((resolve) => {
        Axios.post(`/CustomerService`, params)
            .then((_response: AxiosResponse<{message: string}>) => {
                fireSuccessNotification(
                    'Thank you! We will reply you as soon as possible.',
                );
                resolve(true);
            })
            .catch((error: AxiosError) => {
                fireErrorNotification(error);
            });
    });
};
