import React from 'react';
import {useSelector} from 'react-redux';
import {StoreState} from '../types/store-state';
import {updateNotificationAction} from '../actions/notification';
import {useDispatch} from 'react-redux';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {Snackbar, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    muiAlert: {
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0.3,
        color: theme.palette.neutral.white,
        fontFamily: 'Poppins',
        backgroundColor: theme.palette.neutral.main,
        boxShadow: 'none',
        '&.MuiAlert-filledError': {
            border: `2px solid ${theme.palette.semantic.main}`,
        },
        '&.MuiAlert-filledSuccess': {
            border: `2px solid ${theme.palette.semantic.secondary}`,
        },
        '&.MuiAlert-filledWarning': {
            border: `2px solid ${theme.palette.primary.main}`,
        },
        '&.MuiAlert-filledInfo': {
            border: `2px solid ${theme.palette.neutral.purple1}`,
        },
    },
}));

const Alert = (props: AlertProps) => {
    const classes = useStyles();
    return (
        <MuiAlert
            className={classes.muiAlert}
            elevation={6}
            variant="filled"
            {...props}
        />
    );
};
export const Notification = () => {
    const {notification} = useSelector((state: StoreState) => state);
    const token = localStorage.getItem('token');
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(
            updateNotificationAction({
                ...notification,
                isShow: false,
            }),
        );
    };

    const getNotificationPosition = () => {
        const windowScrollY = window.scrollY;
        if (windowScrollY === 0) {
            if (token) {
                return 200;
            }
        }
        return 24;
    };
    if (!notification.message) {
        return null;
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={notification.isShow}
            autoHideDuration={3000}
            onClose={handleClose}
            message={notification.message}
            style={{
                top: getNotificationPosition(),
            }}
        >
            <Alert onClose={handleClose} severity={notification.type}>
                {notification.message}
            </Alert>
        </Snackbar>
    );
};
