export const LAST_SEVEN_DAYS = 7;
export const LAST_FOURTEEN_DAYS = 14;
export const LAST_MONTH = 30;
export const LAST_TWO_MONTH = 60;
export const DEFAULT_DATE_RANGE = LAST_SEVEN_DAYS;

export const OUTPUT_CHART_DATE_RANGES = [
    {
        label: 'Last 7 days',
        value: LAST_SEVEN_DAYS,
    },
    {
        label: 'Last 14 days',
        value: LAST_FOURTEEN_DAYS,
    },
    {
        label: 'Last Month',
        value: LAST_MONTH,
    },
    {
        label: 'Last 2 Months',
        value: LAST_TWO_MONTH,
    },
];
