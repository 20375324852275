import React, {useState} from 'react';
import {
    Typography,
    Button,
    makeStyles,
    Box,
    TextField,
    CircularProgress,
} from '@material-ui/core';
import {find} from 'lodash';
import {ReCaptcha} from '../../components/ReCAPTCHA';
import {resetPasswordMiddleware} from '../../middlewares/auth';
import {pushTo} from '../../helpers/history';
import {PATH} from '../../constants/path';
import {AuthLayout} from '../../components/AuthLayout';
import {MOBILE_WIDTH} from '../../constants/common';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.neutral.hashrate,
        maxWidth: 482,
        padding: '40px 35px 1px',
    },
    containerMobile: {
        backgroundColor: theme.palette.neutral.hashrate,
        padding: '40px 15px 1px',
    },
    formTitle: {
        height: 45,
        fontFamily: 'Poppins',
        fontSize: 32,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.8,
        textAlign: 'center',
        color: theme.palette.neutral.white,
        marginBottom: 50,
    },
    formSubTitle: {
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0.3,
        color: theme.palette.neutral.white,
    },
    formGroup: {
        marginBottom: 32,
        width: '100%',
        '& label': {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.neutral.gray,
            fontSize: 14,
            marginBottom: 8,
            display: 'inline-block',
        },
        '& small': {
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.semantic.main,
        },
    },
    formHeight: {
        minHeight: 227,
    },
    helperText: {
        color: theme.palette.semantic.main,
    },
    disableButtonColor: {
        height: 48,
        color: theme.palette.neutral.white,
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.88,
        letterSpacing: 0.4,
        '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.5,
        },
    },
    signUpLink: {
        maxWidth: 482,
        textAlign: 'center',
        marginTop: 32,
        color: theme.palette.neutral.white,
        '& p': {
            fontSize: 16,
            '& span': {
                color: theme.palette.primary.main,
                cursor: 'pointer',
            },
        },
    },
    flexible: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: 999999,
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
    },
    errorMessage: {
        padding: '15px 16px 13px',
        borderRadius: 4,
        border: '2px solid #f5525a',
        backgroundColor: 'rgba(245, 82, 90, 0.2)',
        margin: '32px 0',
        '& .text': {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.57,
            letterSpacing: 0.24,
            color: theme.palette.neutral.white,
        },
    },
}));

export const ResetPassword = ({location}) => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token') || '';
    const id = urlParams.get('id') || '';
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [state, setState] = useState({
        password: {value: '', error: ''},
        repassword: {value: '', error: ''},
        reCaptcha: {value: '', error: ''},
    });

    const handleSubmit = async () => {
        setErrorMessage('');
        resetPasswordMiddleware({
            password: state.password.value,
            token: token,
            id: id,
        })
            .then(() => {
                setIsLoading(false);
                setIsSent(true);
            })
            .catch((error) => {
                setErrorMessage(error);
                setIsLoading(false);
            });
    };

    const onChangeText = (key: string) => (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (key === 'password') {
            setState({
                ...state,
                password: {
                    error:
                        event.target.value.length < 6
                            ? 'Password is too short'
                            : '',
                    value: event.target.value,
                },
                repassword: {
                    error: !state.repassword.value
                        ? ''
                        : event.target.value.length < 6
                        ? 'Confirm password is too short'
                        : event.target.value !== state.repassword.value
                        ? 'Password is not match'
                        : '',
                    value: state.repassword.value,
                },
            });
        }
        if (key === 'repassword') {
            setState({
                ...state,
                password: {
                    error:
                        state.password.value.length < 6
                            ? 'Password is too short'
                            : '',
                    value: state.password.value,
                },
                repassword: {
                    error: !state.repassword.value
                        ? ''
                        : event.target.value.length < 6
                        ? 'Confirm password is too short'
                        : event.target.value !== state.password.value
                        ? 'Password not match'
                        : '',
                    value: event.target.value,
                },
            });
        }
    };

    const onChangeReCaptcha = (token: string | null) =>
        setState({
            ...state,
            reCaptcha: {
                value: token || '',
                error: '',
            },
        });

    const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && disableSubmit === false) {
            event.preventDefault();
            handleSubmit();
        }
    };

    const disableSubmit = Boolean(
        find(state, (el) => el.value === '' || el.error !== ''),
    );

    const goToLogin = () => {
        pushTo(PATH.login);
    };

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <AuthLayout>
            <Box>
                <Box
                    className={
                        isMobile ? classes.containerMobile : classes.container
                    }
                >
                    <Box className={classes.formGroup}>
                        <Typography className={classes.formTitle}>
                            Reset Password
                        </Typography>
                    </Box>
                    {errorMessage !== '' ? (
                        <Box className={classes.errorMessage}>
                            <Typography className="text">
                                {errorMessage}
                            </Typography>
                        </Box>
                    ) : null}
                    <Box className={classes.formGroup}>
                        <Typography className={classes.formSubTitle}>
                            {isSent
                                ? 'Reset password successfully.'
                                : 'Enter your new password'}
                        </Typography>
                    </Box>
                    {!isSent ? (
                        <Box>
                            <Box className={classes.formGroup}>
                                <label>Password</label>
                                <TextField
                                    fullWidth
                                    type="password"
                                    variant="outlined"
                                    value={state.password.value}
                                    error={state.password.error ? true : false}
                                    onChange={onChangeText('password')}
                                    onKeyPress={onKeyPress}
                                />
                                {state.password.error ? (
                                    <small className={classes.helperText}>
                                        {state.password.error}
                                    </small>
                                ) : null}
                            </Box>
                            <Box className={classes.formGroup}>
                                <label>Confirm Password</label>
                                <TextField
                                    fullWidth
                                    type="password"
                                    variant="outlined"
                                    value={state.repassword.value}
                                    error={
                                        state.repassword.error ? true : false
                                    }
                                    onChange={onChangeText('repassword')}
                                    onKeyPress={onKeyPress}
                                />
                                {state.repassword.error ? (
                                    <small className={classes.helperText}>
                                        {state.repassword.error}
                                    </small>
                                ) : null}
                            </Box>
                            <Box className={classes.formGroup}>
                                <ReCaptcha
                                    onChange={onChangeReCaptcha}
                                    containerStyle={{marginTop: 20}}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Box className={classes.formHeight}></Box>
                    )}
                    <Box className={classes.formGroup}>
                        <Button
                            fullWidth
                            className={classes.disableButtonColor}
                            variant="contained"
                            color="primary"
                            onClick={isSent ? goToLogin : handleSubmit}
                            disabled={disableSubmit}
                        >
                            {isSent ? 'Back to Log In' : 'Send'}
                        </Button>
                    </Box>
                </Box>
                <Box className={classes.signUpLink}>
                    <Typography>
                        Already have an account?{' '}
                        <span onClick={goToLogin}>Log In here</span>
                    </Typography>
                </Box>
            </Box>
            {isLoading ? (
                <Box className={classes.flexible}>
                    <CircularProgress size={100} />
                </Box>
            ) : null}
        </AuthLayout>
    );
};
