import React, {useState, useEffect} from 'react';
import {Grid, makeStyles, Box, Typography} from '@material-ui/core';
import {HeadingTab} from './HeadingTab';
import {isJsonString} from '../../helpers/validate';
import {pushTo} from '../../helpers/history';
import Button from '../../components/Button';
import CopyButton from '../../components/CopyButton';
import Modal from '../../components/Modal';
import {PATH} from '../../constants/path';
import {ORDER_WAS_PAID, ORDER_WAS_EXPIRED} from '../../constants/order';
import {ICoin} from '../../types/coin';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {getOrder} from '../../middlewares/order';
import SampleQrCode from '../../assets/images/sample-qrcode.png';
import ConfirmDialogIcon from '../../assets/images/confirm-dialog.png';
import AngleLeftImg from '../../assets/images/icons/angle-left.png';
import WarningIcon from '../../assets/images/icons/icons-sematic-warning-triangle.svg';
import InfoIcon from '../../assets/images/icons/sematic-info.svg';
import {MOBILE_WIDTH} from '../../constants/common';
import {truncate} from 'lodash';

const useStyles = makeStyles((theme) => ({
    paymentWrapper: {
        '& .payment-info': {
            '&.mobile': {
                '& .flexible': {
                    flexDirection: 'column',
                    padding: 16,
                    '& .info': {
                        width: '100%',
                        padding: 0,
                        '&.right': {
                            borderLeft: 'none',
                            borderTop: `1px solid ${theme.palette.neutral.white}`,
                            marginTop: 12,
                            paddingTop: 12,
                            paddingLeft: 0,
                            paddingRight: 0,
                        },
                        '& .heading': {
                            fontSize: 16,
                        },
                        '& .timer-expired': {
                            flexDirection: 'column',
                            width: '100%',
                            '& .timer-description': {
                                width: '100%',
                                marginBottom: 16,
                            },
                            '& .buy-again-btn': {
                                color: theme.palette.neutral.white,
                                backgroundColor: theme.palette.primary.main,
                            },
                        },
                    },
                },
                '& .payment-note': {
                    wordBreak: 'break-word',
                    padding: 16,
                },
            },
            '& .flexible': {
                minHeight: 74,
                borderRadius: 2,
                backgroundColor: theme.palette.primary.dark,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px 0',
                '& .info': {
                    padding: '0 32px',
                    width: '50%',
                    height: '100%',
                    '&.right': {
                        borderLeft: `1px solid ${theme.palette.neutral.white}`,
                    },
                    '& .heading': {
                        fontSize: 20,
                        fontWeight: 'bold',
                        lineHeight: 1.5,
                        letterSpacing: 0.5,
                        marginBottom: 8,
                        '& .time': {
                            color: theme.palette.primary.dark,
                            backgroundColor: theme.palette.neutral.white,
                            display: 'inline-block',
                            marginLeft: 5,
                            padding: '1px 6px',
                        },
                    },
                    '& .text': {
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: 1.75,
                        letterSpacing: 0.3,
                    },
                    '& .timer-expired': {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '& .buy-again-btn': {
                            color: theme.palette.neutral.white,
                            backgroundColor: theme.palette.primary.main,
                        },
                    },
                },
            },
            '& .payment-note': {
                backgroundColor: theme.palette.neutral.hashrate,
                padding: '21px 32px',
                marginTop: 16,
                borderRadius: 4,
                color: theme.palette.neutral.white,
                fontSize: 16,
                fontWeight: 500,
                lineHeight: 1.75,
                letterSpacing: 0.3,
                '& .hightlight': {
                    color: theme.palette.primary.main,
                },
            },
        },
        '& .section': {
            marginTop: 30,
            marginBottom: 100,
            '&.mobile': {
                '& .title': {
                    fontSize: 24,
                },
                '& .panel-payment': {
                    '& .payment-body': {
                        padding: 0,
                        '& .block': {
                            marginBottom: 0,
                            '& .value': {
                                wordBreak: 'break-word',
                            },
                            '& .recipient': {
                                '& .flexible': {
                                    flexDirection: 'column',
                                    '& .your-recipient': {
                                        marginRight: 0,
                                        width: 'calc(100% - 32px)',
                                        marginBottom: 12,
                                    },
                                    '& .copy-btn': {
                                        width: '100%',
                                    },
                                },
                            },
                        },
                    },
                },
            },
            '& .title': {
                fontSize: 32,
                fontWeight: 'bold',
                letterSpacing: 0.6,
                color: theme.palette.neutral.white,
                marginBottom: 32,
            },
            '& .panel-payment': {
                backgroundColor: theme.palette.neutral.hashrate,
                borderRadius: 4,
                '& .payment-body': {
                    padding: '15px 15px 0',
                },
                '& .block': {
                    borderRadius: 8,
                    border: `2px solid ${theme.palette.neutral.purple1}`,
                    margin: 15,
                    padding: '6px 24px 17px',
                    '& .p-title': {
                        fontSize: 16,
                        fontWeight: 600,
                        lineHeight: 1.88,
                        letterSpacing: 0.4,
                        color: theme.palette.neutral.white,
                        marginTop: 16,
                        '&.gray': {
                            color: theme.palette.neutral.gray,
                        },
                    },
                    '& .value': {
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: 1.75,
                        letterSpacing: 0.5,
                        color: theme.palette.primary.main,
                        marginTop: 8,
                    },
                    '& .recipient': {
                        marginTop: 9,
                        '& label': {
                            fontWeight: 500,
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 1.67,
                            letterSpacing: 0.38,
                            color: theme.palette.neutral.gray,
                            fontSize: 12,
                            marginBottom: 8,
                            display: 'inline-block',
                        },
                        '& .flexible': {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '& .your-recipient': {
                                fontWeight: 500,
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                lineHeight: 1.67,
                                letterSpacing: 0.38,
                                color: theme.palette.neutral.lighter,
                                backgroundColor: theme.palette.neutral.dark,
                                fontSize: 14,
                                padding: '0 16px',
                                marginRight: 24,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                height: 44,
                            },
                            '& .copy-btn': {
                                backgroundColor: theme.palette.primary.dark,
                                height: 48,
                            },
                        },
                    },
                    '& .notice': {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 12,
                        '& img': {
                            height: 32,
                            width: 36,
                            marginRight: 14,
                            '&.one': {
                                width: 32,
                                marginRight: 16,
                            },
                        },
                        '& .text': {
                            fontSize: 12,
                            fontWeight: 500,
                            lineHeight: 1.83,
                            letterSpacing: 0.2,
                        },
                    },
                },
                '& .block-right': {
                    borderRadius: 8,
                    border: `2px solid ${theme.palette.neutral.purple1}`,
                    padding: '24px 32px 40px',
                    margin: 15,
                    textAlign: 'center',
                    height: 536,
                    '& .subtitle': {
                        fontSize: 24,
                        fontWeight: 'bold',
                        lineHeight: 1.5,
                        letterSpacing: 0.4,
                        color: theme.palette.neutral.white,
                        marginBottom: 12,
                    },
                    '& .p-subtitle': {
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: 1.5,
                        letterSpacing: 0.5,
                        color: theme.palette.neutral.white,
                        marginBottom: 28,
                    },
                    '& .payment-qrcode': {
                        width: '100%',
                        maxWidth: 391,
                        borderRadius: 4,
                    },
                },
                '& .check-payment-wrapper': {
                    width: '100%',
                    padding: '15px 15px 39px',
                    textAlign: 'center',
                    '& .check-payment-btn': {
                        width: '100%',
                        maxWidth: 552,
                        height: 44,
                        boxShadow: '0 4px 8px 0 rgba(19, 14, 70, 0.2)',
                    },
                },
            },
        },
        '& .back-to-plans': {
            margin: '2px 0 17px',
            padding: '0px 15px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '& p': {
                color: theme.palette.neutral.gray,
                fontSize: 14,
                fontWeight: 500,
                lineHeight: 1.71,
                letterSpacing: 0.24,
            },
            '& img': {
                width: 24,
                height: 24,
                backgroundColor: 'rgba(134, 121, 181, 0.5)',
                marginRight: 10,
                borderRadius: '50%',
            },
        },
    },
    confirmationDialog: {
        textAlign: 'center',
        '& .title': {
            fontSize: 32,
            fontWeight: 'bold',
            letterSpacing: 0.6,
            color: theme.palette.neutral.white,
            marginBottom: 32,
            marginTop: 15,
        },
        '& .icon': {
            width: '100%',
            maxWidth: 189,
            marginTop: 16,
            marginBottom: 8,
        },
        '& .information': {
            textAlign: 'left',
            '& .p-title': {
                fontSize: 16,
                fontWeight: 600,
                lineHeight: 1.88,
                letterSpacing: 0.4,
                color: theme.palette.neutral.white,
                marginTop: 16,
            },
            '& .value': {
                fontSize: 16,
                fontWeight: 500,
                lineHeight: 1.75,
                letterSpacing: 0.5,
                color: theme.palette.primary.main,
                marginTop: 8,
                wordBreak: 'break-word',
            },
        },
    },
}));

export const PaymentPurchase = () => {
    const classes = useStyles();
    const [selectedCoin, setSelectedCoin] = useState<ICoin>();
    const {order} = useSelector((state: StoreState) => state);
    const [orderWasExpired, setOrderWasExpired] = useState<boolean>(true);
    const [isOpenConfirmDialog, setOpenConfirmDialog] = useState<boolean>(
        false,
    );
    const [checkTransaction, setCheckTransaction] = useState<any>(null);

    useEffect(() => {
        const orderId: string = sessionStorage.getItem('orderId') ?? '';
        getOrder({orderId: orderId});
        //// get selected coin
        const encodePayWithCoin: any = sessionStorage.getItem('payWithCoin');
        if (!isJsonString(encodePayWithCoin)) {
            /// redirect to select plans page
            pushTo(PATH.selectPlansPurchase);
            return;
        }
        const payWithCoin = JSON.parse(encodePayWithCoin);
        setSelectedCoin(payWithCoin);
    }, []);

    useEffect(() => {
        const orderId: string = sessionStorage.getItem('orderId') ?? '';
        if (order && order.id === orderId) {
            if (order.status === ORDER_WAS_EXPIRED) {
                if (checkTransaction) {
                    clearInterval(checkTransaction);
                }
                return;
            }

            if (order.status === ORDER_WAS_PAID) {
                if (checkTransaction) {
                    clearInterval(checkTransaction);
                }
                pushTo(PATH.confirmPurchase);
                return;
            }
            setOrderWasExpired(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    useEffect(() => {
        if (!isOpenConfirmDialog) {
            if (checkTransaction) {
                clearInterval(checkTransaction);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenConfirmDialog]);

    const handleCheckTransaction = () => {
        if (order) {
            setOpenConfirmDialog(true);
            getOrder({orderId: order.id});
            if (checkTransaction) {
                clearInterval(checkTransaction);
            }
            setCheckTransaction(
                setInterval(() => {
                    getOrder({orderId: order.id});
                }, 5000),
            );
        }
    };

    const copyAddress = () => {
        if (order?.systemWallet?.address) {
            navigator.clipboard.writeText(order.systemWallet.address);
        }
    };

    const backToPlans = () => {
        pushTo(PATH.selectPlansPurchase);
    };

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <Grid container spacing={3} className={classes.paymentWrapper}>
            <Grid item xs={12}>
                <HeadingTab value={3} />
            </Grid>
            <Grid item xs={12} className="back-to-plans" onClick={backToPlans}>
                <img src={AngleLeftImg} alt="back to plans" />
                <Typography>Back to plans</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box className={`payment-info ${isMobile ? 'mobile' : ''}`}>
                    <Box className="flexible">
                        <Box className="info">
                            <Typography className="heading">
                                Order information
                            </Typography>
                            <Typography className="text">
                                Please send {order?.paymentAmount}{' '}
                                {selectedCoin?.code} to the recipient address.
                            </Typography>
                        </Box>
                        <Box className="info right">
                            {order && !orderWasExpired ? (
                                <ExpireTimer
                                    createAt={order.createAt}
                                    setOrderWasExpired={setOrderWasExpired}
                                />
                            ) : (
                                <TimeEnd />
                            )}
                        </Box>
                    </Box>
                    {orderWasExpired ? null : (
                        <Box className="payment-note">
                            Please send{' '}
                            <span className="hightlight">
                                {order?.paymentAmount} {selectedCoin?.code}
                            </span>{' '}
                            to the following address{' '}
                            <span className="hightlight">
                                {order?.systemWallet?.address}
                            </span>{' '}
                            or{' '}
                            <span className="hightlight">scan the QR code</span>{' '}
                            on the right. As soon as we receive your payment the
                            hashpower will be credited to your account and you
                            will start mining. We will send you an e-mail when
                            that happens.
                        </Box>
                    )}
                </Box>
            </Grid>
            {orderWasExpired ? null : (
                <Grid
                    item
                    xs={12}
                    className={`section ${isMobile ? 'mobile' : ''}`}
                >
                    <Typography variant="h1" className="title">
                        Payment
                    </Typography>
                    <Grid item xs={12} className="panel-payment">
                        <Grid container className="payment-body">
                            <Grid item md={6} className="full-width">
                                <Box className="block">
                                    <Box>
                                        <Typography className="p-title">
                                            Payment Information
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography className="p-title gray">
                                            Invoice ID:
                                        </Typography>
                                        <Typography className="value">
                                            {order?.id}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography className="p-title gray">
                                            Payment amount:
                                        </Typography>
                                        <Typography className="value">
                                            {order?.paymentAmount}{' '}
                                            {selectedCoin?.code}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography className="p-title gray">
                                            Recipient address:
                                        </Typography>
                                        <Typography className="value">
                                            {order?.systemWallet?.address}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className="block">
                                    <Box>
                                        <Typography className="p-title">
                                            Recipient address
                                        </Typography>
                                    </Box>
                                    <Box className="recipient">
                                        <label>Recipient address</label>
                                        <Box className="flexible">
                                            <Typography className="your-recipient">
                                                {order?.systemWallet?.address
                                                    ? truncate(
                                                          order.systemWallet
                                                              .address,
                                                      )
                                                    : null}
                                            </Typography>
                                            <CopyButton
                                                variant="contained"
                                                disableElevation
                                                color="primary"
                                                className="copy-btn"
                                                onClick={copyAddress}
                                            >
                                                Copy
                                            </CopyButton>
                                        </Box>
                                    </Box>
                                    <Box className="notice">
                                        <img
                                            src={WarningIcon}
                                            alt="Do not copy the address by hand! Incorrect addresses may result in transfers to the wrong account and cannot be undone."
                                        />
                                        <Typography className="text">
                                            Do not copy the address by hand!
                                            Incorrect addresses may result in
                                            transfers to the wrong account and
                                            cannot be undone.
                                        </Typography>
                                    </Box>
                                    <Box className="notice">
                                        <img
                                            src={InfoIcon}
                                            className="one"
                                            alt="Keep in mind that although most BTC transfers are fast, it can take up to 72 hours to process."
                                        />
                                        <Typography className="text">
                                            Keep in mind that although most BTC
                                            transfers are fast, it can take up
                                            to 72 hours to process.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} className="full-width">
                                <Box className="block-right">
                                    <Typography className="subtitle">
                                        Recipient address QR code
                                    </Typography>
                                    <Typography className="p-subtitle">
                                        We recommend scanning the QR code for
                                        safety reasons
                                    </Typography>
                                    <img
                                        alt="qrcode"
                                        src={
                                            order?.systemWallet
                                                ?.paymentQrCode ?? SampleQrCode
                                        }
                                        className="payment-qrcode"
                                    />
                                </Box>
                            </Grid>
                            <Box className="check-payment-wrapper">
                                <Button
                                    variant="contained"
                                    disableElevation
                                    color="primary"
                                    className="check-payment-btn"
                                    onClick={handleCheckTransaction}
                                >
                                    Check Payment
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <Modal
                open={isOpenConfirmDialog}
                handleClose={() => setOpenConfirmDialog(false)}
            >
                <Box className={classes.confirmationDialog}>
                    <Typography variant="h1" className="title">
                        Confirmation
                    </Typography>
                    <img
                        src={ConfirmDialogIcon}
                        alt="confirm"
                        className="icon"
                    />
                    <Box className="information">
                        <Box>
                            <Typography className="p-title gray">
                                Payment Status
                            </Typography>
                            <Typography className="value">
                                Waiting for confirm
                            </Typography>
                        </Box>
                        <Box>
                            <Typography className="p-title gray">
                                Payment amount:
                            </Typography>
                            <Typography className="value">
                                {order?.paymentAmount} {selectedCoin?.code}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography className="p-title gray">
                                Recipient address:
                            </Typography>
                            <Typography className="value">
                                {order?.systemWallet?.address}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Grid>
    );
};

const ExpireTimer = (props: {
    createAt?: string;
    setOrderWasExpired: (value: boolean) => void;
}) => {
    const {createAt, setOrderWasExpired} = props;
    const [minutes, setMinutes] = useState<number>(0);
    const [seconds, setSeconds] = useState<number>(0);
    const [isStart, setIsStart] = useState<boolean>(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [timer, setTimer] = useState<any>(null);

    useEffect(() => {
        /// componentWillUnmount
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [timer]);

    useEffect(() => {
        if (!createAt) {
            return; // do nothing
        }
        const timeCreateAt = new Date(createAt);
        /// expired in 30 min
        timeCreateAt.setMinutes(timeCreateAt.getMinutes() + 30);
        // get current date
        const now = new Date();
        /// get different time in seconds
        const diffInSeconds = Math.round(
            (timeCreateAt.getTime() - now.getTime()) / 1000,
        );
        // check if timer is < 0
        if (diffInSeconds <= 0) {
            setIsCompleted(true);
            setOrderWasExpired(true);
            return; /// end
        }
        // parse to timer
        setMinutes(Math.floor(diffInSeconds / 60));
        setSeconds(diffInSeconds % 60);
        setIsStart(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createAt]);

    useEffect(() => {
        if (isStart) {
            setTimer(
                setInterval(() => {
                    setSeconds((prevSeconds) => {
                        setMinutes((prevMinutes) => {
                            if (prevMinutes - 1 < 0 && prevSeconds - 1 === 0) {
                                // expired
                                clearInterval(timer);
                            }
                            return prevMinutes;
                        });
                        if (prevSeconds - 1 < 0) {
                            setMinutes((prevMinutes) => prevMinutes - 1);
                            return 59;
                        }
                        return prevSeconds - 1;
                    });
                }, 1000),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStart]);

    useEffect(() => {
        if (minutes === 0 && seconds === 0 && isStart) {
            setIsCompleted(true);
            setOrderWasExpired(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minutes, seconds]);

    if (isCompleted) {
        return <TimeEnd />;
    }
    return (
        <Box>
            <Typography className="heading">
                Your order expires in :
                <span className="time">
                    {minutes < 10 ? `0${minutes}` : minutes}:{' '}
                    {seconds < 10 ? `0${seconds}` : seconds}
                </span>
            </Typography>
            <Typography className="text">
                Please finish the payment before the order expires
            </Typography>
        </Box>
    );
};

const TimeEnd = () => {
    return (
        <Box className="timer-expired">
            <Box className="timer-description">
                <Typography className="heading">
                    Your order has been expired
                </Typography>
                <Typography className="text">
                    You can try again with payment
                </Typography>
            </Box>
            <Button
                variant="contained"
                color="primary"
                onClick={() => pushTo(PATH.selectPlansPurchase)}
                className="buy-again-btn"
            >
                Re-order
            </Button>
        </Box>
    );
};
