import {Notification} from '../types/notification';
import {
    updateNotificationAction,
    UPDATE_NOTIFICATION,
} from '../actions/notification';

export const notification = (
    state: Notification = {isShow: false, message: '', type: 'info'},
    action: updateNotificationAction,
) => {
    if (action.type === UPDATE_NOTIFICATION) {
        return action.payload;
    }
    return state;
};
