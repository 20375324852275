import React, {useState, useEffect} from 'react';
import {PageLayout} from '../../components/PageLayout';
import Table from '../../components/Table';
import {Grid, makeStyles, Box, Typography} from '@material-ui/core';
import {
    getPendingOrders,
    getActiveOrders,
    getEndedOrders,
} from '../../middlewares/order';
import {IOrderSumary, IUserOrderPaginationData} from '../../types/order';
import {ICoin} from '../../types/coin';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {pushTo} from '../../helpers/history';
import {PATH} from '../../constants/path';
import {isEmpty, isUndefined} from 'lodash';
import moment from 'moment';
import EmptyDataImg from '../../assets/images/empty-order-data.png';
import AngleRightTwoImg from '../../assets/images/icons/icons-angle-right-two.png';
import {MOBILE_WIDTH} from '../../constants/common';
import {DEFAULT_PAGE_SIZE} from '../../constants/pagination';

const useStyles = makeStyles((theme) => ({
    orderWrapper: {
        '& .title': {
            fontSize: 32,
            fontWeight: 'bold',
            letterSpacing: 0.6,
            color: theme.palette.neutral.white,
            margin: '50px 0 24px',
            '&.mobile': {
                fontSize: 24,
            },
        },
    },
    href: {
        textDecoration: 'none',
        fontWeight: 600,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '& img': {
            marginRight: 4,
        },
    },
}));

export const Order = () => {
    const classes = useStyles();
    const {pendingOrders, activeOrders, endedOrders, coins} = useSelector(
        (state: StoreState) => state,
    );
    const [pendingOrderData, setPendingOrderData] = useState<{
        data: IOrderSumary[];
        isLoading?: boolean;
    }>({
        data: [],
        isLoading: true,
    });

    const [pendingColumns] = useState([
        {
            name: 'createAt',
            title: 'Created',
            renderer: (_record: any, value: string) =>
                moment(value).format('Y-MM-DD'),
        },
        {
            name: 'power',
            title: 'Hashpower',
            renderer: (record: any, value: number) =>
                `${value} ${record.plan?.coin?.powerUnit}`,
        },
        {
            name: 'algorithm',
            title: 'Algorithm',
            renderer: (record: any, _value: string) =>
                `${record.plan?.coin?.algorithm}`,
        },
        {name: 'id', title: 'Invoice ID'},
        {
            name: 'orderId',
            title: 'Info about order',
            renderer: (record: any, value: string) => (
                <Box
                    className={classes.href}
                    component="a"
                    onClick={(e) => continuePayment(e, value, record.coin)}
                >
                    <img src={AngleRightTwoImg} alt="Continue with payment" />
                    Continue with payment
                </Box>
            ),
        },
    ]);
    const [activeColumns] = useState([
        {
            name: 'createAt',
            title: 'Order Create',
            renderer: (_record: IUserOrderPaginationData, value: string) =>
                moment(value).format('DD-MM-YYYY'),
        },
        {
            name: 'createAt',
            title: 'Start Date',
            renderer: (_record: IUserOrderPaginationData, value: string) =>
                moment(value).format('DD-MM-YYYY'),
        },
        {
            name: 'endDate',
            title: 'End date',
            renderer: (_record: IUserOrderPaginationData, value: string) =>
                moment(value).format('DD-MM-YYYY'),
        },
        {
            name: 'power',
            title: 'Hashpower',
            renderer: (record: IUserOrderPaginationData, value: number) =>
                `${value} ${record.plan.coin.powerUnit}`,
        },
        {
            name: 'period',
            title: 'Maintenance paid until',
            renderer: (record: IUserOrderPaginationData, _value: number) =>
                moment(record.endDate).format('DD-MM-YYYY'),
        },
        {
            name: 'type',
            title: 'Type',
            renderer: (record: IUserOrderPaginationData, _value: string) => {
                if (!record.plan.coin.image) {
                    return '';
                }
                return (
                    <img
                        src={record.plan.coin.image}
                        alt={record.plan.coin.name}
                        width="32"
                    />
                );
            },
        },
        {
            name: 'price',
            title: 'Price',
            renderer: (record: IUserOrderPaginationData, value: number) =>
                `${value} ${record.order.coin.code}`,
        },
        {
            name: 'price',
            title: 'Order Status',
            renderer: (_record: IUserOrderPaginationData, _value: number) =>
                `Active`,
        },
    ]);
    const [endedColumns] = useState([
        {
            name: 'createAt',
            title: 'Order Create',
            renderer: (_record: IUserOrderPaginationData, value: string) =>
                moment(value).format('DD-MM-YYYY'),
        },
        {
            name: 'createAt',
            title: 'Start Date',
            renderer: (_record: IUserOrderPaginationData, value: string) =>
                moment(value).format('DD-MM-YYYY'),
        },
        {
            name: 'endDate',
            title: 'End date',
            renderer: (_record: IUserOrderPaginationData, value: string) =>
                moment(value).format('DD-MM-YYYY'),
        },
        {
            name: 'power',
            title: 'Hashpower',
            renderer: (record: IUserOrderPaginationData, value: number) =>
                `${value} ${record.plan.coin.powerUnit}`,
        },
        {
            name: 'type',
            title: 'Type',
            renderer: (record: IUserOrderPaginationData, _value: string) => {
                if (!record.plan.coin.image) {
                    return '';
                }
                return (
                    <img
                        src={record.plan.coin.image}
                        alt={record.plan.coin.name}
                        width="32"
                    />
                );
            },
        },
        {
            name: 'price',
            title: 'Price',
            renderer: (record: IUserOrderPaginationData, value: number) =>
                `${value} ${record.order.coin.code}`,
        },
        {
            name: 'price',
            title: 'Order Status',
            renderer: (_record: any, _value: number) => `Ended`,
        },
    ]);

    const continuePayment = (
        _event: React.ChangeEvent<any>,
        orderId: string,
        coin: ICoin,
    ) => {
        // save to session
        sessionStorage.setItem('orderId', orderId);
        sessionStorage.setItem('payWithCoin', JSON.stringify(coin));

        /// remove temp orders
        sessionStorage.removeItem('orders');
        /// continue payment
        pushTo(PATH.paymentPurchase);
    };

    const [orderActivePageNumber, setOrderActivePageNumber] = useState<number>(
        1,
    );
    const [orderEndedPageNumber, setOrderEndedPageNumber] = useState<number>(1);

    const onChangeOrderActivePage = (pageNumber: number) => {
        if (pageNumber < 1 || pageNumber > activeOrders.pageInfo.totalPages) {
            return; /// do nothing
        }
        setOrderActivePageNumber(pageNumber);
    };
    const onChangeOrderEndedPage = (pageNumber: number) => {
        if (pageNumber < 1 || pageNumber > endedOrders.pageInfo.totalPages) {
            return; /// do nothing
        }
        setOrderEndedPageNumber(pageNumber);
    };

    useEffect(() => {
        getActiveOrders({
            PageSize: DEFAULT_PAGE_SIZE,
            PageNumber: orderActivePageNumber,
        });
    }, [orderActivePageNumber]);
    useEffect(() => {
        getEndedOrders({
            PageSize: DEFAULT_PAGE_SIZE,
            PageNumber: orderEndedPageNumber,
        });
    }, [orderEndedPageNumber]);

    //// call API
    useEffect(() => {
        getPendingOrders();
    }, []);

    /// pending order
    useEffect(() => {
        if (isUndefined(pendingOrders.data)) {
            setPendingOrderData({
                data: [],
                isLoading: false,
            });
            return;
        }
        if (!isEmpty(pendingOrders) && !isEmpty(coins)) {
            const pendingOrderSummaries: IOrderSumary[] = pendingOrders.data.reduce(
                (accumulator: any, pendingOrder) => {
                    const selectedCoin = coins.find(
                        (coin) => coin.id === pendingOrder.coinId,
                    );
                    if (!isEmpty(selectedCoin)) {
                        const orderSummaries = pendingOrder.orderSummaries.map(
                            (orderSumary) => {
                                return {
                                    ...orderSumary,
                                    coin: selectedCoin,
                                };
                            },
                        );
                        return [...accumulator, ...orderSummaries];
                    }
                    return [...accumulator];
                },
                [],
            );
            setPendingOrderData({
                data: pendingOrderSummaries,
                isLoading: pendingOrders.isLoading,
            });
        }
    }, [pendingOrders, coins]);

    const isMobile = window.outerWidth <= MOBILE_WIDTH;
    return (
        <PageLayout>
            <Grid container spacing={3} className={classes.orderWrapper}>
                <Grid item xs={12}>
                    <Box>
                        <Typography
                            className={`title ${isMobile ? 'mobile' : ''}`}
                        >
                            My active orders
                        </Typography>
                    </Box>
                    <Box>
                        <Table
                            columns={activeColumns}
                            data={activeOrders.data}
                            pageInfo={activeOrders.pageInfo}
                            isLoading={activeOrders.isLoading}
                            onChangePage={onChangeOrderActivePage}
                            noDataTitle="No Active orders"
                            noDataImg={EmptyDataImg}
                            dynamicHeight
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Typography
                            className={`title ${isMobile ? 'mobile' : ''}`}
                        >
                            My pending orders
                        </Typography>
                    </Box>
                    <Box>
                        <Table
                            columns={pendingColumns}
                            data={pendingOrderData.data}
                            isLoading={pendingOrderData.isLoading}
                            noDataTitle="No Pending orders"
                            noDataImg={EmptyDataImg}
                            dynamicHeight
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Typography
                            className={`title ${isMobile ? 'mobile' : ''}`}
                        >
                            My ended orders
                        </Typography>
                    </Box>
                    <Box>
                        <Table
                            columns={endedColumns}
                            data={endedOrders.data}
                            pageInfo={endedOrders.pageInfo}
                            isLoading={endedOrders.isLoading}
                            onChangePage={onChangeOrderEndedPage}
                            noDataTitle="No Ended orders"
                            noDataImg={EmptyDataImg}
                            dynamicHeight
                        />
                    </Box>
                </Grid>
            </Grid>
        </PageLayout>
    );
};

// export const
