import {Action} from 'redux';
import {IRank} from '../types/rank';

export const SET_RANK = 'SET_RANK';
export type SET_RANK = typeof SET_RANK;

export interface ISetRankAction extends Action<SET_RANK> {
    payload: IRank[];
}

export const setRankAction = (payload: IRank[]): ISetRankAction => {
    return {
        type: SET_RANK,
        payload,
    };
};
