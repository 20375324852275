export const isValidEmail = (email: string): boolean => {
    // eslint-disable-next-line no-useless-escape
    const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email);
};

export const generateNumberArray = (from: number, to: number) => {
    let array: number[] = [];
    let index = from;
    if (from < to) {
        while (index <= to) {
            array.push(index);
            index++;
        }
    } else {
        while (index >= to) {
            array.push(index);
            index--;
        }
    }
    return array;
};

export const daysInMonth = (month: number, year: number) => {
    return new Date(year, month, 0).getDate();
};

export const isJsonString = (encodeJson: any) => {
    try {
        const json = JSON.parse(encodeJson);
        if (json === null) {
            return false;
        }
        return typeof json === 'object';
    } catch (e) {
        return false;
    }
};
