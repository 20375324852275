import {
    SET_ORDER,
    SET_PENDING_ORDERS,
    SET_ACTIVE_ORDERS,
    SET_ENDED_ORDERS,
    ISetOrderAction,
    ISetPendingOrdersAction,
    ISetActiveOrdersAction,
    ISetEndedOrdersAction,
    SET_PENDING_ORDERS_LOADING,
    SET_ACTIVE_ORDERS_LOADING,
    SET_ENDED_ORDERS_LOADING,
    ISetPendingOrdersLoadingAction,
    ISetActiveOrdersLoadingAction,
    ISetEndedOrdersLoadingAction,
} from '../actions/order';
import {IOrder, IUserOrder, IUserOrderPagination} from '../types/order';

export const order = (state: IOrder | null = null, action: ISetOrderAction) => {
    if (action.type === SET_ORDER) {
        return action.payload;
    }
    return state;
};

// user pending order
const initUserOrder = {
    data: [],
    isLoading: true,
};
export const pendingOrders = (
    state: IUserOrder = initUserOrder,
    action: ISetPendingOrdersAction | ISetPendingOrdersLoadingAction,
) => {
    if (action.type === SET_PENDING_ORDERS) {
        const newState = {...state};
        newState.data = action.payload;
        newState.isLoading = false;
        return newState;
    }

    if (action.type === SET_PENDING_ORDERS_LOADING) {
        return {
            ...state,
            isLoading: true,
        };
    }
    return state;
};

const initUserOrderPagination = {
    data: [],
    isLoading: true,
    pageInfo: {
        currentPage: 1,
        hasNext: true,
        hasPrevious: false,
        pageSize: 1,
        totalCount: 1,
        totalPages: 1,
    },
};

// user active order
export const activeOrders = (
    state: IUserOrderPagination = initUserOrderPagination,
    action: ISetActiveOrdersAction | ISetActiveOrdersLoadingAction,
) => {
    if (action.type === SET_ACTIVE_ORDERS) {
        const newState = action.payload;
        if (!newState.pageInfo) {
            newState.pageInfo = initUserOrderPagination.pageInfo;
        }
        newState.isLoading = false;
        return newState;
    }
    if (action.type === SET_ACTIVE_ORDERS_LOADING) {
        return {
            ...state,
            isLoading: true,
        };
    }
    return state;
};
// user ended order
export const endedOrders = (
    state: IUserOrder = initUserOrder,
    action: ISetEndedOrdersAction | ISetEndedOrdersLoadingAction,
) => {
    if (action.type === SET_ENDED_ORDERS) {
        const newState = action.payload;
        if (!newState.pageInfo) {
            newState.pageInfo = initUserOrderPagination.pageInfo;
        }
        newState.isLoading = false;
        return newState;
    }
    if (action.type === SET_ENDED_ORDERS_LOADING) {
        return {
            ...state,
            isLoading: true,
        };
    }
    return state;
};
