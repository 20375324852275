import {Action} from 'redux';
import {IReferralRewardData} from '../types/referral';

export const SET_REFERRAL_REWARD = 'SET_REFERRAL_REWARD';
export type SET_REFERRAL_REWARD = typeof SET_REFERRAL_REWARD;

export interface ISetReferralRewardAction extends Action<SET_REFERRAL_REWARD> {
    payload: IReferralRewardData[];
}

export const setReferralRewardAction = (
    payload: IReferralRewardData[],
): ISetReferralRewardAction => {
    return {
        type: SET_REFERRAL_REWARD,
        payload,
    };
};

export const SET_REFERRAL_LOADING = 'SET_REFERRAL_LOADING';
export type SET_REFERRAL_LOADING = typeof SET_REFERRAL_LOADING;

export interface ISetReferralLoadingAction
    extends Action<SET_REFERRAL_LOADING> {}

export const setReferralLoadingAction = (): ISetReferralLoadingAction => {
    return {
        type: SET_REFERRAL_LOADING,
    };
};
