import React from 'react';
import {IconButton} from '@material-ui/core';
import {OfflineBolt} from '@material-ui/icons';

const AirbnbThumb = ({defaultProps}) => {
    const {style, ...other} = defaultProps;
    return (
        <span style={style} {...other}>
            <IconButton className="power-icon">
                <OfflineBolt />
            </IconButton>
        </span>
    );
};

export default AirbnbThumb;
