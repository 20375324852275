import {Action} from 'redux';
import {ICurrency} from '../types/currency';

export const SET_CURRENCY = 'SET_CURRENCY';
export type SET_CURRENCY = typeof SET_CURRENCY;

export interface ISetCurrencyAction extends Action<SET_CURRENCY> {
    payload: ICurrency[];
}

export const setCurrencyAction = (payload: ICurrency[]): ISetCurrencyAction => {
    return {
        type: SET_CURRENCY,
        payload,
    };
};
