import {LoginRequest, VerifyTwoFactorRequest} from '../types/auth';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {pushTo} from '../helpers/history';
import {PATH} from '../constants/path';
import {
    fireErrorNotification,
    fireSuccessNotification,
} from '../helpers/notification';
import {getErrorMessageFromAxios} from '../helpers/common';
import {getUserProfileMiddleware} from './user';

export const loginMiddleware = (request: LoginRequest) => {
    return new Promise((resolve, reject) => {
        Axios.post('/auth/login', request)
            .then(
                (response: AxiosResponse<{token: string} & {data: string}>) => {
                    const secretCode = response.data.data;
                    if (secretCode) {
                        resolve({
                            twoFactorActivated: true,
                            secretCode: secretCode,
                        });
                        return;
                    }
                    localStorage.setItem('token', response.data.token);
                    resolve({
                        twoFactorActivated: false,
                        secretCode: '',
                    });
                    getUserProfileMiddleware();
                },
            )
            .catch((error: AxiosError) => {
                fireErrorNotification('');
                reject(getErrorMessageFromAxios(error));
            });
    });
};
export const verifyTwoFactor = (request: VerifyTwoFactorRequest) => {
    return new Promise((resolve, reject) => {
        Axios.post('/Auth/Login2FA', request)
            .then(
                (
                    response: AxiosResponse<{
                        token: string;
                    }>,
                ) => {
                    localStorage.setItem('token', response.data.token);
                    resolve(true);
                    getUserProfileMiddleware();
                },
            )
            .catch((error: AxiosError) => {
                if (error.response?.status === 404) {
                    /// secretCode expired
                    fireErrorNotification(
                        'The Session has been expired, Please reload the page!',
                    );
                    return;
                }
                fireErrorNotification(error);
                reject(error);
            });
    });
};

export const signUpMiddleware = (
    request: LoginRequest & {subscribe?: boolean},
) => {
    return new Promise((resolve, reject) => {
        Axios.post('/auth/register', request)
            .then(() => {
                resolve(true);
            })
            .catch((error: AxiosError) => {
                fireErrorNotification('');
                reject(getErrorMessageFromAxios(error));
            });
    });
};

export const forgotPasswordMiddleware = (request: {
    email: string;
    googleReCaptchaResponse: string;
}) => {
    return new Promise((resolve, reject) => {
        Axios.post('/auth/forgotpassword', request)
            .then(() => {
                resolve(true);
            })
            .catch((error: AxiosError) => {
                fireErrorNotification('');
                reject(getErrorMessageFromAxios(error));
            });
    });
};

export const resetPasswordMiddleware = (request: {
    password: string;
    token: string;
    id: string;
}) => {
    return new Promise((resolve, reject) => {
        Axios.post(`/auth/resetPassword`, request)
            .then(() => {
                resolve(true);
            })
            .catch((error: AxiosError) => {
                fireErrorNotification('');
                reject(getErrorMessageFromAxios(error));
            });
    });
};

export const verifyEmailMiddleware = (request: {token: string; id: string}) => {
    Axios.post(`/auth/confirmEmail`, request)
        .then(() => {})
        .catch(() => {
            fireErrorNotification(
                'Verify email failed. Redirect to login page after 2s',
            );
            setTimeout(() => pushTo(PATH.login), 3000);
        });
};
export const resendVerificationEmail = (request: {email: string}) => {
    Axios.post(`/Auth/ResendEmail`, request)
        .then(() => {
            fireSuccessNotification('Success! Please check your email');
        })
        .catch(() => {
            fireErrorNotification('Resend Email Failed');
        });
};
