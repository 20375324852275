import React, {useEffect} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import {PATH} from './constants/path';
import {Login} from './pages/auth/Login';
import {includes} from 'lodash';
import {ForgotPassword} from './pages/auth/ForgotPassword';
import {Dashboard} from './pages/dashboard';
import {Referral} from './pages/referral';
import {Affiliate} from './pages/affiliate';
import {Order} from './pages/order';
import {Activity} from './pages/activity';
import {ResetPassword} from './pages/auth/ResetPassword';
import {SignUp} from './pages/auth/SignUp';
import {VerifyEmail} from './pages/auth/VerifyEmail';
import {Settings} from './pages/settings';
import {Wallets} from './pages/my-wallet/wallets';
import {MyWallet} from './pages/my-wallet';
import {CustomerService} from './pages/customerservice';
import {
    getUserProfileMiddleware,
    getUserWalletsMiddleware,
    get2FactorAuthKey,
    getBalances,
} from './middlewares/user';
import {getPlan} from './middlewares/plan';
import {getCoin} from './middlewares/coin';
import {getCurrency} from './middlewares/currency';
import {Purchase} from './pages/purchase';
import {useSelector} from 'react-redux';
import {StoreState} from './types/store-state';

export const Routes = () => {
    const history = useHistory();
    const access = localStorage.getItem('token');
    const {userProfile} = useSelector((state: StoreState) => state);
    useEffect(() => {
        if (!access) {
            if (
                !includes(
                    [PATH.signUp, PATH.forgotPassword, PATH.verifyEmail],
                    history.location.pathname,
                ) &&
                !includes(
                    history.location.pathname,
                    PATH.resetPassword.replace('/:token', ''),
                )
            ) {
                return history.push(PATH.login);
            }
        } else {
            getUserProfileMiddleware();
            if (
                includes(
                    [PATH.login, PATH.home, PATH.forgotPassword],
                    history.location.pathname,
                )
            ) {
                return history.push(PATH.dashboard);
            }
        }
    }, [access, history]);

    useEffect(() => {
        if (userProfile && userProfile.twoFactorEnabled === false) {
            get2FactorAuthKey();
        }
        if (userProfile) {
            getUserWalletsMiddleware();
            getPlan();
            getCoin();
            getCurrency();
            getBalances();
        }
    }, [userProfile]);

    return (
        <Switch>
            <Route path={PATH.login} exact component={Login} />

            <Route
                path={PATH.forgotPassword}
                exact
                component={ForgotPassword}
            />
            <Route path={PATH.resetPassword} component={ResetPassword} />
            <Route path={PATH.signUp} exact component={SignUp} />
            <Route path={PATH.verifyEmail} exact component={VerifyEmail} />

            <Route path={PATH.settings} component={Settings} />
            <Route path={PATH.purchase} component={Purchase} />
            <Route path={PATH.referral} component={Referral} />

            <Route path={PATH.dashboard} exact component={Dashboard} />
            <Route path={PATH.affiliate} exact component={Affiliate} />
            <Route path={PATH.myOrder} exact component={Order} />
            <Route path={PATH.login} component={Login} />
            <Route path={PATH.activity} component={Activity} />
            <Route path={PATH.myWallet} component={MyWallet} />
            <Route path={PATH.wallets} component={Wallets} />
            <Route path={PATH.customerservice} component={CustomerService} />
        </Switch>
    );
};
