import React from 'react';
import {
    ClickAwayListener,
    Tooltip,
    Button,
    ButtonProps,
} from '@material-ui/core';
import {PalateColors} from '../theme';

const CopyButton = (props: ButtonProps) => {
    const {disabled, onClick, ...other} = props;
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = (e: any) => {
        setOpen(true);
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied"
            >
                <Button
                    variant="outlined"
                    style={{
                        fontFamily: 'Poppins',
                        fontSize: 16,
                        fontWeight: 600,
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 1.88,
                        letterSpacing: 0.4,
                        textTransform: 'none',
                        color: PalateColors.neutral.white,
                        opacity: disabled ? 0.5 : 1,
                    }}
                    disabled={disabled}
                    onClick={handleTooltipOpen}
                    {...other}
                />
            </Tooltip>
        </ClickAwayListener>
    );
};

export default CopyButton;
