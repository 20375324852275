import {Action} from 'redux';
import {IOrder, IUserOrderData, IUserOrderPagination} from '../types/order';

export const SET_ORDER = 'SET_ORDER';
export type SET_ORDER = typeof SET_ORDER;
////
export const SET_PENDING_ORDERS = 'SET_PENDING_ORDERS';
export type SET_PENDING_ORDERS = typeof SET_PENDING_ORDERS;
////
export const SET_ACTIVE_ORDERS = 'SET_ACTIVE_ORDERS';
export type SET_ACTIVE_ORDERS = typeof SET_ACTIVE_ORDERS;
////
export const SET_ENDED_ORDERS = 'SET_ENDED_ORDERS';
export type SET_ENDED_ORDERS = typeof SET_ENDED_ORDERS;

export interface ISetOrderAction extends Action<SET_ORDER> {
    payload: IOrder;
}

export interface ISetPendingOrdersAction extends Action<SET_PENDING_ORDERS> {
    payload: IUserOrderData[];
}
export interface ISetActiveOrdersAction extends Action<SET_ACTIVE_ORDERS> {
    payload: IUserOrderPagination;
}
export interface ISetEndedOrdersAction extends Action<SET_ENDED_ORDERS> {
    payload: IUserOrderPagination;
}

export const setOrderAction = (payload: IOrder): ISetOrderAction => {
    return {
        type: SET_ORDER,
        payload,
    };
};

///
export const setPendingOrdersAction = (
    payload: IUserOrderData[],
): ISetPendingOrdersAction => {
    return {
        type: SET_PENDING_ORDERS,
        payload,
    };
};
export const SET_PENDING_ORDERS_LOADING = 'SET_PENDING_ORDERS_LOADING';
export type SET_PENDING_ORDERS_LOADING = typeof SET_PENDING_ORDERS_LOADING;

export interface ISetPendingOrdersLoadingAction
    extends Action<SET_PENDING_ORDERS_LOADING> {}

export const setPendingOrdersLoadingAction = (): ISetPendingOrdersLoadingAction => {
    return {
        type: SET_PENDING_ORDERS_LOADING,
    };
};

///
export const setActiveOrdersAction = (
    payload: IUserOrderPagination,
): ISetActiveOrdersAction => {
    return {
        type: SET_ACTIVE_ORDERS,
        payload,
    };
};
export const SET_ACTIVE_ORDERS_LOADING = 'SET_ACTIVE_ORDERS_LOADING';
export type SET_ACTIVE_ORDERS_LOADING = typeof SET_ACTIVE_ORDERS_LOADING;

export interface ISetActiveOrdersLoadingAction
    extends Action<SET_ACTIVE_ORDERS_LOADING> {}

export const setActiveOrdersLoadingAction = (): ISetActiveOrdersLoadingAction => {
    return {
        type: SET_ACTIVE_ORDERS_LOADING,
    };
};
///
export const setEndedOrdersAction = (
    payload: IUserOrderPagination,
): ISetEndedOrdersAction => {
    return {
        type: SET_ENDED_ORDERS,
        payload,
    };
};

export const SET_ENDED_ORDERS_LOADING = 'SET_ENDED_ORDERS_LOADING';
export type SET_ENDED_ORDERS_LOADING = typeof SET_ENDED_ORDERS_LOADING;

export interface ISetEndedOrdersLoadingAction
    extends Action<SET_ENDED_ORDERS_LOADING> {}

export const setEndedOrdersLoadingAction = (): ISetEndedOrdersLoadingAction => {
    return {
        type: SET_ENDED_ORDERS_LOADING,
    };
};
