import React, {useEffect, useState} from 'react';
import {PageLayout} from '../../components/PageLayout';
import CopyButton from '../../components/CopyButton';
import {Grid, makeStyles, Box, Typography} from '@material-ui/core';
import {
    WhatIsAffiliateBonus,
    HowToUseAffiliateCode,
    HowDoesAffiliateCodeWork,
    HowBigRewards,
    RankingSystemMeaning,
    HowToRankup,
    WhatAreAffiliateID,
    WhyDoSomePendingSale,
    IHaveSuggestion,
} from './FrequentlyQuestion';
import {Ranking} from './Ranking';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {getRanks} from '../../middlewares/rank';
import {IRank} from '../../types/rank';
import AffiliateProgramIcon from '../../assets/images/illus.png';
import {MOBILE_WIDTH} from '../../constants/common';

const useStyles = makeStyles((theme) => ({
    affiliateContainer: {
        '& .page-title': {
            textAlign: 'center',
            fontSize: 32,
            fontWeight: 'bold',
            letterSpacing: 0.8,
            marginTop: 60,
            color: theme.palette.neutral.white,
            '&.mobile': {
                fontSize: 24,
                marginTop: 40,
            },
        },
        '& .title': {
            fontSize: 24,
            fontWeight: 'bold',
            lineHeight: 1.5,
            letterSpacing: 0.4,
            marginBottom: 20,
            color: theme.palette.neutral.white,
            marginTop: 40,
            '&.mobile': {
                fontSize: 20,
                marginTop: 10,
            },
        },
        '& .affiliate-type': {
            marginBottom: 20,
        },
        '& .main': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 4,
            padding: 32,
            marginBottom: 16,
            '&.mobile': {
                padding: 15,
            },
            backgroundColor: theme.palette.neutral.hashrate,
            '& .div-left': {
                width: '60%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&.mobile': {
                    display: 'none',
                },
            },
            '& .div-right': {
                width: '45%',
                borderRadius: 8,
                border: `2px solid ${theme.palette.neutral.purple1}`,
                padding: 32,
                '&.mobile': {
                    width: '100%',
                    padding: 15,
                    '& .flexible': {
                        flexDirection: 'column',
                        '& .your-code': {
                            marginRight: 0,
                            marginBottom: 8,
                            width: 'calc(100% - 32px)',
                        },
                        '& .copy-btn': {
                            width: '100%',
                        },
                    },
                },
                '& .title': {
                    fontWeight: 500,
                    lineHeight: 1.75,
                    letterSpacing: 0.3,
                    color: theme.palette.neutral.white,
                    fontSize: 16,
                    '& .highlight-text': {
                        color: theme.palette.primary.main,
                        cursor: 'pointer',
                    },
                },
                '& .code': {
                    marginTop: 24,
                    '& label': {
                        fontWeight: 600,
                        lineHeight: 1.88,
                        letterSpacing: 0.4,
                        color: theme.palette.neutral.white,
                        fontSize: 16,
                        marginBottom: 8,
                        display: 'block',
                    },
                    '& .flexible': {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '& .your-code': {
                            fontWeight: 500,
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 1.67,
                            letterSpacing: 0.38,
                            color: theme.palette.neutral.lighter,
                            backgroundColor: theme.palette.neutral.dark,
                            fontSize: 14,
                            padding: '0 16px',
                            marginRight: 24,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            height: 44,
                        },
                        '& .copy-btn': {
                            backgroundColor: theme.palette.primary.dark,
                            color: theme.palette.neutral.white,
                        },
                    },
                },
                '& .note': {
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: 1.67,
                    letterSpacing: 0.38,
                    color: theme.palette.neutral.gray,
                    marginTop: 24,
                },
            },
        },
    },
}));

export const Affiliate = () => {
    const classes = useStyles();
    const {userProfile, ranks} = useSelector((state: StoreState) => state);
    const [userRank, setUserRank] = useState<IRank | null>(null);

    useEffect(() => {
        //// call API to get rank
        getRanks();
    }, []);

    const copyReferalCode = () => {
        if (!userProfile) {
            return; /// do nothing
        }
        navigator.clipboard.writeText(userProfile.referralCode);
    };

    useEffect(() => {
        if (ranks && userProfile) {
            const selectedRank = ranks.find(
                (rank) => rank.id === userProfile.rankId,
            );
            if (selectedRank) {
                setUserRank(selectedRank);
            }
        }
    }, [ranks, userProfile]);

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <PageLayout>
            <Grid
                container
                spacing={3}
                className={classes.affiliateContainer}
                justify="center"
            >
                <Grid item md={10}>
                    <Typography
                        className={`page-title ${isMobile ? 'mobile' : ''}`}
                        variant="h1"
                    >
                        Affiliate
                    </Typography>
                </Grid>
                <Grid item md={10}>
                    <Box>
                        <Box>
                            <WhatIsAffiliateBonus isMobile={isMobile} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={10}>
                    <Box>
                        <Typography
                            className={`title ${isMobile ? 'mobile' : ''}`}
                            variant="h2"
                        >
                            Affiliate Program
                        </Typography>

                        <Box className={`main ${isMobile ? 'mobile' : ''}`}>
                            <Box
                                className={`div-left ${
                                    isMobile ? 'mobile' : ''
                                }`}
                            >
                                <img
                                    src={AffiliateProgramIcon}
                                    alt="affiliate program"
                                />
                            </Box>
                            <Box
                                className={`div-right ${
                                    isMobile ? 'mobile' : ''
                                }`}
                            >
                                <Typography className="title">
                                    Welcome to Expert Mining's Affiliate
                                    Program! To read the terms and conditions of
                                    our affiliate agreement,{' '}
                                    <span className="highlight-text">
                                        Click here!
                                    </span>
                                </Typography>
                                <Box className="code">
                                    <label>Your affiliate code</label>
                                    <Box className="flexible">
                                        <Typography className="your-code">
                                            {userProfile?.referralCode ?? 'N/A'}
                                        </Typography>
                                        <CopyButton
                                            variant="contained"
                                            disableElevation
                                            color="primary"
                                            onClick={copyReferalCode}
                                            className="copy-btn"
                                        >
                                            Copy
                                        </CopyButton>
                                    </Box>
                                </Box>
                                <Typography className="note">
                                    You're welcome to share your affiliate code
                                    with anyone you like – friends, family, or
                                    followers. They will get a 3% discount on
                                    their purchase when they use your code, and
                                    in turn, you'll get a hash power upgrade
                                    corresponding to your tier. Referrals may
                                    use promotional codes to purchase all Expert
                                    Mining plans, except the 6-month mining
                                    plan. Whether you're a newbie blogger or
                                    someone who spends a lot of time on social
                                    networks, you can reap immense benefits from
                                    our affiliate program.
                                </Typography>
                            </Box>
                        </Box>

                        <Box>
                            <HowToUseAffiliateCode isMobile={isMobile} />
                            <HowDoesAffiliateCodeWork isMobile={isMobile} />
                            <HowBigRewards isMobile={isMobile} />
                            <RankingSystemMeaning isMobile={isMobile} />
                            <HowToRankup isMobile={isMobile} />
                            <WhatAreAffiliateID isMobile={isMobile} />
                            <WhyDoSomePendingSale isMobile={isMobile} />
                            <IHaveSuggestion isMobile={isMobile} />
                        </Box>
                    </Box>
                </Grid>

                <Grid item md={10}>
                    <Typography className="title" variant="h2">
                        Your affiliate rank
                    </Typography>
                    <Box className="ranking-container">
                        <Ranking ranks={ranks} userRank={userRank} />
                    </Box>
                </Grid>
            </Grid>
        </PageLayout>
    );
};
