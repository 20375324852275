import {Action} from 'redux';
import {IWithdrawHistory} from '../types/withdraw';

export const SET_WITHDRAW_HISTORY = 'SET_WITHDRAW_HISTORY';
export type SET_WITHDRAW_HISTORY = typeof SET_WITHDRAW_HISTORY;

export interface ISetWithdrawHistoryAction
    extends Action<SET_WITHDRAW_HISTORY> {
    payload: IWithdrawHistory;
}

export const setWithdrawHistories = (
    payload: IWithdrawHistory,
): ISetWithdrawHistoryAction => {
    return {
        type: SET_WITHDRAW_HISTORY,
        payload,
    };
};

export const SET_WITHDRAW_LOADING = 'SET_WITHDRAW_LOADING';
export type SET_WITHDRAW_LOADING = typeof SET_WITHDRAW_LOADING;

export interface ISetWithdrawLoadingAction
    extends Action<SET_WITHDRAW_LOADING> {}

export const setWithdrawLoadingAction = (): ISetWithdrawLoadingAction => {
    return {
        type: SET_WITHDRAW_LOADING,
    };
};
