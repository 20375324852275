import React, {useState, useEffect} from 'react';
import {PageLayout} from '../../components/PageLayout';
import {
    Grid,
    makeStyles,
    Box,
    Typography,
    CircularProgress,
    Tabs,
    Tab,
    Link,
    LinearProgress,
    MenuItem,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '../../components/Button';
import Table from '../../components/Table';
import CopyButton from '../../components/CopyButton';
import {
    getMiningData,
    getMiningReward,
    getBalances,
    setLoaddedMiningData,
} from '../../middlewares/user';
import {getActiveOrders} from '../../middlewares/order';
import {getTransactions} from '../../middlewares/transaction';
import {useSelector} from 'react-redux';
import {ICurrency} from '../../types/currency';
import {StoreState} from '../../types/store-state';
import {IUserOrderPaginationData} from '../../types/order';
import {IChartMiningReward} from '../../types/user';
import {MiningLineChart} from './MiningLineChart';
import moment from 'moment';
import {isEmpty, sumBy} from 'lodash';
/////
import BitCoinImg from '../../assets/images/bitcoin.svg';
import RankImg from '../../assets/images/rank.png';
import WalletImg from '../../assets/images/icons/wallet.png';
import ArtworkImg from '../../assets/images/artwork.png';
import EmptyDataImg from '../../assets/images/empty-table-data.png';
import TabPanel, {getTabProps} from '../../components/TabPanel';
import SelectBox from '../../components/SelectBox';
import {pushTo} from '../../helpers/history';
import {PATH} from '../../constants/path';
import {MOBILE_WIDTH} from '../../constants/common';
import {DEFAULT_PAGE_SIZE} from '../../constants/pagination';
import {
    DEFAULT_DATE_RANGE,
    OUTPUT_CHART_DATE_RANGES,
} from '../../constants/date';

const useStyles = makeStyles((theme) => ({
    outputChartContainer: {
        marginTop: 60,
        '& h2': {
            fontSize: 32,
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 0.6,
            '&.title-mobile': {
                fontSize: 24,
            },
        },
        '& .row': {
            padding: '0 15px',
        },
        '& .tab-container': {
            backgroundColor: theme.palette.neutral.hashrate,
            borderRadius: 4,
            '& .MuiTabs-flexContainer': {
                height: 82,
                '& button': {
                    fontFamily: 'Poppins',
                    fontSize: 16,
                    fontWeight: 600,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.88,
                    letterSpacing: 0.4,
                    textTransform: 'none',
                },
            },
        },
        '& .tab-top': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '15px 18px',
            backgroundColor: theme.palette.primary.dark,
            '& .filter-container': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .filter-option': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 29,
                    '& .label': {
                        marginRight: 12,
                        whiteSpace: 'nowrap',
                    },
                    '& fieldset': {
                        border: 'none !important',
                    },
                    '& input': {
                        color: theme.palette.neutral.white,
                    },
                },
            },
            '& .output-in': {
                '& .label': {
                    fontSize: 16,
                },
            },
        },
        '& .empty-hashrate': {
            margin: '50px 0',
            textAlign: 'center',
            '& h4': {
                fontSize: 24,
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.5,
                letterSpacing: 0.4,
                color: theme.palette.neutral.white,
                marginBottom: 12,
            },
            '& h5': {
                fontSize: 16,
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.5,
                letterSpacing: 0.3,
                color: theme.palette.neutral.gray,
                marginBottom: 16,
            },
        },
        '& .line-chart-container': {
            position: 'relative',
            marginTop: 32,
        },
        '& .loading-chart-container': {
            '& .title': {
                backgroundColor: theme.palette.neutral.hashrate,
            },
            '& .filter': {
                backgroundColor: theme.palette.primary.dark,
            },
            '& .content': {
                backgroundColor: theme.palette.neutral.darker,
            },
        },
    },
    chartLoading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 10,
    },
    createWalletContainer: {
        marginTop: 60,
        '& h2': {
            fontSize: 32,
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 0.6,
            '&.title-mobile': {
                fontSize: 24,
            },
        },
        '& .row': {
            padding: '0 15px',
        },
        '& .container-mobile': {
            flexDirection: 'column',
            '& .btn-createWallet': {
                marginTop: 16,
            },
            '& .flexible': {
                '& .title': {
                    fontSize: 20,
                },
            },
        },
        '& .container': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.dark,
            padding: '15px 30px',
            borderRadius: 4,
            '& .flexible': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& img': {
                    width: 88,
                    marginRight: 32,
                },
                '& .title': {
                    fontSize: 24,
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.5,
                    letterSpacing: 0.4,
                    color: theme.palette.neutral.white,
                },
                '& .sub-title': {
                    fontSize: 16,
                    fontWeight: 500,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.75,
                    letterSpacing: 0.3,
                    color: theme.palette.neutral.white,
                },
            },
            '& .btn-createWallet': {
                color: theme.palette.neutral.white,
                boxShadow: '0 4px 8px 0 rgba(19, 14, 70, 0.2)',
                width: 200,
                height: 44,
                fontWeight: 600,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.88,
                letterSpacing: 0.4,
                fontSize: 16,
                textTransform: 'capitalize',
            },
        },
    },
    recentAffiliateContainer: {
        marginTop: 60,
        '& .MuiGrid-grid-md-6': {
            width: '100%',
        },
        '& h2': {
            fontSize: 32,
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 0.6,
            '&.title-mobile': {
                fontSize: 24,
            },
        },
        '& .flex-between': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& .link': {
            fontSize: 16,
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.88,
            letterSpacing: 0.4,
            color: theme.palette.neutral.orange,
            cursor: 'pointer',
        },
        '& .row': {
            padding: '0 15px',
        },
        '& .recent-container': {
            marginTop: 15,
            backgroundColor: theme.palette.neutral.hashrate,
            borderRadius: 4,
            padding: '8px 24px',
            minHeight: 565,
            '& .recent-item-mobile': {
                flexDirection: 'column',
                alignItem: 'flex-start',
                '& img': {
                    display: 'none',
                },
                '& .flex-center': {
                    width: '100%',
                },
                '& a': {
                    width: '100%',
                    marginTop: 6,
                },
            },
            '& .recent-item': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px 0',
                borderBottom: `1px solid ${theme.palette.neutral.timber}`,
                '&:last-child': {
                    borderBottom: 'none',
                },
                '& .flex-center': {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '72%',
                    overflow: 'hidden',
                    '& img': {
                        width: 56,
                        marginRight: 16,
                    },
                    '& .address': {
                        fontSize: 13,
                        fontWeight: 500,
                        lineHeight: 1.75,
                        letterSpacing: 0.3,
                        color: theme.palette.neutral.white,
                        marginBottom: 10,
                    },
                    '& .badge': {
                        borderRadius: 16,
                        backgroundColor: 'rgba(255,255,255, 0.36)',
                        display: 'inline-block',
                        marginRight: 12,
                        padding: '4px 11px',
                        fontSize: 16,
                        lineHeight: 1.5,
                        letterSpacing: 0.5,
                        color: theme.palette.neutral.white,
                        fontWeight: 500,
                    },
                    '& .createat': {
                        color: theme.palette.neutral.gray,
                        lineHeight: 1.57,
                        letterSpacing: 0.24,
                        fontWeight: 500,
                        fontSize: 14,
                    },
                },
                '& a': {
                    width: '23%',
                },
            },
            '& .no-activity-wrapper': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: 475,
                flexDirection: 'column',
                '& .no-activity-img': {
                    width: '100%',
                    maxWidth: 230,
                    height: 'auto',
                },
                '& .no-activity-text': {
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: 1.5,
                    letterSpacing: 0.3,
                    textAlign: 'center',
                    color: theme.palette.neutral.gray,
                    marginTop: 46,
                },
            },
        },
        '& .affiliate': {
            marginTop: 15,
            backgroundColor: theme.palette.neutral.hashrate,
            borderRadius: 4,
            minHeight: 523,
            padding: '23px 35px 35px',
            '& .subtitle': {
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.5,
                letterSpacing: 0.3,
                color: theme.palette.neutral.gray,
                fontSize: 16,
                margin: '12px 0 24px',
            },
            '& .progressbar-container': {
                backgroundColor: theme.palette.neutral.timber,
                borderRadius: 4,
                padding: '38px 24px',
                textAlign: 'center',
                '& .rank-img': {
                    width: 130,
                    marginBottom: 15,
                },
                '& .progressbar': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& .MuiLinearProgress-root': {
                        height: 8,
                        borderRadius: 7,
                        backgroundColor: 'rgba(255, 255, 255, 0.36)',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: theme.palette.neutral.white,
                        },
                        width: '100%',
                    },
                    '& .rank-icon': {
                        width: 59,
                        marginLeft: 8,
                    },
                },
                '& .progress-info': {
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 0.5,
                    color: theme.palette.neutral.white,
                    fontSize: 16,
                    marginTop: -10,
                    textAlign: 'left',
                },
            },
            '& .code': {
                marginTop: 24,
                '& label': {
                    fontWeight: 500,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.67,
                    letterSpacing: 0.38,
                    color: theme.palette.neutral.gray,
                    fontSize: 12,
                    marginBottom: 8,
                    display: 'inline-block',
                },
                '& .flexible': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& .your-code': {
                        fontWeight: 500,
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 1.67,
                        letterSpacing: 0.38,
                        color: theme.palette.neutral.lighter,
                        backgroundColor: theme.palette.neutral.dark,
                        fontSize: 14,
                        padding: '0 16px',
                        marginRight: 24,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        height: 44,
                    },
                    '& .copy-btn': {
                        minWidth: 'auto',
                        width: 145,
                        height: 48,
                    },
                    '& .copy-btn-mobile': {
                        minWidth: 'auto',
                    },
                },
            },
        },
    },
    orderContainer: {
        marginTop: 60,
        '& h2': {
            fontSize: 32,
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 0.6,
            '&.title-mobile': {
                fontSize: 24,
            },
        },
        '& .flex-between': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& .link': {
            fontSize: 16,
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.88,
            letterSpacing: 0.4,
            color: theme.palette.neutral.orange,
            cursor: 'pointer',
        },
        '& .row': {
            padding: '0 15px',
        },
    },
}));

export const Dashboard = () => {
    const classes = useStyles();
    const [miningRewards, setMiningRewards] = useState<IChartMiningReward>({
        isLoading: true,
        data: [],
    });
    const [outputChartIndex, setOutputChartIndex] = useState(0);
    const [currency, setCurrency] = useState<ICurrency>();
    const [activeColumns] = useState([
        {
            name: 'createAt',
            title: 'Order Create',
            renderer: (_record: IUserOrderPaginationData, value: string) =>
                moment(value).format('DD-MM-YYYY'),
        },
        {
            name: 'createAt',
            title: 'Start Date',
            renderer: (_record: IUserOrderPaginationData, value: string) =>
                moment(value).format('DD-MM-YYYY'),
        },
        {
            name: 'endDate',
            title: 'End date',
            renderer: (_record: IUserOrderPaginationData, value: string) =>
                moment(value).format('DD-MM-YYYY'),
        },
        {
            name: 'power',
            title: 'Hashpower',
            renderer: (record: IUserOrderPaginationData, value: number) =>
                `${value} ${record.plan.coin.powerUnit}`,
        },
        {
            name: 'period',
            title: 'Maintenance paid until',
            renderer: (record: IUserOrderPaginationData, _value: number) =>
                moment(record.endDate).format('DD-MM-YYYY'),
        },
        {
            name: 'type',
            title: 'Type',
            renderer: (record: IUserOrderPaginationData, _value: string) => {
                if (!record.plan.coin.image) {
                    return '';
                }
                return (
                    <img
                        src={record.plan.coin.image}
                        alt={record.plan.coin.name}
                        width="32"
                    />
                );
            },
        },
        {
            name: 'price',
            title: 'Price',
            renderer: (record: IUserOrderPaginationData, value: number) =>
                `${value} ${record.order.coin.code}`,
        },
        {
            name: 'price',
            title: 'Order Status',
            renderer: (_record: IUserOrderPaginationData, _value: number) =>
                `Active`,
        },
    ]);

    const {
        userMining,
        userMiningRewards,
        currencies,
        userProfile,
        transaction,
        activeOrders,
        userBalance,
    } = useSelector((state: StoreState) => state);

    useEffect(() => {
        getMiningData();
        getActiveOrders({
            PageSize: DEFAULT_PAGE_SIZE,
            PageNumber: 1,
        });
        getBalances();
        getTransactions({
            PageSize: 5,
            PageNumber: 1,
        });
    }, []);

    useEffect(() => {
        if (userMiningRewards) {
            setMiningRewards(userMiningRewards);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userMiningRewards, setMiningRewards]);

    useEffect(() => {
        if (!isEmpty(currencies) && userProfile && !isEmpty(userMining)) {
            const selectedCurrency = currencies.find(
                (currency) => currency.id === userProfile.currencyId,
            );
            if (selectedCurrency) {
                setCurrency(selectedCurrency);
            }
            const today = moment().format('Y-MM-DD');
            const fiffteenDayAgo = moment()
                .subtract(DEFAULT_DATE_RANGE, 'days')
                .format('Y-MM-DD');
            const availableCodes = userMining.reduce(
                (codes: string[], miningCoin) => {
                    if (miningCoin.power > 0) {
                        if (miningCoin.coin.code in codes) {
                            return codes;
                        }
                        codes.push(miningCoin.coin.code);
                    }
                    return codes;
                },
                [],
            );

            if (!isEmpty(availableCodes)) {
                availableCodes.forEach((coinCode) => {
                    getMiningReward({
                        coinCode: coinCode,
                        startDate: fiffteenDayAgo,
                        endDate: today,
                        range: DEFAULT_DATE_RANGE,
                        currency: selectedCurrency,
                    });
                });
            } else {
                setLoaddedMiningData();
            }
        }
    }, [currencies, userProfile, userMining]);

    const handleChangeDateRange = (
        event: React.ChangeEvent<any>,
        index: number,
    ) => {
        const newMiningRewards = [...miningRewards.data];
        const today = moment().format('Y-MM-DD');
        const selectedDateRange: number = event.target.value;
        if (selectedDateRange <= 0) {
            return; // do nothing
        }
        /// get startDate
        const viewFromDate = moment()
            .subtract(selectedDateRange, 'days')
            .format('Y-MM-DD');

        //// parse to mining data
        newMiningRewards[index].startDate = viewFromDate;
        newMiningRewards[index].endDate = today;
        newMiningRewards[index].isLoading = true;
        newMiningRewards[index].range = selectedDateRange;
        setMiningRewards({
            isLoading: false,
            data: newMiningRewards,
        });
        //// refresh api
        getMiningReward({
            coinCode: newMiningRewards[index].coinCode,
            startDate: viewFromDate,
            endDate: today,
            range: selectedDateRange,
            currency: currency,
        });
    };

    const gotoPage = (path: string) => {
        pushTo(path);
    };

    const copyReferalCode = () => {
        if (!userProfile) {
            return; /// do nothing
        }
        navigator.clipboard.writeText(userProfile.referralCode);
    };

    const availableBalance = sumBy(userBalance, (balance) => {
        return Math.floor(balance.amount * balance.coin.priceInUsd);
    });

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <PageLayout>
            <Grid
                container
                className={classes.outputChartContainer}
                spacing={3}
            >
                <Grid item xs={12} className="row">
                    <Typography
                        variant="h2"
                        className={`title ${isMobile ? 'title-mobile' : ''}`}
                    >
                        Output Chart
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {miningRewards.isLoading ? (
                        <LoadingChartComponent />
                    ) : isEmpty(miningRewards.data) ? (
                        <EmptyOutputChart />
                    ) : (
                        <OutputChartView
                            miningRewards={miningRewards}
                            handleChangeDateRange={handleChangeDateRange}
                            outputChartIndex={outputChartIndex}
                            setOutputChartIndex={setOutputChartIndex}
                            isMobile={isMobile}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                className={classes.createWalletContainer}
                spacing={3}
            >
                <Grid item xs={12} className="row">
                    <Typography
                        variant="h2"
                        className={`${isMobile ? 'title-mobile' : ''}`}
                    >
                        Wallet Address
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        className={`container ${
                            isMobile ? 'container-mobile' : ''
                        }`}
                    >
                        <Box className="flexible">
                            {!isMobile ? (
                                <img
                                    src={WalletImg}
                                    alt="wallet mining expert"
                                />
                            ) : null}

                            <Box>
                                <Typography className="title">
                                    {availableBalance > 0
                                        ? `$${availableBalance} available for withdrawal `
                                        : `You don't have any crypto wallet yet`}
                                </Typography>
                                <Typography className="sub-title">
                                    {availableBalance > 0
                                        ? `You can acess wallets to withdrawal `
                                        : `Create one now to execute your transaction`}
                                </Typography>
                            </Box>
                        </Box>
                        <Button
                            variant="contained"
                            disableElevation
                            color="primary"
                            className="btn-createWallet"
                            onClick={() => gotoPage(PATH.wallets)}
                        >
                            {availableBalance > 0
                                ? `View My Wallet`
                                : `Create Wallet`}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid
                container
                className={classes.recentAffiliateContainer}
                spacing={3}
            >
                <Grid item md={6}>
                    <Grid item xs={12}>
                        <Box className="flex-between">
                            <Typography
                                variant="h2"
                                className={`${isMobile ? 'title-mobile' : ''}`}
                            >
                                Recent Activities
                            </Typography>
                            <span
                                className="link"
                                onClick={() => gotoPage(PATH.activity)}
                            >
                                View All
                            </span>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="recent-container">
                            {!isEmpty(transaction.data) ? (
                                transaction.data.map((data, index) => (
                                    <Box
                                        className={`recent-item ${
                                            isMobile ? 'recent-item-mobile' : ''
                                        }`}
                                        key={index}
                                    >
                                        <Box className="flex-center">
                                            <img
                                                src={
                                                    data.withdraw?.coin.image ??
                                                    data.order?.coin.image ??
                                                    BitCoinImg
                                                }
                                                className="coin-icon"
                                                alt="coin-icon"
                                            />
                                            <Box>
                                                <Typography className="address">
                                                    {data.address}
                                                </Typography>
                                                <Typography>
                                                    <span className="badge">
                                                        {data.amount}{' '}
                                                        {data.withdraw?.coin
                                                            .code ??
                                                            data.order?.coin
                                                                .code}
                                                    </span>
                                                    <span className="createat">
                                                        {moment(
                                                            data.createAt,
                                                        ).format(
                                                            'DD MMM YYYY, LT',
                                                        )}
                                                    </span>
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Link href={data.url} target="_blank">
                                            Transaction Link
                                        </Link>
                                    </Box>
                                ))
                            ) : (
                                <Box className="no-activity-wrapper">
                                    <img
                                        src={EmptyDataImg}
                                        alt="no data title"
                                        className="no-activity-img"
                                    />
                                    <Typography className="no-activity-text">
                                        No activity
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Grid item xs={12}>
                        <Box className="flex-between">
                            <Typography
                                variant="h2"
                                className={`${isMobile ? 'title-mobile' : ''}`}
                            >
                                Your Affiliate Rank
                            </Typography>
                            <span
                                className="link"
                                onClick={() => gotoPage(PATH.affiliate)}
                            >
                                {isMobile ? 'View All' : 'Find out more'}
                            </span>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="affiliate">
                            <Typography variant="h2">
                                {userProfile?.rank.name ?? 'N/A'}
                            </Typography>
                            <Typography className="subtitle">
                                You will now gain{' '}
                                {userProfile
                                    ? parseFloat(
                                          (
                                              userProfile.rank.percent * 100
                                          ).toFixed(3),
                                      )
                                    : 0}
                                % bonus hashpower with every new sale.
                            </Typography>
                            <Box className="progressbar-container">
                                <img
                                    src={userProfile?.rank.image ?? RankImg}
                                    className="rank-img"
                                    alt="rank-img"
                                />
                                <Box className="progressbar">
                                    <LinearProgress
                                        variant="determinate"
                                        value={
                                            userProfile?.affiliateBalance ?? 0
                                        }
                                    />
                                    <img
                                        src={
                                            userProfile?.nextRank.image ??
                                            RankImg
                                        }
                                        className="rank-icon"
                                        alt="rank-icon"
                                    />
                                </Box>
                                <Typography className="progress-info">
                                    Earn{' '}
                                    {userProfile?.rank.nextLevelRequired ??
                                        'N/A'}{' '}
                                    USD to catch next rank
                                </Typography>
                            </Box>
                            <Box className="code">
                                <label>Your affiliate code</label>
                                <Box className="flexible">
                                    <Typography className="your-code">
                                        {userProfile?.referralCode ?? 'N/A'}
                                    </Typography>
                                    <CopyButton
                                        variant="contained"
                                        disableElevation
                                        color="primary"
                                        className={
                                            isMobile
                                                ? 'copy-btn-mobile'
                                                : 'copy-btn'
                                        }
                                        onClick={copyReferalCode}
                                    >
                                        Copy
                                    </CopyButton>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {!isEmpty(activeOrders.data) ? (
                <Grid container className={classes.orderContainer} spacing={3}>
                    <Grid item xs={12} className="row">
                        <Box className="flex-between">
                            <Typography
                                variant="h2"
                                className={`${isMobile ? 'title-mobile' : ''}`}
                            >
                                My Active Orders
                            </Typography>
                            <span
                                className="link"
                                onClick={() => gotoPage(PATH.myOrder)}
                            >
                                View All
                            </span>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Table
                            columns={activeColumns}
                            data={activeOrders.data}
                            isLoading={activeOrders.isLoading}
                            dynamicHeight
                        />
                    </Grid>
                </Grid>
            ) : null}
        </PageLayout>
    );
};

const EmptyOutputChart = () => {
    return (
        <Box className="empty-hashrate">
            <img src={ArtworkImg} alt="no data display output chart" />
            <Typography variant="h4"> No data to display </Typography>
            <Typography variant="h5">
                {' '}
                Expecting to see something? Try adjusting the data.{' '}
            </Typography>
        </Box>
    );
};

const OutputChartView = ({
    miningRewards,
    setOutputChartIndex,
    outputChartIndex,
    handleChangeDateRange,
    isMobile,
}: {
    miningRewards: IChartMiningReward;
    setOutputChartIndex: (index: number) => void;
    outputChartIndex: number;
    handleChangeDateRange: (
        event: React.ChangeEvent<any>,
        index: number,
    ) => void;
    isMobile?: boolean;
}) => {
    const classes = useStyles();
    return (
        <Box>
            <Tabs
                value={outputChartIndex}
                onChange={(_event, value) => setOutputChartIndex(value)}
                aria-label="tabs"
                className="tab-container"
            >
                {miningRewards.data.map((miningReward, index) => (
                    <Tab
                        label={miningReward.coinName}
                        {...getTabProps(index)}
                        key={index}
                    />
                ))}
            </Tabs>
            {miningRewards.data.map((miningReward, index) => (
                <TabPanel value={outputChartIndex} key={index} index={index}>
                    <Box className="tab-top">
                        <Box className="output-in">
                            <Typography className="label">
                                {' '}
                                Output in:{' '}
                                <strong>{miningReward.priceUnit}</strong>
                            </Typography>
                        </Box>
                        <Box className="filter-container">
                            <Box className="filter-option">
                                {!isMobile ? (
                                    <Typography className="label">
                                        Date range
                                    </Typography>
                                ) : null}
                                <SelectBox
                                    value={miningReward.range}
                                    fullWidth
                                    handleChange={(e) =>
                                        handleChangeDateRange(e, index)
                                    }
                                >
                                    {OUTPUT_CHART_DATE_RANGES.map(
                                        (range, rangeIndex) => (
                                            <MenuItem
                                                value={range.value}
                                                key={rangeIndex}
                                            >
                                                {range.label}
                                            </MenuItem>
                                        ),
                                    )}
                                </SelectBox>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="line-chart-container">
                        {miningReward.isLoading ? (
                            <Box className={classes.chartLoading}>
                                <CircularProgress size={80} />
                            </Box>
                        ) : null}
                        <MiningLineChart
                            data={miningReward.data}
                            coinName={miningReward.coinName}
                            priceUnit={miningReward.priceUnit}
                        />
                    </Box>
                </TabPanel>
            ))}
        </Box>
    );
};

const LoadingChartComponent = () => {
    return (
        <Box className="loading-chart-container">
            <Skeleton variant="rect" height={82} className="title" />
            <Skeleton variant="rect" height={81} className="filter" />
            <Skeleton variant="rect" height={650} className="content" />
        </Box>
    );
};
