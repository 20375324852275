import React from 'react';
import {PageLayout} from '../../components/PageLayout';
import {Switch, Route} from 'react-router-dom';
import {PATH} from '../../constants/path';
import {SelectPlans} from './SelectPlans';
import {ReviewPurchase} from './ReviewPurchase';
import {PaymentPurchase} from './PaymentPurchase';
import {ConfirmPurchase} from './ConfirmPurchase';

export const Purchase = () => {
    return (
        <PageLayout>
            <Switch>
                <Route
                    path={PATH.selectPlansPurchase}
                    component={SelectPlans}
                />
                <Route path={PATH.reviewPurchase} component={ReviewPurchase} />
                <Route
                    path={PATH.paymentPurchase}
                    component={PaymentPurchase}
                />
                <Route
                    path={PATH.confirmPurchase}
                    component={ConfirmPurchase}
                />
            </Switch>
        </PageLayout>
    );
};
