import {Action} from 'redux';
import {ITransaction} from '../types/transaction';

export const SET_TRANSACTION = 'SET_TRANSACTION';
export type SET_TRANSACTION = typeof SET_TRANSACTION;

export interface ISetTransactionAction extends Action<SET_TRANSACTION> {
    payload: ITransaction;
}

export const setTransactionAction = (
    payload: ITransaction,
): ISetTransactionAction => {
    return {
        type: SET_TRANSACTION,
        payload,
    };
};

export const SET_TRANSACTION_LOADING = 'SET_TRANSACTION_LOADING';
export type SET_TRANSACTION_LOADING = typeof SET_TRANSACTION_LOADING;

export interface ISetTransactionLoadingAction
    extends Action<SET_TRANSACTION_LOADING> {}

export const setTransactionLoadingAction = (): ISetTransactionLoadingAction => {
    return {
        type: SET_TRANSACTION_LOADING,
    };
};
