import {store} from '..';
import {updateNotificationAction} from '../actions/notification';
import {AxiosError} from 'axios';
import {getErrorMessageFromAxios} from './common';

export const fireErrorNotification = (error: AxiosError | string) => {
    const message = getErrorMessageFromAxios(error);

    store.dispatch(
        updateNotificationAction({
            isShow: true,
            type: 'error',
            message,
        }),
    );
};

export const fireSuccessNotification = (message?: string) => {
    if (message) {
        store.dispatch(
            updateNotificationAction({
                isShow: true,
                type: 'success',
                message,
            }),
        );
    }
};
