import React, {useState, useEffect} from 'react';
import {Grid, Box, Typography, makeStyles} from '@material-ui/core';
import {HeadingTab} from './HeadingTab';
import {ITempOrder, IOrder} from '../../types/order';
import {ICoin} from '../../types/coin';
import {isJsonString} from '../../helpers/validate';
import {pushTo} from '../../helpers/history';
import {convertPrice} from '../../helpers/common';
import {PATH} from '../../constants/path';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {createOrder, checkPromo} from '../../middlewares/order';
import Table from '../../components/Table';
import Button from '../../components/Button';
import {ComboBox} from '../../components/Autocomplete';
import {Checkbox} from '../../components/Checkbox';
import {MOBILE_WIDTH} from '../../constants/common';
import AngleLeftImg from '../../assets/images/icons/angle-left.png';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    reviewPurchaseContainer: {
        '& .section': {
            '& .title': {
                fontSize: 32,
                fontWeight: 'bold',
                letterSpacing: 0.6,
                color: theme.palette.neutral.white,
                marginBottom: 24,
                '&.mobile': {
                    fontSize: 24,
                },
            },
            '& .total-sumary': {
                backgroundColor: theme.palette.neutral.hashrate,
                marginTop: 16,
                padding: '17px 19px 12px 24px',
                '&.mobile': {
                    '& .item': {
                        marginBottom: 16,
                        '& .price': {
                            fontSize: 18,
                        },
                    },
                },
                '& .item': {
                    borderRadius: 4,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 23,
                    '& .text': {
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: 1.5,
                        letterSpacing: 0.3,
                        color: theme.palette.neutral.gray,
                    },
                    '& .price': {
                        fontSize: 20,
                        fontWeight: 'bold',
                        lineHeight: 1.5,
                        letterSpacing: 0.5,
                        color: theme.palette.neutral.white,
                        '&.primary': {
                            color: theme.palette.primary.main,
                        },
                    },
                },
            },
            '& .promo-container': {
                backgroundColor: theme.palette.neutral.hashrate,
                marginTop: 16,
                padding: '16px 24px',
                '&.mobile': {
                    '& .flexible': {
                        flexDirection: 'column',
                        '& .input-wrapper': {
                            marginRight: 0,
                            marginBottom: 12,
                        },
                        '& .promo-action': {
                            width: '100%',
                            '& .promo-btn': {
                                width: '100%',
                            },
                        },
                    },
                },
                '& .flexible': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                '& label': {
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.67,
                    letterSpacing: 0.38,
                    color: theme.palette.neutral.gray,
                    fontSize: 12,
                    marginBottom: 8,
                    display: 'inline-block',
                },
                '& small': {
                    fontWeight: 500,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.67,
                    letterSpacing: 0.38,
                    color: theme.palette.semantic.main,
                    '&.ok': {
                        color: theme.palette.neutral.gray,
                    },
                },
                '& .input-wrapper': {
                    width: '100%',
                    marginRight: 30,
                },
                '& .promo-action': {
                    '& .promo-btn': {
                        backgroundColor: theme.palette.primary.dark,
                        width: 356,
                        height: 44,
                    },
                },
            },
            '& .action-container': {
                marginTop: 16,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '&.mobile': {
                    flexDirection: 'column',
                    '& .continue-btn': {
                        width: '100%',
                        marginTop: 32,
                    },
                },
                '& .agree-term-wrapper': {
                    '& .MuiCheckbox-colorPrimary': {
                        color: theme.palette.neutral.lighter,
                        '&.Mui-checked': {
                            color: theme.palette.neutral.lighter,
                        },
                    },
                    '& .MuiSvgIcon-root': {
                        width: 28,
                        height: 28,
                    },
                    '& .term-text': {
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: 1.75,
                        letterSpacing: 0.3,
                        color: theme.palette.neutral.white,
                        '& .hightlight': {
                            color: theme.palette.primary.main,
                            textDecoration: 'underline',
                        },
                    },
                },
                '& .continue-btn': {
                    width: 441,
                    height: 44,
                    boxShadow: '0 4px 8px 0 rgba(19, 14, 70, 0.2)',
                },
            },
        },
        '& .back-to-plans': {
            margin: '4px 0 19px',
            padding: '0px 15px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '& p': {
                color: theme.palette.neutral.gray,
                fontSize: 14,
                fontWeight: 500,
                lineHeight: 1.71,
                letterSpacing: 0.24,
            },
            '& img': {
                width: 24,
                height: 24,
                backgroundColor: 'rgba(134, 121, 181, 0.5)',
                marginRight: 10,
                borderRadius: '50%',
            },
        },
    },
}));

export const ReviewPurchase = () => {
    const classes = useStyles();
    const {userProfile} = useSelector((state: StoreState) => state);
    const [orders, setOrders] = useState<ITempOrder[]>([]);
    const [promoCode, setPromoCode] = useState({
        value: '',
        message: '',
        error: false,
    });
    const [selectedCoin, setSelectedCoin] = useState<ICoin>();
    const [agreeTerm, setAgreeTerm] = useState(true);
    const [discountPercent, setDiscountPercent] = useState(0);

    const handleConfirm = () => {
        if (!selectedCoin) {
            pushTo(PATH.selectPlansPurchase);
            return;
        }
        createOrder({
            payWith: selectedCoin.code,
            promoCode: promoCode.value,
            orderSpecifications: orders.map((order: ITempOrder) => {
                return {
                    planId: order.planId,
                    power: order.power,
                };
            }),
        }).then((response: IOrder) => {
            // save to session
            sessionStorage.setItem('orderId', response.id);
            /// remove temp orders
            sessionStorage.removeItem('orders');
            /// next step
            pushTo(PATH.paymentPurchase);
        });
    };

    const getTotalPrice = () => {
        return orders.reduce((total: number, order) => {
            if (order.power > 0) {
                return total + order.price;
            }
            return total;
        }, 0);
    };
    const getDiscountPrice = () => {
        if (!selectedCoin) {
            return 0.0;
        }
        const priceTotal = getTotalPrice();
        const discountPrice = priceTotal * discountPercent;
        return parseFloat(convertPrice(discountPrice, selectedCoin));
    };

    const calculateTotalPrice = (isAppliedDiscount: boolean = false) => {
        if (!selectedCoin) {
            return 0.0;
        }
        const priceTotal = parseFloat(
            convertPrice(getTotalPrice(), selectedCoin),
        );
        if (isAppliedDiscount) {
            const discountPrice = getDiscountPrice();
            return parseFloat(
                (priceTotal - discountPrice).toFixed(selectedCoin.decimals),
            );
        }
        return priceTotal;
    };

    const handleChangeAgreeTerm = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setAgreeTerm(event.target.checked);
    };

    const validatePromoCode = () => {
        if (promoCode.value !== '') {
            checkPromo(promoCode.value).then(
                (response: {check: boolean; rate: number}) => {
                    if (response.check) {
                        setDiscountPercent(response.rate);
                        setPromoCode({
                            ...promoCode,
                            message: 'Promotion code applied sucessfully',
                            error: false,
                        });
                        return;
                    }
                    setPromoCode({
                        ...promoCode,
                        message: 'Promo code invalid',
                        error: true,
                    });
                },
            );
        }
    };

    const handleChangePromoCode = (value: any) => {
        setPromoCode({
            value: value.toString(),
            message: '',
            error: false,
        });
        setDiscountPercent(0);
    };

    useEffect(() => {
        const encodeOrders: any = sessionStorage.getItem('orders');
        const encodePayWithCoin: any = sessionStorage.getItem('payWithCoin');
        let noAccess = false;
        if (isJsonString(encodeOrders)) {
            const orders = JSON.parse(encodeOrders);
            setOrders(orders);
        } else {
            noAccess = true;
        }
        if (isJsonString(encodePayWithCoin)) {
            const payWithCoin = JSON.parse(encodePayWithCoin);
            setSelectedCoin(payWithCoin);
        } else {
            noAccess = true;
        }
        ////
        if (noAccess) {
            pushTo(PATH.selectPlansPurchase);
        }
    }, []);

    useEffect(() => {
        if (orders && selectedCoin) {
        }
    }, [orders, selectedCoin]);

    useEffect(() => {
        if (userProfile && userProfile.promoCode) {
            setPromoCode({
                value: userProfile.promoCode,
                message: '',
                error: false,
            });
        }
    }, [userProfile, setPromoCode]);

    const gotoTermOfServicePage = (event: React.ChangeEvent<any>) => {
        event.stopPropagation();
        event.preventDefault();
        window.open('/pdf/Expert-Mining-Terms-of-Service.pdf', '_blank');
    };

    const backToPlans = () => {
        pushTo(PATH.selectPlansPurchase);
    };

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <Grid container spacing={3} className={classes.reviewPurchaseContainer}>
            <Grid item xs={12}>
                <HeadingTab value={2} />
            </Grid>
            <Grid item xs={12} className="back-to-plans" onClick={backToPlans}>
                <img src={AngleLeftImg} alt="back to plans" />
                <Typography>Back to plans</Typography>
            </Grid>
            <Grid item xs={12} className="section">
                <Typography
                    variant="h1"
                    className={`title ${isMobile ? 'mobile' : ''}`}
                >
                    Order specification
                </Typography>
                <Table
                    dynamicHeight
                    columns={[
                        {
                            name: 'coinName',
                            title: 'Mining Plan',
                        },
                        {
                            name: 'algorithm',
                            title: 'Algorithm',
                        },
                        {
                            name: 'power',
                            title: 'Hashpower',
                            renderer: (record: ITempOrder, _value: number) => {
                                return `${record.power} ${record.powerUnit}`;
                            },
                        },
                        {
                            name: 'period',
                            title: 'Term',
                            renderer: (record: ITempOrder, _value: number) => {
                                return `${record.period} months`;
                            },
                        },
                        {
                            name: 'maintenanceFee',
                            title: 'Maintenance Fee',
                            renderer: (record: ITempOrder, _value: number) => {
                                return `${parseFloat(
                                    (record.maintenanceFee * 100).toFixed(3),
                                )} % per day`;
                            },
                        },
                        {
                            name: 'type',
                            title: 'Technology',
                        },
                        {
                            name: 'startDate',
                            title: 'Technology',
                            renderer: (_record: ITempOrder, _value: number) => {
                                return moment().format('DD/MM/YYYY');
                            },
                        },
                        {
                            name: 'price',
                            title: 'Price',
                            renderer: (record: ITempOrder, _value: number) => {
                                if (selectedCoin) {
                                    return `${convertPrice(
                                        record.price,
                                        selectedCoin,
                                    )} ${selectedCoin.code}`;
                                }
                                return `${record.price} ${record.priceUnit}`;
                            },
                        },
                    ]}
                    data={orders}
                    noDataTitle="No Orders"
                />
                <Box className={`promo-container ${isMobile ? 'mobile' : ''}`}>
                    <label>Promo code? (optional)</label>
                    <Box className="flexible">
                        <Box className="input-wrapper">
                            <ComboBox
                                freeSolo
                                options={[]}
                                value={promoCode.value}
                                onChange={handleChangePromoCode}
                                hasError={promoCode.error}
                            />
                        </Box>
                        <Box className="promo-action">
                            <Button
                                variant="contained"
                                color="primary"
                                className="promo-btn"
                                onClick={validatePromoCode}
                                disabled={promoCode.value === ''}
                            >
                                Validate
                            </Button>
                        </Box>
                    </Box>
                    {promoCode.message !== '' ? (
                        <small
                            className={`helper-text ${
                                promoCode.error ? '' : 'ok'
                            }`}
                        >
                            {promoCode.message}
                        </small>
                    ) : null}
                </Box>
                <Box className={`total-sumary ${isMobile ? 'mobile' : ''}`}>
                    <Box className="item">
                        <Typography className="text">Subtotal</Typography>
                        <Typography className="price">
                            {calculateTotalPrice()}{' '}
                            {selectedCoin?.code ?? 'N/A'}
                        </Typography>
                    </Box>
                    {discountPercent > 0 ? (
                        <Box className="item">
                            <Typography className="text">Promo code</Typography>
                            <Typography className="price">
                                -{getDiscountPrice()}{' '}
                                {selectedCoin?.code ?? 'N/A'}
                            </Typography>
                        </Box>
                    ) : null}

                    <Box className="item">
                        <Typography className="text">Total</Typography>
                        <Typography className="price primary">
                            {calculateTotalPrice(true)}{' '}
                            {selectedCoin?.code ?? 'N/A'}
                        </Typography>
                    </Box>
                </Box>
                <Box className={`action-container ${isMobile ? 'mobile' : ''}`}>
                    <Checkbox
                        label={
                            <span className="term-text">
                                Agree to the{' '}
                                <span
                                    className="hightlight"
                                    onClick={gotoTermOfServicePage}
                                >
                                    Terms of Service.
                                </span>
                            </span>
                        }
                        checked={agreeTerm}
                        onChange={handleChangeAgreeTerm}
                        className="agree-term-wrapper"
                    />
                    <Button
                        disabled={!agreeTerm}
                        variant="contained"
                        color="primary"
                        className="continue-btn"
                        onClick={handleConfirm}
                    >
                        Continue to order payment
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};
