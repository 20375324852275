import {SET_CURRENCY, ISetCurrencyAction} from '../actions/currency';
import {ICurrency} from '../types/currency';

export const currencies = (
    state: ICurrency[] = [],
    action: ISetCurrencyAction,
) => {
    if (action.type === SET_CURRENCY) {
        return action.payload;
    }
    /// default state
    return state;
};
