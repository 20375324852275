import {IReferralReward} from '../types/referral';
import {
    ISetReferralRewardAction,
    SET_REFERRAL_REWARD,
    SET_REFERRAL_LOADING,
    ISetReferralLoadingAction,
} from '../actions/referral';

const initState: IReferralReward = {
    data: [],
    isLoading: true,
};

export const referralRewards = (
    state: IReferralReward = initState,
    action: ISetReferralRewardAction | ISetReferralLoadingAction,
) => {
    if (action.type === SET_REFERRAL_REWARD) {
        const newState = {...state};
        newState.data = action.payload;
        newState.isLoading = false;
        return newState;
    }

    if (action.type === SET_REFERRAL_LOADING) {
        return {
            ...state,
            isLoading: true,
        };
    }

    return state;
};
