import React, {useState, useEffect} from 'react';
import {PageLayout} from '../../components/PageLayout';
// import {ComboBox, IComboBoxOption} from '../../components/Autocomplete';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import SelectBox from '../../components/SelectBox';
import {PATH} from '../../constants/path';
import {pushTo} from '../../helpers/history';
import {
    Grid,
    makeStyles,
    Box,
    Typography,
    MenuItem,
    TextField,
} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {ICoin} from '../../types/coin';
import {IWithdrawHistoryData, IWithdrawRequest} from '../../types/withdraw';
import {getTransactions, proceedwithdraw} from '../../middlewares/withdraw';
import {DEFAULT_PAGE_SIZE} from '../../constants/pagination';
import {WITHDRAW_HISTORY_STATUS_NAMES} from '../../constants/withdraw';
import moment from 'moment';
import {isEmpty, find, truncate} from 'lodash';
import DoubleRightIcon from '../../assets/images/icons/double-right.png';
import WalletImg from '../../assets/images/icons/wallet.png';
import EmptyDataImg from '../../assets/images/empty-order-data.png';
import {fireErrorNotification} from '../../helpers/notification';
import {MOBILE_WIDTH} from '../../constants/common';
import {toFixedFloor} from '../../helpers/common';

const useStyles = makeStyles((theme) => ({
    myWalletContainer: {
        '& .title': {
            fontSize: 32,
            fontWeight: 'bold',
            lineHeight: 'normal',
            letterSpacing: 0.6,
            color: theme.palette.neutral.white,
            marginTop: 50,
            marginBottom: -12,
            '&.mobile': {
                fontSize: 24,
            },
        },
        '& .coin-wrapper': {
            borderRadius: 4,
            padding: '33px 32px 19px',
            backgroundColor: theme.palette.primary.dark,
            height: 191,
            '& .total-coin-balance': {
                fontSize: 32,
                fontWeight: 'bold',
                lineHeight: 'normal',
                letterSpacing: 0.6,
                color: theme.palette.neutral.white,
                marginBottom: 9,
            },
            '& .coin-balance': {
                fontSize: 16,
                fontWeight: 500,
                lineHeight: 1.75,
                letterSpacing: 0.3,
                color: theme.palette.neutral.white,
                marginBottom: 14,
                paddingBottom: 16,
                borderBottom: '1px solid rgba(255,255,255,0.5)',
            },
            '& .coin-adress': {
                fontSize: 12,
                fontWeight: 500,
                lineHeight: 1.67,
                letterSpacing: 0.38,
                color: theme.palette.neutral.white,
                marginBottom: 15,
            },
            '& .withdraw-btn': {
                backgroundColor: theme.palette.neutral.white,
                color: `${theme.palette.primary.main} !important`,
            },
            '&.mobile': {
                height: 'auto',
            },
        },
        '& .address-managenent-wrapper': {
            backgroundColor: theme.palette.neutral.hashrate,
            height: 243,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            '& .text': {
                fontSize: 16,
                fontWeight: 600,
                lineHeight: 1.88,
                letterSpacing: 0.4,
                color: theme.palette.neutral.white,
                marginRight: 8,
            },
        },
        '& .empty-wallet': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.dark,
            padding: '15px 30px',
            borderRadius: 4,
            '& .flexible': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& img': {
                    width: 88,
                    marginRight: 32,
                },
                '& .title': {
                    fontSize: 24,
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.5,
                    letterSpacing: 0.4,
                    color: theme.palette.neutral.white,
                    marginTop: 0,
                    marginBottom: 0,
                },
                '& .sub-title': {
                    fontSize: 16,
                    fontWeight: 500,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.75,
                    letterSpacing: 0.3,
                    color: theme.palette.neutral.white,
                },
            },
            '& .btn-createWallet': {
                color: theme.palette.neutral.white,
                boxShadow: '0 4px 8px 0 rgba(19, 14, 70, 0.2)',
                width: 200,
                height: 44,
                fontWeight: 600,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.88,
                letterSpacing: 0.4,
                fontSize: 16,
                textTransform: 'capitalize',
            },
            '&.mobile': {
                flexDirection: 'column',
                '& .btn-createWallet': {
                    marginTop: 16,
                },
                '& .flexible': {
                    '& .title': {
                        fontSize: 20,
                    },
                },
            },
        },
        '& .amount-style': {
            backgroundColor: 'rgba(255,255,255, 0.36)',
            padding: '4px 11px',
            borderRadius: 16,
            whiteSpace: 'nowrap',
        },
    },
    withdrawPanel: {
        '& h2': {
            fontSize: 20,
            fontWeight: 'bold',
            lineHeight: 1.5,
            letterSpacing: 0.5,
            color: theme.palette.neutral.white,
            textAlign: 'center',
        },
        '& .form-control': {
            marginTop: 24,
            '&.select-box': {
                '& .MuiInputBase-root': {
                    height: 44,
                    border: `solid 2px ${theme.palette.neutral.lighter}`,
                    backgroundColor: `${theme.palette.neutral.dark} !important`,
                },
                '& .MuiSelect-select': {
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: 1.57,
                    letterSpacing: 0.24,
                },
            },
            '& label': {
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.67,
                letterSpacing: 0.38,
                color: theme.palette.neutral.gray,
                fontSize: 12,
                marginBottom: 8,
                display: 'inline-block',
            },
            '& small': {
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.67,
                letterSpacing: 0.38,
                color: theme.palette.neutral.gray,
                display: 'block',
                fontSize: 14,
                marginTop: 5,
                '& span.highlight': {
                    color: theme.palette.neutral.white,
                },
                '&.error': {
                    color: theme.palette.semantic.main,
                },
            },
            '& .your-recipient': {
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.67,
                letterSpacing: 0.38,
                color: theme.palette.neutral.lighter,
                backgroundColor: theme.palette.neutral.dark,
                fontSize: 14,
                padding: '0 16px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: 44,
            },
            '& .flexible': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .amount': {
                    marginRight: 24,
                    width: '100%',
                },
                '& .max-btn': {
                    backgroundColor: theme.palette.primary.dark,
                    height: 48,
                    '&.mobile': {
                        minWidth: 'auto',
                    },
                },
            },
        },
        '& .twofactor': {
            marginTop: 29,
            paddingTop: 16,
            borderTop: '1px solid rgba(216, 216, 216, 0.5)',
            '& .MuiTextField-root input': {
                backgroundColor: theme.palette.neutral.main,
            },
            '& .MuiTextField-root .MuiOutlinedInput-root': {
                borderColor: `${theme.palette.neutral.main} !important`,
            },
            '& fieldset': {
                borderColor: `${theme.palette.neutral.main} !important`,
            },
        },
        '& .withdraw-overview': {
            marginTop: 29,
            paddingTop: 16,
            borderTop: '1px solid rgba(216, 216, 216, 0.5)',
            '& .item': {
                borderRadius: 4,
                backgroundColor: theme.palette.neutral.hashrate,
                marginTop: 16,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .text': {
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: 1.57,
                    letterSpacing: 0.24,
                    color: theme.palette.neutral.gray,
                },
                '& .value': {
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: 1.57,
                    letterSpacing: 0.24,
                    color: theme.palette.neutral.white,
                    '&.active': {
                        fontSize: 16,
                        fontWeight: 600,
                        lineHeight: 1.88,
                        letterSpacing: 0.4,
                    },
                },
            },
        },
        '& .button-confirm': {
            marginTop: 36,
        },
    },
}));

interface MyWallet {
    amount: number;
    coin: ICoin;
    address: string;
}

export const MyWallet = () => {
    const classes = useStyles();
    const {userBalance, userWallets, withdraw, userProfile} = useSelector(
        (state: StoreState) => state,
    );
    const [twoFactorCode, setTwoFactorCode] = useState({
        value: '',
        error: '',
    });
    const [showWithdraw, setShowWithdraw] = useState(false);
    const [wallets, setWallets] = useState<MyWallet[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [selectedWallet, setSelectedWallet] = useState<MyWallet>();
    const [withdrawAmount, setWithdrawAmount] = useState({
        value: 0,
        error: '',
    });
    const [withdrawColumns] = useState([
        {
            name: 'walletAddress',
            title: 'Address',
            width: '35%',
        },
        {
            name: 'amount',
            title: 'Amount',
            width: '12%',
            renderer: (record: IWithdrawHistoryData, value: number) => {
                return (
                    <span className="amount-style">{`${value} ${record.coin.code}`}</span>
                );
            },
        },
        {
            name: 'withdrawFee',
            title: 'Transaction Fee',
            width: '15%',
            renderer: (record: IWithdrawHistoryData, value: number) => {
                return `${value} ${record.coin.code}`;
            },
        },
        {
            name: 'status',
            title: 'Status',
            width: '8%',
            renderer: (_record: IWithdrawHistoryData, value: number) =>
                WITHDRAW_HISTORY_STATUS_NAMES[value],
        },
        {
            name: 'createAt',
            title: 'Created At',
            width: '30%',
            renderer: (_record: IWithdrawHistoryData, value: string) =>
                moment(value).format('LLLL'),
        },
    ]);

    useEffect(() => {
        if (!isEmpty(userWallets) && !isEmpty(userBalance)) {
            const availableWallets: MyWallet[] = [];
            userWallets.forEach((wallet) => {
                if (!isEmpty(wallet.address)) {
                    const availableBalance = find(
                        userBalance,
                        (balance) => balance.coin.code === wallet.coin.code,
                    );

                    let availableAmount = 0;
                    if (availableBalance) {
                        availableAmount = availableBalance.amount;
                    }

                    availableWallets.push({
                        amount: availableAmount,
                        coin: wallet.coin,
                        address: wallet.address,
                    });
                }
            });
            setWallets(availableWallets);
        }
    }, [userBalance, userWallets]);

    useEffect(() => {
        getTransactions({
            PageSize: DEFAULT_PAGE_SIZE,
            PageNumber: pageNumber,
        });
    }, [pageNumber]);

    const handleChangeSelectedCoin = (event: React.ChangeEvent<any>) => {
        const walletAddress = event.target.value;
        let selectedWallet = wallets.find(
            (wallet) => wallet.address === walletAddress,
        );
        if (selectedWallet) {
            setSelectedWallet(selectedWallet);
        }
    };

    const disabledConfirmTransaction = Boolean(
        withdrawAmount.value === 0 || withdrawAmount.error !== '',
    );

    const setMaxTransfer = (_event: React.ChangeEvent<any>) => {
        const availableAmount = Number(
            selectedWallet
                ? toFixedFloor(
                      selectedWallet.amount - selectedWallet.coin.feeWithdraw,
                      selectedWallet.coin.decimals,
                  )
                : 0,
        );
        setWithdrawAmount({
            value: availableAmount,
            error: '',
        });
    };

    const handleChangeAmount = (event: React.ChangeEvent<any>) => {
        if (selectedWallet) {
            const availableAmount = Number(
                toFixedFloor(
                    selectedWallet.amount - selectedWallet.coin.feeWithdraw,
                    selectedWallet.coin.decimals,
                ),
            );
            const amount = event.target.value;
            if (amount < 0) {
                return;
            }
            let error = '';

            if (amount > availableAmount) {
                error = `Amount must not be greater than ${availableAmount} ${selectedWallet.coin.code}`;
            }
            // if (amount <= selectedWallet.coin.feeWithdraw) {
            //     error = `Amount must be greater than ${selectedWallet.coin.feeWithdraw} ${selectedWallet.coin.code}`;
            // }

            setWithdrawAmount({
                value: Number(amount),
                error: error,
            });
        }
    };

    const showWithdrawPanel = (walletAddress: string) => {
        const chosenWallet = wallets.find(
            (wallet) => wallet.address === walletAddress,
        );
        if (!chosenWallet) {
            fireErrorNotification('Wallet selection Error!!');
            return;
        }

        if (chosenWallet.amount < chosenWallet.coin.feeWithdraw) {
            fireErrorNotification('Insufficient funds');
            return;
        }

        setWithdrawAmount({
            value: 0,
            error: '',
        });
        setSelectedWallet(chosenWallet);
        setShowWithdraw(true);
    };

    const confirmTransaction = () => {
        if (withdrawAmount && selectedWallet) {
            const withdrawData: IWithdrawRequest = {
                amount: withdrawAmount.value,
                coinId: selectedWallet.coin.id,
                walletAddress: selectedWallet.address,
                twoFactorCode: twoFactorCode.value,
            };
            if (validateDataWithDraw(withdrawData)) {
                proceedwithdraw(withdrawData).then(() => {
                    setShowWithdraw(false);
                    setWithdrawAmount({
                        value: 0,
                        error: '',
                    });
                    getTransactions({
                        PageSize: DEFAULT_PAGE_SIZE,
                        PageNumber: pageNumber,
                    });
                });
            }
        }
    };

    const validateDataWithDraw = (withdrawData: IWithdrawRequest) => {
        if (!selectedWallet) {
            fireErrorNotification('Please select Coin');
            return false;
        }
        let error = '';

        if (withdrawData.coinId === '') {
            error = 'Coin can not be empty';
        }
        // if (withdrawData.amount <= selectedWallet.coin.feeWithdraw) {
        //     error = `Amount must be greater than ${selectedWallet.coin.feeWithdraw} ${selectedWallet.coin.code}`;
        // }
        if (withdrawData.walletAddress === '') {
            error = 'Addres can not be empty';
        }

        if (userProfile?.twoFactorEnabled) {
            if (twoFactorCode.value === '') {
                setTwoFactorCode({
                    ...twoFactorCode,
                    error: 'Please input your 2FA code',
                });
                return false;
            }
        }

        if (error === '') {
            return true;
        }
        fireErrorNotification(error);
        return false;
    };

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    //
    return (
        <PageLayout>
            <Grid container spacing={3} className={classes.myWalletContainer}>
                <Grid item xs={12}>
                    <Typography
                        variant="h1"
                        className={`title ${isMobile ? 'mobile' : ''}`}
                    >
                        My Wallets
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {isEmpty(wallets) ? (
                        <Box
                            className={`empty-wallet ${
                                isMobile ? 'mobile' : ''
                            }`}
                        >
                            <Box className="flexible">
                                {!isMobile ? (
                                    <img
                                        src={WalletImg}
                                        alt="wallet mining expert"
                                    />
                                ) : null}

                                <Box>
                                    <Typography className="title">
                                        You don't have any cryto wallet yet
                                    </Typography>
                                    <Typography className="sub-title">
                                        Create one now to execute your
                                        transaction
                                    </Typography>
                                </Box>
                            </Box>
                            <Button
                                variant="contained"
                                disableElevation
                                color="primary"
                                className="btn-createWallet"
                                onClick={() => pushTo(PATH.wallets)}
                            >
                                Create Wallet
                            </Button>
                        </Box>
                    ) : (
                        <Grid container spacing={3}>
                            {wallets.map((wallet, index) => (
                                <Grid item md={4} key={index}>
                                    <Box
                                        className={`coin-wrapper ${
                                            isMobile ? 'mobile' : ''
                                        }`}
                                    >
                                        <Typography
                                            variant="h4"
                                            className="total-coin-balance"
                                        >
                                            {toFixedFloor(
                                                wallet.amount,
                                                wallet.coin.decimals,
                                            )}{' '}
                                            {wallet.coin.code}
                                        </Typography>
                                        <Typography className="coin-balance">
                                            Available balance :{' '}
                                            {toFixedFloor(
                                                wallet.amount -
                                                    wallet.coin.feeWithdraw <
                                                    0
                                                    ? 0
                                                    : wallet.amount -
                                                          wallet.coin
                                                              .feeWithdraw,
                                                wallet.coin.decimals,
                                            )}{' '}
                                            {wallet.coin.code}
                                        </Typography>
                                        <Typography className="coin-adress">
                                            {isMobile
                                                ? truncate(wallet.address, {
                                                      length: 34,
                                                  })
                                                : wallet.address}
                                        </Typography>
                                        <Button
                                            fullWidth
                                            className="withdraw-btn"
                                            onClick={() =>
                                                showWithdrawPanel(
                                                    wallet.address,
                                                )
                                            }
                                        >
                                            Withdraw
                                        </Button>
                                    </Box>
                                </Grid>
                            ))}
                            <Grid item md={4}>
                                <Box
                                    className="address-managenent-wrapper"
                                    onClick={() => pushTo(PATH.wallets)}
                                >
                                    <Typography className="text">
                                        Address Managements
                                    </Typography>
                                    <img
                                        src={DoubleRightIcon}
                                        alt="address management"
                                        className="icon"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h1"
                        className={`title ${isMobile ? 'mobile' : ''}`}
                    >
                        Recent Withdrawal History
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Table
                        columns={withdrawColumns}
                        data={withdraw.data}
                        pageInfo={withdraw.pageInfo}
                        onChangePage={(page) => setPageNumber(page)}
                        hideIndex
                        isLoading={withdraw.isLoading}
                        noDataTitle="No Histories"
                        noDataImg={EmptyDataImg}
                    />
                </Grid>
            </Grid>
            <Modal
                open={showWithdraw}
                handleClose={() => setShowWithdraw(false)}
            >
                <Box className={classes.withdrawPanel}>
                    <Typography variant="h2">Withdraw</Typography>
                    <Box className="form-control select-box">
                        <label>Coin</label>
                        <SelectBox
                            fullWidth
                            value={selectedWallet?.address ?? ''}
                            handleChange={handleChangeSelectedCoin}
                        >
                            {wallets.map((wallet, index) => (
                                <MenuItem value={wallet.address} key={index}>
                                    {wallet.coin.code} ( {wallet.coin.name} )
                                </MenuItem>
                            ))}
                        </SelectBox>
                    </Box>
                    <Box className="form-control">
                        <label>
                            Recipient's {selectedWallet?.coin.code} Address
                        </label>
                        <Typography className="your-recipient">
                            {selectedWallet
                                ? isMobile
                                    ? truncate(selectedWallet.address, {
                                          length: 24,
                                      })
                                    : selectedWallet.address
                                : null}
                        </Typography>
                    </Box>
                    <Box className="form-control">
                        <label>Amount</label>
                        <Box className="flexible">
                            <TextField
                                type="number"
                                id="withdraw-amount"
                                className="amount"
                                value={withdrawAmount.value}
                                error={withdrawAmount.error !== ''}
                                onChange={handleChangeAmount}
                                variant="outlined"
                            />

                            <Button
                                variant="contained"
                                disableElevation
                                color="primary"
                                className={`max-btn ${
                                    isMobile ? 'mobile' : ''
                                }`}
                                onClick={setMaxTransfer}
                            >
                                Max
                            </Button>
                        </Box>
                        {withdrawAmount.error ? (
                            <small className="error">
                                {withdrawAmount.error}
                            </small>
                        ) : null}
                        <small>
                            Available Balance:{' '}
                            <span className="highlight">
                                {selectedWallet
                                    ? `${toFixedFloor(
                                          selectedWallet.amount -
                                              selectedWallet.coin.feeWithdraw,
                                          selectedWallet.coin.decimals,
                                      )} ${selectedWallet.coin.code}`
                                    : 0}
                            </span>
                        </small>
                    </Box>
                    {userProfile?.twoFactorEnabled ? (
                        <Box className="form-control twofactor">
                            <label>Google Authetication Code</label>
                            <TextField
                                fullWidth
                                type="number"
                                variant="outlined"
                                value={twoFactorCode.value}
                                error={twoFactorCode.error !== ''}
                                onChange={(e) =>
                                    setTwoFactorCode({
                                        value: e.target.value,
                                        error: '',
                                    })
                                }
                            />
                            {twoFactorCode.error ? (
                                <small className="error">
                                    {twoFactorCode.error}
                                </small>
                            ) : null}
                        </Box>
                    ) : null}
                    <Box className="withdraw-overview">
                        <Box className="item">
                            <Typography className="text">
                                Transaction Fee:
                            </Typography>
                            <Typography className="value">
                                {selectedWallet
                                    ? `${selectedWallet.coin.feeWithdraw} ${selectedWallet.coin.code}`
                                    : 'N/A'}
                            </Typography>
                        </Box>
                        <Box className="item">
                            <Typography className="text">
                                You Will Pay:
                            </Typography>
                            <Typography className="value active">
                                {selectedWallet
                                    ? `${toFixedFloor(
                                          withdrawAmount.value +
                                              selectedWallet.coin.feeWithdraw,
                                          selectedWallet.coin.decimals,
                                      )} ${selectedWallet.coin.code}`
                                    : 'N/A'}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="button-confirm">
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            disabled={disabledConfirmTransaction}
                            onClick={confirmTransaction}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </PageLayout>
    );
};
