import React from 'react';
import {TreeView, TreeItem} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import AngleUpIcon from '../../assets/images/icons/icons-angle-up.png';
import ExpandMoreIcon from '../../assets/images/icons/dropdown.png';

export const useStyles = makeStyles((theme) => ({
    treeView: {
        '& .MuiTreeItem-iconContainer svg': {
            fontSize: 24,
            color: theme.palette.neutral.lighter,
        },
        marginBottom: 16,
        '&.MuiTreeItem-content': {
            minHeight: 56,
            backgroundColor: theme.palette.neutral.hashrate,
        },
        '& .MuiTreeItem-iconContainer': {
            width: 56,
            height: 56,
            borderRadius: 4,
            backgroundColor: theme.palette.neutral.hashrate,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1.88,
            letterSpacing: 0.4,
            color: theme.palette.neutral.white,
            marginRight: 8,
        },
        '&:last-child': {
            marginBottom: 0,
        },
    },
    mobiletreeView: {
        '& .MuiTreeItem-iconContainer svg': {
            fontSize: 24,
            color: theme.palette.neutral.lighter,
        },
        marginBottom: 16,
        '&.MuiTreeItem-content': {
            minHeight: 56,
            backgroundColor: theme.palette.neutral.hashrate,
        },
        '& .MuiTreeItem-iconContainer': {
            display: 'none',
        },
        '&:last-child': {
            marginBottom: 0,
        },
        '& .MuiTreeItem-group': {
            marginLeft: 0,
        },
    },
    question: {
        position: 'relative',
        '& .MuiTreeItem-label': {
            fontSize: 16,
            minHeight: 56,
            fontWeight: 600,
            backgroundColor: `${theme.palette.neutral.hashrate} !important`,
            lineHeight: 1.88,
            letterSpacing: 0.4,
            display: 'flex',
            paddingLeft: 16,
            paddingRight: 70,
            alignItems: 'center',
            color: theme.palette.neutral.white,
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 16,
            right: 24,
            width: 24,
            height: 24,
            backgroundImage: `url(${ExpandMoreIcon})`,
            pointerEvents: 'none',
        },
        '&.Mui-expanded': {
            '&::after': {
                backgroundImage: `url(${AngleUpIcon})`,
            },
        },
    },
    mobileQuestion: {
        position: 'relative',
        '& .MuiTreeItem-label': {
            fontSize: 16,
            minHeight: 56,
            fontWeight: 600,
            backgroundColor: `${theme.palette.neutral.hashrate} !important`,
            lineHeight: 1.88,
            letterSpacing: 0.4,
            display: 'flex',
            padding: '16px 70px 16px 16px',
            alignItems: 'center',
            color: theme.palette.neutral.white,
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 30,
            right: 24,
            width: 24,
            height: 24,
            backgroundImage: `url(${ExpandMoreIcon})`,
            pointerEvents: 'none',
        },
        '&.Mui-expanded': {
            '&::after': {
                backgroundImage: `url(${AngleUpIcon})`,
            },
        },
    },
    answer: {
        marginLeft: 47,
        '& .MuiTreeItem-label': {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.57,
            letterSpacing: 0.24,
            color: theme.palette.neutral.gray,
            paddingRight: 15,
        },
        '& .MuiTreeItem-iconContainer': {
            display: 'none',
        },
    },
    mobileAnswer: {
        marginLeft: 0,
        '& .MuiTreeItem-label': {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 1.57,
            letterSpacing: 0.24,
            color: theme.palette.neutral.gray,
            paddingRight: 15,
        },
        '& .MuiTreeItem-iconContainer': {
            display: 'none',
        },
    },
}));

export interface QuestionInterface {
    isMobile?: boolean;
}

export const WhatIsAffiliateBonus = ({isMobile}: QuestionInterface) => {
    const classes = useStyles();
    return (
        <TreeView
            className={isMobile ? classes.mobiletreeView : classes.treeView}
            defaultCollapseIcon={<HelpIcon />}
            defaultExpandIcon={<HelpIcon />}
        >
            <TreeItem
                className={isMobile ? classes.mobileQuestion : classes.question}
                nodeId="1"
                label="What Is the Bitcoin (BTC) Reward System You Give to Affiliates?"
            >
                <TreeItem
                    className={isMobile ? classes.mobileAnswer : classes.answer}
                    nodeId="2"
                    label={
                        <div>
                            <p>
                                With the BTC Reward System, you have the option
                                to receive BTC referral rewards for every
                                referral you generate instead of getting bonus
                                hash power. The value of BTC rewards will depend
                                on your current affiliate rank, and the same
                                goes with the hash power upgrades you'll
                                receive.
                            </p>
                            <p>
                                <b>
                                    The following timeframes apply to the
                                    release of referral rewards:
                                </b>
                            </p>
                            <div>
                                <ul>
                                    <li>
                                        For contracts paid with
                                        cryptocurrencies: The system will
                                        release referral rewards six (6) days
                                        after purchase.
                                    </li>
                                    <li>
                                        For contracts paid with credit card: The
                                        system will release referral rewards
                                        thirty-two (32) days after purchase.
                                    </li>
                                    <li>
                                        For contracts paid through wire
                                        transfer: The system will release
                                        referral rewards one (1) day after the
                                        contract's approval.
                                    </li>
                                </ul>
                            </div>
                            <p>
                                Currently, the only option for receiving
                                referral rewards is in the form of BTC, which
                                you'll see is the default setting. The bitcoins
                                have a corresponding US dollar value that the
                                system will convert to BTC value upon release.
                                Once the total amount has gone over the
                                threshold value, the system will automatically
                                transfer the referral reward to the same wallet
                                address you use for your daily mining returns.
                                In case you haven't added a wallet address yet,
                                we will hold all referral rewards in the system
                                until you add one.
                            </p>
                            <p>
                                The different types of affiliate bonuses are
                                specific and not interchangeable. That means you
                                cannot exchange a BTC reward into hash power
                                upgrade, and vice versa. Each type requires
                                different processing; thus, they will remain
                                interchangeable to make the process seamless for
                                our affiliates.
                            </p>
                            <p>
                                If you have any questions, don't hesitate to
                                reach out to us. Our team is always available to
                                help and clarify the new program. Thank you for
                                being such excellent partners in mining and
                                advancing BTC's success!
                            </p>
                        </div>
                    }
                />
            </TreeItem>
        </TreeView>
    );
};
export const HowToUseAffiliateCode = ({isMobile}: QuestionInterface) => {
    const classes = useStyles();
    return (
        <TreeView
            className={isMobile ? classes.mobiletreeView : classes.treeView}
            defaultCollapseIcon={1}
            defaultExpandIcon={1}
        >
            <TreeItem
                className={isMobile ? classes.mobileQuestion : classes.question}
                nodeId="1"
                label="In What Ways Can I Use My Affiliate Code?"
            >
                <TreeItem
                    className={isMobile ? classes.mobileAnswer : classes.answer}
                    nodeId="2"
                    label={
                        <div>
                            <p>
                                Share, share, and share! You can share it on
                                Facebook, Twitter, Instagram, Pinterest, your
                                mom's blog, or your brother's podcast. It
                                doesn't matter where or how you share it. Just
                                get it out there!
                            </p>
                        </div>
                    }
                />
            </TreeItem>
        </TreeView>
    );
};
export const HowDoesAffiliateCodeWork = ({isMobile}: QuestionInterface) => {
    const classes = useStyles();
    return (
        <TreeView
            className={isMobile ? classes.mobiletreeView : classes.treeView}
            defaultCollapseIcon={2}
            defaultExpandIcon={2}
        >
            <TreeItem
                className={isMobile ? classes.mobileQuestion : classes.question}
                nodeId="1"
                label="Please Tell Me How My Affiliate Code Works?"
            >
                <TreeItem
                    className={isMobile ? classes.mobileAnswer : classes.answer}
                    nodeId="2"
                    label={
                        <div>
                            <p>
                                It's simple. When someone you referred to Expert
                                Mining uses your affiliate code, they
                                automatically get a 3% discount on their
                                purchase. In turn, you will get a "referral
                                reward," which can be in the form of bitcoins or
                                hash power upgrade. Incidentally, the hash power
                                upgrade corresponds to your affiliate rank when
                                your referral uses your affiliate code. That
                                means the higher your level is, the bigger the
                                rewards you'll get. You will get an email
                                notification from us when someone uses your
                                affiliate code.
                            </p>
                        </div>
                    }
                />
            </TreeItem>
        </TreeView>
    );
};
export const HowBigRewards = ({isMobile}: QuestionInterface) => {
    const classes = useStyles();
    return (
        <TreeView
            className={isMobile ? classes.mobiletreeView : classes.treeView}
            defaultCollapseIcon={3}
            defaultExpandIcon={3}
        >
            <TreeItem
                className={isMobile ? classes.mobileQuestion : classes.question}
                nodeId="1"
                label="What Kind of Rewards Should I Expect?"
            >
                <TreeItem
                    className={isMobile ? classes.mobileAnswer : classes.answer}
                    nodeId="2"
                    label={
                        <div>
                            <p>
                                To give you an idea, let's say you are at our
                                "Starter" tier, or Rank 1. At this tier, you
                                will get 2.5% of the hash power quantity that
                                your referral purchased. For instance, if your
                                referral used your affiliate code to purchase a
                                10MH/s contract, you will get 0.25MH/s as a
                                reward, while your referral will get 3% off the
                                total price. Nobody loses with our rewards
                                system.
                            </p>
                        </div>
                    }
                />
            </TreeItem>
        </TreeView>
    );
};
export const RankingSystemMeaning = ({isMobile}: QuestionInterface) => {
    const classes = useStyles();
    return (
        <TreeView
            className={isMobile ? classes.mobiletreeView : classes.treeView}
            defaultCollapseIcon={4}
            defaultExpandIcon={4}
        >
            <TreeItem
                className={isMobile ? classes.mobileQuestion : classes.question}
                nodeId="1"
                label="Could You Please Explain the “Ranking” System to Me?"
            >
                <TreeItem
                    className={isMobile ? classes.mobileAnswer : classes.answer}
                    nodeId="2"
                    label={
                        <div>
                            <p>
                                Our ranking system or tier-based program means
                                you will level up depending on your progress.
                                The more people you refer to Expert Mining, the
                                bigger your reward. Currently, there are five
                                (5) ranks you'll want to achieve – Starter,
                                Copper, Bronze, Silver, and Gold –, with each
                                one offering a bigger reward than the last
                                (lower) rank. You'll likewise be in line for
                                some extraordinary rewards.
                            </p>
                        </div>
                    }
                />
            </TreeItem>
        </TreeView>
    );
};
export const HowToRankup = ({isMobile}: QuestionInterface) => {
    const classes = useStyles();
    return (
        <TreeView
            className={isMobile ? classes.mobiletreeView : classes.treeView}
            defaultCollapseIcon={5}
            defaultExpandIcon={5}
        >
            <TreeItem
                className={isMobile ? classes.mobileQuestion : classes.question}
                nodeId="1"
                label="How Do I Go Up in Rank? Is it Possible to Lose My Current Rank?"
            >
                <TreeItem
                    className={isMobile ? classes.mobileAnswer : classes.answer}
                    nodeId="2"
                    label={
                        <div>
                            <p>
                                Your rank is based on the total amount your
                                referrals have purchased over the last 100 days.
                            </p>
                            <p>
                                As mentioned previously, the bottom rank is Rank
                                1, our Starter tier. To "rank up," you will need
                                to accumulate US $100 in purchases made by your
                                referrals. Let's say you referred US $50 in
                                sales on the first two weeks and another US $60
                                on the third week. Your accumulated referred
                                sales will be US $110, and that will put you on
                                Rank 2, our Copper tier. After six weeks, your
                                referrals might purchase another US $400, making
                                your total US $510. That will put you on Rank 3,
                                our Bronze tier.
                            </p>
                            <p>
                                Remember, ranks are based on purchase activities
                                over the last 100 days. That means the ranks are
                                variable, so your level can fluctuate over time.
                                We discard referral purchases older than 100
                                days, which means your position and subsequent
                                rewards will adjust accordingly.
                            </p>
                            <p>
                                Your current rank and total sales performance
                                are posted on your affiliate page for easy
                                reference.
                            </p>
                        </div>
                    }
                />
            </TreeItem>
        </TreeView>
    );
};
export const WhatAreAffiliateID = ({isMobile}: QuestionInterface) => {
    const classes = useStyles();
    return (
        <TreeView
            className={isMobile ? classes.mobiletreeView : classes.treeView}
            defaultCollapseIcon={6}
            defaultExpandIcon={6}
        >
            <TreeItem
                className={isMobile ? classes.mobileQuestion : classes.question}
                nodeId="1"
                label="Please Tell Me What Affiliate ID URLs Are and How I Should Use Them. Is the URL Different from a Promotional Code?"
            >
                <TreeItem
                    className={isMobile ? classes.mobileAnswer : classes.answer}
                    nodeId="2"
                    label={
                        <div>
                            <p>
                                The affiliate ID URLs are personalized for you.
                                You can use them to link across various
                                platforms – your websites, social media
                                accounts, and your email and forum signatures.
                                Expert Mining will tie anyone who clicks on that
                                link to create an account on the system to you
                                as the referrer. We will likewise connect all
                                future purchases to your affiliate account, so
                                long as no promotional code is entered.
                            </p>
                            <p>
                                On the other hand, the promotional code may be
                                used on a new account and an existing one. The
                                promo code needs to be applied upon checkout.
                            </p>
                            <p>
                                Keep in mind that the promo code always takes
                                priority. So, let's suppose you sign up using
                                affiliate A's ID URL but apply affiliate B's
                                promo code to get a discount. Then only
                                affiliate B will receive a hash power bonus.
                            </p>
                        </div>
                    }
                />
            </TreeItem>
        </TreeView>
    );
};
export const WhyDoSomePendingSale = ({isMobile}: QuestionInterface) => {
    const classes = useStyles();
    return (
        <TreeView
            className={isMobile ? classes.mobiletreeView : classes.treeView}
            defaultCollapseIcon={7}
            defaultExpandIcon={7}
        >
            <TreeItem
                className={isMobile ? classes.mobileQuestion : classes.question}
                nodeId="1"
                label="Why Is My Sale Showing a “Pending” Status?"
            >
                <TreeItem
                    className={isMobile ? classes.mobileAnswer : classes.answer}
                    nodeId="2"
                    label={
                        <div>
                            <p>
                                It takes up to six (6) days for the system to
                                finalize purchases, which is why some of your
                                sales will stay "pending." Rest assured that we
                                will credit these sales rightfully once the
                                system completes your referral's purchase.
                            </p>
                        </div>
                    }
                />
            </TreeItem>
        </TreeView>
    );
};
export const IHaveSuggestion = ({isMobile}: QuestionInterface) => {
    const classes = useStyles();
    return (
        <TreeView
            className={isMobile ? classes.mobiletreeView : classes.treeView}
            defaultCollapseIcon={8}
            defaultExpandIcon={8}
        >
            <TreeItem
                className={isMobile ? classes.mobileQuestion : classes.question}
                nodeId="1"
                label="I Would Like to Make a Suggestion"
            >
                <TreeItem
                    className={isMobile ? classes.mobileAnswer : classes.answer}
                    nodeId="2"
                    label={
                        <div>
                            <p>
                                By all means, please let us know! We would love
                                to hear your feedback, so send anything – ideas,
                                suggestions, thoughts, and opinions – to{' '}
                                <u>support@expertmining.io</u>, and you will
                                receive personalized help and support. We are
                                continually working to improve our program, and
                                your feedback will help us make it the best it
                                can be.
                            </p>
                        </div>
                    }
                />
            </TreeItem>
        </TreeView>
    );
};
