import React from 'react';
import {Grid, Typography, makeStyles, Box} from '@material-ui/core';
import {PATH} from '../../constants/path';
import {PageLayout} from '../../components/PageLayout';
import {Route, Switch} from 'react-router-dom';
import {GeneralInformation} from './GeneralInformation';
import {ChangePassword} from './ChangePassword';
import {TwoFactorAuth} from './TwoFactorAuth';
import {pushTo} from '../../helpers/history';
import {MOBILE_WIDTH} from '../../constants/common';

export const MENU_LIST = [
    {
        title: 'Profile',
        path: PATH.generalInformation,
    },
    {
        title: '2Factor Authentication',
        path: PATH.twoFactorAuth,
    },
    {
        title: 'Password',
        path: PATH.changePassword,
    },
];

const useStyles = makeStyles((theme) => ({
    settingTitle: {
        marginTop: 60,
        '& .title': {
            textAlign: 'center',
            fontFamily: 'Poppins',
            fontSize: 32,
            fontWeight: 'bold',
            letterSpacing: 0.8,
            color: theme.palette.neutral.white,
        },
        marginBottom: 24,
    },
    contentWrapper: {
        backgroundColor: theme.palette.neutral.hashrate,
        '& .tab-container': {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: '0 33px',
            '&.mobile': {
                padding: '0 16px',
                '& h3': {
                    height: 40,
                    fontSize: 12,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0px 8px',
                },
            },
            '& h3': {
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: 20,
                fontWeight: 'bold',
                lineHeight: 1.5,
                letterSpacing: 0.5,
                color: theme.palette.neutral.gray,
                borderBottom: `1px solid ${theme.palette.neutral.timber}`,
                width: '100%',
                padding: '15px 0px 30px',
                cursor: 'pointer',
                '&.active': {
                    color: theme.palette.primary.main,
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                },
            },
        },
        '& .content': {
            padding: '48px 33px 48px',
            '&.mobile': {
                padding: '48px 16px 48px',
            },
        },
    },
}));

export const Settings = ({location}) => {
    const classes = useStyles();
    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <PageLayout>
            <Grid container spacing={3} className={classes.settingTitle}>
                <Grid item xs={12}>
                    <Typography variant="h2" className="title">
                        Settings
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3} justify="center">
                <Grid item md={10} className={classes.contentWrapper}>
                    <Box
                        className={`tab-container ${isMobile ? 'mobile' : ''}`}
                    >
                        {MENU_LIST.map((item, index) => (
                            <Typography
                                variant="h3"
                                className={
                                    item.path === location.pathname
                                        ? 'active'
                                        : 'tab'
                                }
                                onClick={() => pushTo(item.path)}
                                key={index}
                            >
                                {item.title}
                            </Typography>
                        ))}
                    </Box>
                    <Box className={`content ${isMobile ? 'mobile' : ''}`}>
                        <Switch>
                            <Route
                                path={PATH.generalInformation}
                                exact
                                component={GeneralInformation}
                            />
                            <Route
                                path={PATH.changePassword}
                                exact
                                component={ChangePassword}
                            />
                            <Route
                                path={PATH.twoFactorAuth}
                                exact
                                component={TwoFactorAuth}
                            />
                        </Switch>
                    </Box>
                </Grid>
            </Grid>
        </PageLayout>
    );
};
