import {SET_PLAN, ISetPlanAction} from '../actions/plan';
import {IMiningPlan, IMiningPlanRadiant} from '../types/plan';
import {forEach, head, isNull} from 'lodash';
import {ITempOrder} from '../types/order';
import {isJsonString} from '../helpers/validate';

export const plans = (
    state: IMiningPlan[] | [] = [],
    action: ISetPlanAction,
) => {
    if (action.type === SET_PLAN) {
        let orders: ITempOrder[] = [];
        let sessionOrders = sessionStorage.getItem('orders');
        if (!isNull(sessionOrders) && isJsonString(sessionOrders)) {
            orders = JSON.parse(sessionOrders);
        }

        const miningPlan: IMiningPlan[] = [];
        /// parse plan data to frontend data
        forEach(action.payload, (plan) => {
            const planName = `${plan.coin.name} mining`;
            //// set mining plan parent object
            let planIndex = miningPlan.findIndex(
                (plan: IMiningPlan) => plan.name === planName,
            );
            if (planIndex === -1) {
                miningPlan.push({
                    name: planName,
                    radiants: [],
                });
                planIndex = miningPlan.length - 1;
            }
            //// set radiants object
            let radiantIndex = miningPlan[planIndex].radiants.findIndex(
                (radiant: IMiningPlanRadiant) => radiant.name === plan.type,
            );
            if (radiantIndex === -1) {
                miningPlan[planIndex].radiants.push({
                    name: plan.type,
                    plans: [],
                });
                /// get new key
                radiantIndex = miningPlan[planIndex].radiants.length - 1;
            }

            // fill selectedpower
            const orderSelected = orders.find(
                (order) => order.planId === plan.id,
            );

            /// set plan value
            miningPlan[planIndex].radiants[radiantIndex].plans.push({
                id: plan.id,
                coinId: plan.coinId,
                name: plan.name,
                period: plan.period,
                priceUnit: plan.priceUnit,
                maintenanceFee: plan.maintenanceFee,
                algorithm: plan.coin.algorithm,
                actFlag: plan.actFlag,
                powerUnit: plan.coin.powerUnit,
                image: plan.image,
                type: plan.type,
                coinName: plan.coin.name,
                selectedPower: orderSelected
                    ? orderSelected.power
                    : head(plan.planDetails)?.power ?? 0,
                // set slider value
                marks: plan.planDetails.map((mark) => {
                    return {
                        value: mark.power,
                        price: mark.price,
                    };
                }),
            });
        });
        return miningPlan;
    }
    return state;
};
