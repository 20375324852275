import {IWithdrawHistory} from '../types/withdraw';
import {
    SET_WITHDRAW_HISTORY,
    ISetWithdrawHistoryAction,
    SET_WITHDRAW_LOADING,
    ISetWithdrawLoadingAction,
} from '../actions/withdraw';

const initState: IWithdrawHistory = {
    data: [],
    isLoading: true,
    pageInfo: {
        currentPage: 1,
        hasNext: true,
        hasPrevious: false,
        pageSize: 1,
        totalCount: 1,
        totalPages: 1,
    },
};

export const withdraw = (
    state: IWithdrawHistory = initState,
    action: ISetWithdrawHistoryAction | ISetWithdrawLoadingAction,
) => {
    if (action.type === SET_WITHDRAW_HISTORY) {
        const newState = action.payload;
        if (!newState.pageInfo) {
            newState.pageInfo = initState.pageInfo;
        }
        newState.isLoading = false;
        return newState;
    }
    if (action.type === SET_WITHDRAW_LOADING) {
        return {
            ...state,
            isLoading: true,
        };
    }
    return state;
};
