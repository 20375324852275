import React from 'react';
import {makeStyles, Box, Grid, Typography} from '@material-ui/core';
import {IRank} from '../../types/rank';
import RankImg from '../../assets/images/rank.png';
const useStyles = makeStyles((theme) => ({
    rankWrapper: {
        '& .item-container': {
            border: `2px solid rgba(134, 121, 181, 0.5)`,
            backgroundColor: theme.palette.neutral.dark,
            borderRadius: 4,
            padding: 24,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
            '&.active': {
                border: `2px solid ${theme.palette.primary.dark}`,
            },
            '& .item-top': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .rank-img': {
                    marginRight: 16,
                    borderRadius: 4,
                    backgroundColor: theme.palette.neutral.purple3,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '7px 4px',
                    '& img': {
                        maxWidth: 88,
                        maxHeight: 82,
                        objectFit: 'contain',
                    },
                },
                '& .rank-top': {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    '& .current-rank': {
                        borderRadius: 4,
                        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
                        border: `2px solid ${theme.palette.primary.dark}`,
                        fontSize: 12,
                        fontWeight: 500,
                        lineHeight: 1.67,
                        letterSpacing: 0.38,
                        textAlign: 'center',
                        color: theme.palette.neutral.white,
                        backgroundColor: theme.palette.primary.dark,
                        width: '100%',
                        marginBottom: 13,
                    },
                    '& .rank-level': {
                        fontSize: 12,
                        fontWeight: 500,
                        lineHeight: 2,
                        letterSpacing: 0.23,
                        color: theme.palette.neutral.gray,
                        width: '100%',
                    },
                    '& .rank-name': {
                        fontSize: 24,
                        fontWeight: 'bold',
                        letterSpacing: 0.4,
                        color: theme.palette.neutral.white,
                        width: '100%',
                    },
                },
            },
            '& .item-bottom': {
                marginTop: 32,
                '& .rank-bonus': {
                    backgroundColor: 'rgba(255,255,255, 0.1)',
                    borderRadius: 4,
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: 1.88,
                    letterSpacing: 0.4,
                    textAlign: 'center',
                    color: theme.palette.neutral.white,
                    padding: 1,
                },
                '& .rank-note': {
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: 1.67,
                    letterSpacing: 0.38,
                    textAlign: 'center',
                    color: theme.palette.neutral.gray,
                    marginTop: 12,
                },
            },
        },
    },
}));

interface IProps {
    ranks: IRank[];
    userRank: IRank | null;
}

export const Ranking = (props: IProps) => {
    const {ranks, userRank} = props;
    const classes = useStyles();

    return (
        <Grid container spacing={3} justify="center">
            {ranks.map((rank, index) => (
                <Grid
                    item
                    md={6}
                    lg={4}
                    className={classes.rankWrapper}
                    key={index}
                >
                    <Box
                        className={`item-container ${
                            userRank && rank.level === userRank.level
                                ? `active`
                                : ''
                        }`}
                    >
                        <Box className="item-top">
                            <Box className="rank-img">
                                <img src={rank.image ?? RankImg} alt="rank" />
                            </Box>
                            <Box className="rank-top">
                                {userRank && rank.level === userRank.level ? (
                                    <Typography className="current-rank">
                                        Your current rank
                                    </Typography>
                                ) : null}
                                <Typography className="rank-level">
                                    Rank {rank.level}
                                </Typography>
                                <Typography className="rank-name">
                                    {rank.name}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="item-bottom">
                            <Typography className="rank-bonus">
                                {(rank.percent * 100).toFixed(1)}% Bonus
                            </Typography>
                            <Typography className="rank-note">
                                You will now gain{' '}
                                {(rank.percent * 100).toFixed(1)}% bonus
                                hashpower with every new sale.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};
