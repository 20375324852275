import React from 'react';
import {Grid, makeStyles, Box, Typography} from '@material-ui/core';
import {HeadingTab} from './HeadingTab';
import {pushTo} from '../../helpers/history';
import {PATH} from '../../constants/path';
import Button from '../../components/Button';
import SuccessImg from '../../assets/images/success-thumb.png';

const useStyles = makeStyles((theme) => ({
    thankContainer: {
        '& .content': {
            textAlign: 'center',
            '& .title': {
                fontSize: 24,
                fontWeight: 'bold',
                lineHeight: 1.5,
                letterSpacing: 0.4,
                color: theme.palette.neutral.white,
                marginBottom: 9,
            },
            '& .sub-title': {
                fontSize: 16,
                fontWeight: 500,
                lineHeight: 1.5,
                letterSpacing: 0.3,
                color: theme.palette.neutral.gray,
                marginBottom: 16,
            },
            '& .go-btn': {
                border: `2px solid ${theme.palette.primary.main}`,
                color: `${theme.palette.primary.main} !important`,
                width: 200,
                height: 44,
            },
            '& .thumb': {
                marginBottom: 12,
            },
        },
    },
}));

export const ConfirmPurchase = () => {
    const classes = useStyles();

    const goToDashboard = (_event: React.ChangeEvent<any>) => {
        pushTo(PATH.dashboard);
    };

    return (
        <Grid container spacing={3} className={classes.thankContainer}>
            <Grid item xs={12}>
                <HeadingTab value={4} />
            </Grid>
            <Grid item xs={12}>
                <Box className="content">
                    <img src={SuccessImg} alt="thank you" className="thumb" />
                    <Typography variant="h2" className="title">
                        Thank You
                    </Typography>
                    <Typography className="sub-title">
                        Your order has been completed
                    </Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={goToDashboard}
                        className="go-btn"
                    >
                        Go Dashboard
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};
