import React from 'react';
import {Router} from 'react-router-dom';
import {Routes} from './Routes';
import {History} from 'history';

const App = (props: {history: History}) => {
    return (
        <Router history={props.history}>
            <Routes />
        </Router>
    );
};

export default App;
