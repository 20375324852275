import {ITransaction} from '../types/transaction';
import {
    SET_TRANSACTION,
    ISetTransactionAction,
    SET_TRANSACTION_LOADING,
    ISetTransactionLoadingAction,
} from '../actions/transaction';

const initState = {
    data: [],
    isLoading: true,
    pageInfo: {
        currentPage: 1,
        hasNext: true,
        hasPrevious: false,
        pageSize: 1,
        totalCount: 1,
        totalPages: 1,
    },
};

export const transaction = (
    state: ITransaction = initState,
    action: ISetTransactionAction | ISetTransactionLoadingAction,
) => {
    if (action.type === SET_TRANSACTION) {
        const newState = action.payload;
        if (!newState.pageInfo) {
            newState.pageInfo = initState.pageInfo;
        }
        newState.isLoading = false;
        return newState;
    }
    if (action.type === SET_TRANSACTION_LOADING) {
        return {
            ...state,
            isLoading: true,
        };
    }
    return state;
};
