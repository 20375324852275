import React, {
    useState,
    useRef,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import {
    MenuList,
    makeStyles,
    Box,
    Button,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
} from '@material-ui/core';

import ExpandMoreIcon from '../assets/images/icons/expand-more.png';
const useStyles = makeStyles((theme) => ({
    userToolbar: {
        fontSize: 16,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0.3,
        color: theme.palette.neutral.white,
        textTransform: 'none',
    },
    userToolBarContent: {
        zIndex: 1,
        width: 244,
        borderRadius: 4,
        backgroundColor: theme.palette.neutral.hashrate,
        border: `2px solid ${theme.palette.neutral.lighter}`,
        '& .paper': {
            backgroundColor: 'transparent',
            '& ul': {
                padding: '9px 0px',
                '& li': {
                    '& .toolbar-item': {
                        color: `${theme.palette.neutral.white} !important`,
                        justifyContent: 'flex-start',
                        fontSize: '14px !important',
                        fontWeight: '500 !important',
                        lineHeight: '1.57 !important',
                        letterSpacing: '0.24px !important',
                        textTransform: 'capitalize',
                        border: 'none',
                        '&:hover': {
                            backgroundColor: 'transparent !important',
                        },
                    },
                    '&.divider': {
                        backgroundColor: theme.palette.neutral.lighter,
                        width: '83%',
                        margin: '5px auto',
                        height: 1,
                        padding: 0,
                    },
                    '&:last-child': {
                        '& .toolbar-item': {
                            color: `${theme.palette.semantic.main} !important`,
                        },
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.neutral.dark,
                    },
                },
            },
        },
    },
    contentWrapper: {
        color: theme.palette.neutral.white,
    },
}));

interface IProps {
    children: any;
    buttonContent: any;
    ref?: any;
}

const Dropdown = forwardRef((props: IProps, ref: any) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    useImperativeHandle(ref, () => ({
        handleToggle: handleToggle,
    }));

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Box>
            <Button
                disableElevation
                className={classes.userToolbar}
                endIcon={
                    <img alt="expert mining expand-more" src={ExpandMoreIcon} />
                }
                onClick={handleToggle}
                ref={anchorRef}
                aria-controls={open ? 'dropdown-list' : undefined}
                aria-haspopup="true"
            >
                {props.buttonContent}
            </Button>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className={classes.userToolBarContent}
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper className="paper">
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="dropdown-list"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {props.children}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
});
export default Dropdown;
