import {createMuiTheme} from '@material-ui/core';
declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        neutral: any;
        semantic: any;
    }
    interface PaletteOptions {
        neutral: {
            main: React.CSSProperties['color'];
            dark: React.CSSProperties['color'];
            darker: React.CSSProperties['color'];
            white: React.CSSProperties['color'];
            lighter: React.CSSProperties['color'];
            gray: React.CSSProperties['color'];
            hashrate: React.CSSProperties['color'];
            crosshair: React.CSSProperties['color'];
            timber: React.CSSProperties['color'];
            purple1: React.CSSProperties['color'];
            purple2: React.CSSProperties['color'];
        };
        semantic: {
            main: React.CSSProperties['color'];
            dark: React.CSSProperties['color'];
            secondary: React.CSSProperties['color'];
        };
    }
}

export const PalateColors = {
    primary: {
        main: '#F59D52',
        dark: '#5787FF',
    },
    secondary: {
        main: '#35E1FF',
        dark: '#F5C452',
    },
    neutral: {
        main: '#0D003E',
        dark: '#16074A',
        darker: '#1F1150',
        lighter: '#8679B5',
        white: '#FFFFFF',
        gray: '#CACACD',
        hashrate: '#1E0E5B',
        crosshair: '#86809e',
        timber: '#2F1E6A',
        orange: '#F5C452',
        purple1: '#5483ff',
        purple2: '#201150',
        purple3: '#2c1a6a',
    },
    semantic: {
        main: '#F5525A',
        dark: '#F5525A',
        secondary: '#84FEB9',
    },
};

export const theme = createMuiTheme({
    palette: PalateColors,
    spacing: 10,
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: 'Poppins !important',
            },
            h1: {
                fontWeight: 600,
                fontSize: 48,
            },
            h2: {
                fontWeight: 600,
                fontSize: 36,
            },
            subtitle1: {
                fontWeight: 400,
                fontSize: 24,
            },
            subtitle2: {
                fontWeight: 'normal',
                fontSize: 22,
            },
            caption: {
                fontSize: 14,
            },
            body1: {
                fontSize: 14,
            },
        },
        MuiButton: {
            root: {
                height: 40,
                minWidth: 170,
                fontFamily: 'Poppins !important',
                textTransform: 'capitalize',
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 13px) scale(1)',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '10.5px 14px',
            },
        },
        MuiTab: {
            root: {
                minWidth: '100px !important',
            },
            textColorPrimary: {
                color: '#113a56',
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                textTransform: 'none',
                fontSize: 13,
                width: 'auto',
                '&$disabled': {
                    color: undefined,
                },
            },
        },
        MuiSlider: {
            root: {
                color: '#ffab32',
                height: 6,
            },
            thumb: {
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus, &:hover, &$active': {
                    boxShadow: 'inherit',
                },
            },
        },
        MuiTextField: {
            root: {
                outline: 'none',
                borderRadius: 4,
                backgroundColor: PalateColors.neutral.dark,
                '& .MuiInput-underline': {
                    '&:before': {
                        content: 'none',
                    },
                    '&:after': {
                        content: 'none',
                    },
                },
                '& .MuiIconButton-root': {
                    color: PalateColors.neutral.lighter,
                },
                '& input, textarea': {
                    backgroundColor: PalateColors.neutral.dark,
                    color: PalateColors.neutral.lighter,
                    border: 'none',
                    outline: 'none',
                    padding: '10px 16px',
                    fontSize: 14,
                    fontWeight: 500,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 1.71,
                    letterSpacing: 0.24,
                    minHeight: 24,
                    fontFamily: 'Poppins',
                    transition: 'ease-in-out 0.3s all',
                    '&:-webkit-autofill': {
                        'transition-delay': '9999s',
                        'transition-property': 'background-color, color',
                    },
                    '&:hover': {
                        color: PalateColors.neutral.white,
                    },
                    '&:active': {
                        color: PalateColors.neutral.white,
                    },
                    '&:visited': {
                        color: PalateColors.neutral.white,
                    },
                    '&:focus': {
                        color: PalateColors.neutral.white,
                    },
                },
                '& textarea': {
                    padding: 0,
                },
                '& .MuiOutlinedInput-root': {
                    border: `2px solid transparent`,
                    borderRadius: 4,
                    transition: 'ease-in-out 0.3s all',
                    outline: 'none',
                    '&:hover': {
                        '& fieldset': {
                            border: `2px solid ${PalateColors.neutral.lighter}`,
                        },
                        outline: 'none',
                    },
                    '&:active': {
                        '& fieldset': {
                            border: `2px solid ${PalateColors.neutral.lighter}`,
                        },
                        outline: 'none',
                    },
                    '&:visited': {
                        '& fieldset': {
                            border: `2px solid ${PalateColors.neutral.lighter}`,
                        },
                        outline: 'none',
                    },
                    '&:focus': {
                        '& fieldset': {
                            border: `2px solid ${PalateColors.neutral.lighter}`,
                        },
                        outline: 'none',
                    },
                    '& fieldset': {
                        border: `2px solid ${PalateColors.neutral.dark}`,
                        transition: 'ease-in-out 0.3s all',
                        outline: 'none',
                        borderRadius: 4,
                    },
                },
                '& .MuiOutlinedInput-root.Mui-error': {
                    '& fieldset': {
                        border: `2px solid ${PalateColors.semantic.main}`,
                    },
                },
                '& .MuiOutlinedInput-root.Mui-focused': {
                    '& fieldset': {
                        border: `2px solid ${PalateColors.neutral.lighter}`,
                    },
                },
                '& .MuiOutlinedInput-root.Mui-disabled': {
                    '& fieldset': {
                        border: `2px solid ${PalateColors.neutral.dark}`,
                    },
                },
            },
        },
    },
});
