import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as reducers from './reducers';
import {combineReducers, createStore} from 'redux';
import {Provider} from 'react-redux';
import {history} from './helpers/history';
import {Notification} from './components/Notification';
import {ThemeProvider} from '@material-ui/core';
import {theme} from './theme';
import {initialInterceptor} from './middlewares/axios';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const rootReducer = combineReducers(reducers);
export const store = createStore(rootReducer);
initialInterceptor();

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <App history={history} />
                <Notification />
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
