import {Action} from 'redux';
import {ICoin} from '../types/coin';

export const SET_COIN = 'SET_COIN';
export type SET_COIN = typeof SET_COIN;

export interface ISetCoinAction extends Action<SET_COIN> {
    payload: ICoin[];
}

export const setCoinAction = (payload: ICoin[]): ISetCoinAction => {
    return {
        type: SET_COIN,
        payload,
    };
};
