import {store} from '..';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {ITransaction, IRequestTransaction} from '../types/transaction';
import {
    setTransactionAction,
    setTransactionLoadingAction,
} from '../actions/transaction';
import {fireErrorNotification} from '../helpers/notification';

export const getTransactions = (props: IRequestTransaction) => {
    store.dispatch(setTransactionLoadingAction());
    const {PageSize, PageNumber} = props;
    Axios.get(`/Transaction?PageSize=${PageSize}&PageNumber=${PageNumber}`)
        .then((response: AxiosResponse<ITransaction>) => {
            store.dispatch(setTransactionAction(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
