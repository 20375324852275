import React from 'react';
import {ResponsiveLine, PointTooltipProps, Serie} from '@nivo/line';
import {makeStyles, Box} from '@material-ui/core';
import {PalateColors} from '../../theme';
import {MOBILE_WIDTH} from '../../constants/common';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    lineChartWrapper: {width: '100%', height: 650},
    tooltipWrapper: {
        padding: '9px 20px',
        fontSize: 16,
        fontFamily: 'Poppins',
        color: theme.palette.neutral.white,
        borderRadius: 15.5,
        border: `2px solid ${theme.palette.primary.dark}`,
        backgroundColor: theme.palette.neutral.main,
    },
    tooltipX: {
        textAlign: 'center',
        fontSize: 16,
    },
    tooltipY: {
        textAlign: 'center',
        fontWeight: 600,
    },
}));

interface IProps {
    data: Serie[];
    priceUnit: string;
    coinName: string;
}
const DATE_WIDTH = 110;
export const MiningLineChart = (props: IProps) => {
    const classes = useStyles();
    const {data, priceUnit, coinName} = props;
    const customToolTip = (record: PointTooltipProps) => {
        return (
            <Box className={classes.tooltipWrapper}>
                <Box className={classes.tooltipX}>{`${coinName} mining`}</Box>
                <Box className={classes.tooltipX}>
                    {moment(record.point.data.x).format('DD-MM-YYYY')}
                </Box>
                <Box className={classes.tooltipY}>
                    {record.point.data.y} {priceUnit}
                </Box>
            </Box>
        );
    };

    const isMobile = window.outerWidth <= MOBILE_WIDTH;
    const dataSize = data.length > 0 ? data[0].data.length : 1;
    const widthWidth = window.innerWidth > 1280 ? 1280 : window.innerWidth;
    const availableChartWidth = widthWidth - 90;
    const dateSkip = Math.ceil(dataSize / (availableChartWidth / DATE_WIDTH));
    return (
        <Box className={classes.lineChartWrapper}>
            <ResponsiveLine
                data={data}
                margin={{
                    top: 50,
                    right: 0,
                    bottom: 80,
                    left: isMobile ? 70 : 100,
                }}
                xScale={{
                    type: 'time',
                    precision: 'day',
                    useUTC: false,
                    format: '%Y-%m-%d',
                }}
                yScale={{
                    type: 'linear',
                    min: 0,
                    max: 'auto',
                    stacked: true,
                    reverse: false,
                }}
                xFormat="time:%Y-%m-%d"
                colors={'#5787ff'}
                lineWidth={2}
                pointSize={5}
                pointBorderWidth={4}
                pointLabel="y"
                pointLabelYOffset={-12}
                useMesh={true}
                enableSlices={false}
                enableGridX={false}
                enablePoints={false}
                axisBottom={{
                    format: (tick) => moment(tick).format('DD MMM YYYY'),
                    tickValues: `every ${dateSkip} days`,
                    tickSize: 0,
                    tickPadding: 30,
                }}
                axisLeft={{
                    tickSize: 0,
                    tickPadding: 30,
                }}
                theme={{
                    background: 'transparent',
                    grid: {
                        line: {
                            stroke: PalateColors.neutral.hashrate,
                        },
                    },
                    labels: {
                        text: {
                            fill: PalateColors.neutral.gray,
                        },
                    },
                    axis: {
                        ticks: {
                            text: {
                                fill: PalateColors.neutral.gray,
                                fontSize: isMobile ? 12 : 16,
                                color: PalateColors.neutral.gray,
                                fontStretch: 'normal',
                                letterSpacing: 0.3,
                                lineHeight: 1.5,
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                            },
                        },
                    },
                    crosshair: {
                        line: {
                            stroke: PalateColors.neutral.crosshair,
                            strokeWidth: 1,
                            strokeOpacity: 0.75,
                            strokeDasharray: '6 6',
                        },
                    },
                }}
                tooltip={customToolTip}
            />
        </Box>
    );
};
