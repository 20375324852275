import React, {useState, useEffect} from 'react';
import {Grid, MenuItem, makeStyles, TextField, Box} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {UserProfile} from '../../types/user';
import {
    isValidEmail,
    generateNumberArray,
    daysInMonth,
} from '../../helpers/validate';
import {isEqual, isBoolean} from 'lodash';
import {updateUserProfileMiddleware} from '../../middlewares/user';
import {Checkbox} from '../../components/Checkbox';
import Select from '../../components/SelectBox';
import Button from '../../components/Button';

const DATE_SELECTION = {
    day: generateNumberArray(1, 31),
    month: generateNumberArray(1, 12),
    year: generateNumberArray(
        new Date().getFullYear() - 13,
        new Date().getFullYear() - 100,
    ),
};

const useStyles = makeStyles((theme) => ({
    formGroup: {
        marginBottom: 0,
        width: '100%',
        paddingBottom: '0px !important',
        '& label': {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.neutral.gray,
            fontSize: 12,
            marginBottom: 8,
            display: 'block',
        },
        '& small': {
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.67,
            letterSpacing: 0.38,
            color: theme.palette.semantic.main,
        },
        '& input.Mui-disabled': {
            cursor: 'not-allowed',
        },
        '& svg': {
            color: theme.palette.neutral.lighter,
        },
        '& .checkbox-wrapper': {
            marginTop: 15,
            // backgroundColor: theme.palette.neutral.dark,
            '& label': {
                fontSize: 14,
            },
        },
        '& .MuiFormControlLabel-root': {
            margin: 0,
            padding: 10,
            borderRadius: 4,
            display: 'inline-block',
        },
        '& .submit-button': {
            width: 250,
            marginTop: 27,
            backgroundColor: theme.palette.primary.dark,
        },
    },
    helperText: {
        color: theme.palette.semantic.main,
    },
    selectWrapper: {
        '& .MuiInputBase-root': {
            backgroundColor: `${theme.palette.neutral.dark} !important`,
            height: 48,
        },
    },
}));

export const GeneralInformation = () => {
    const classes = useStyles();
    const {userProfile, currencies} = useSelector((state: StoreState) => state);
    const [user, setUser] = useState<UserProfile | null>(null);
    const [emailError, setEmailError] = useState('');
    const [dayError, setDayError] = useState('');

    useEffect(() => {
        if (userProfile) {
            setUser(userProfile);
        }
        // eslint-disable-next-line
    }, [userProfile]);

    useEffect(() => {
        if (user && daysInMonth(user.month, user.year) < user.day) {
            setDayError('Day is invalid');
        } else {
            setDayError('');
        }
        // eslint-disable-next-line
    }, [user?.day, user?.month, user?.year]);

    const onChangeInput = (key: string) => (event: any, value?: any) => {
        let fieldValue = event.target.value ?? '';

        if (key === 'backupEmail') {
            if (fieldValue === '') {
                fieldValue = undefined;
            }
            setEmailError(
                isValidEmail(fieldValue)
                    ? ''
                    : fieldValue
                    ? 'Email is invalid'
                    : '',
            );
        }
        if (user) {
            setUser({
                ...user,
                [key]: isBoolean(value) ? value : fieldValue,
            });
        }
    };

    const handleSubmit = () => {
        if (user) {
            updateUserProfileMiddleware(user);
        }
    };

    const isDisableSubmit =
        isEqual(user, userProfile) || emailError !== '' || dayError !== '';

    return (
        <Grid container spacing={3}>
            <Grid item md={6} className={classes.formGroup}>
                <label>Email</label>
                <TextField
                    fullWidth
                    type="email"
                    disabled
                    variant="outlined"
                    value={user?.email || ''}
                />
            </Grid>
            <Grid item md={6} className={classes.formGroup}>
                <label>Backup Email</label>
                <TextField
                    fullWidth
                    type="email"
                    variant="outlined"
                    value={user?.backupEmail || ''}
                    error={emailError ? true : false}
                    onChange={onChangeInput('backupEmail')}
                />
                {emailError ? (
                    <small className={classes.helperText}>{emailError}</small>
                ) : null}
            </Grid>
            <Grid item md={6} className={classes.formGroup}>
                <label>First Name</label>
                <TextField
                    fullWidth
                    type="email"
                    variant="outlined"
                    value={user?.firstName || ''}
                    onChange={onChangeInput('firstName')}
                />
            </Grid>
            <Grid item md={6} className={classes.formGroup}>
                <label>Phone Number</label>
                <TextField
                    fullWidth
                    type="email"
                    variant="outlined"
                    value={user?.phoneNumber || ''}
                    onChange={onChangeInput('phoneNumber')}
                />
            </Grid>

            <Grid item md={6} className={classes.formGroup}>
                <label>Last Name</label>
                <TextField
                    fullWidth
                    type="email"
                    variant="outlined"
                    value={user?.lastName || ''}
                    onChange={onChangeInput('lastName')}
                />
            </Grid>
            <Grid item md={6} className={classes.formGroup}>
                <Grid container spacing={3}>
                    <Grid item md={4} className={classes.formGroup}>
                        <label>Day of birth</label>
                        <Box className={classes.selectWrapper}>
                            <Select
                                fullWidth
                                value={user?.day || 1}
                                handleChange={onChangeInput('day')}
                                error={dayError ? true : false}
                            >
                                {DATE_SELECTION.day.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        {dayError ? (
                            <small className={classes.helperText}>
                                {dayError}
                            </small>
                        ) : null}
                    </Grid>
                    <Grid item md={4} className={classes.formGroup}>
                        <label>Month of birth</label>
                        <Box className={classes.selectWrapper}>
                            <Select
                                fullWidth
                                value={user?.month || 1}
                                handleChange={onChangeInput('month')}
                            >
                                {DATE_SELECTION.month.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item md={4} className={classes.formGroup}>
                        <label>Year of birth</label>
                        <Box className={classes.selectWrapper}>
                            <Select
                                fullWidth
                                value={
                                    user?.year || new Date().getFullYear() - 13
                                }
                                handleChange={onChangeInput('year')}
                            >
                                {DATE_SELECTION.year.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} className={classes.formGroup}>
                <label>Currency</label>
                <Box className={classes.selectWrapper}>
                    <Select
                        fullWidth
                        value={user?.currencyId || 0}
                        handleChange={onChangeInput('currencyId')}
                    >
                        <MenuItem value={0}>Select currency</MenuItem>
                        {currencies.map((currency, index) => (
                            <MenuItem key={index} value={currency.id}>
                                {currency.code}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Grid>

            <Grid item xs={12} className={classes.formGroup}>
                <Box className="checkbox-wrapper">
                    <Checkbox
                        label="Receive our Newsletter?"
                        checked={user?.subscribe ?? false}
                        onChange={onChangeInput('subscribe')}
                    />
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                className={classes.formGroup}
                style={{textAlign: 'center'}}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isDisableSubmit}
                    className="submit-button"
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};
