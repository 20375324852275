import {SetUserProfileAction, SET_USER_PROFILE} from '../actions/auth';
import {
    UserProfile,
    IBalance,
    IMiningData,
    IChartMiningReward,
    IChartMiningRewardObject,
    IWallet,
    TwoFactor,
} from '../types/user';
import {
    ISetBalanceAction,
    SET_BALANCE,
    ISetMiningDataAction,
    SET_MINING_DATA,
    ISetMiningRewardAction,
    SET_MINING_REWARDS,
    ISetWalletAction,
    SET_WALLET,
    SET_TWO_FACTOR,
    ISetTwoFactorAction,
    EMPTY_CHART_DATA,
    ISetEmptyChartData,
} from '../actions/user';
import {isEmpty} from 'lodash';
import moment from 'moment';
import {DEFAULT_WALLETS} from '../constants/wallet';

export const userProfile = (
    state: UserProfile | null = null,
    action: SetUserProfileAction,
) => {
    if (action.type === SET_USER_PROFILE) {
        return action.payload;
    }
    return state;
};

export const userBalance = (
    state: IBalance[] = [],
    action: ISetBalanceAction,
) => {
    if (action.type === SET_BALANCE) {
        return action.payload;
    }
    return state;
};

export const userMining = (
    state: IMiningData[] = [],
    action: ISetMiningDataAction,
) => {
    if (action.type === SET_MINING_DATA) {
        return action.payload;
    }
    return state;
};

const initMiningRewardChartData: IChartMiningReward = {
    isLoading: true,
    data: [],
};

export const userMiningRewards = (
    state: IChartMiningReward = initMiningRewardChartData,
    action: ISetMiningRewardAction | ISetEmptyChartData,
) => {
    if (action.type === SET_MINING_REWARDS) {
        const newState = [...state.data];
        const chartIndex = newState.findIndex(
            (chartData) => chartData.coinCode === action.payload.coinCode,
        );
        ///////
        const {coinCode, startDate, endDate, currency, range} = action.payload;
        const miningChart: IChartMiningRewardObject = {
            coinCode,
            startDate,
            endDate,
            coinId: '',
            coinName: '',
            priceUnit: '',
            range,
            data: [],
        };

        /////////////////// FORMAT CHART DATA /////////////////////
        action.payload.data.forEach((rawChartData) => {
            /////
            if (isEmpty(miningChart.data)) {
                miningChart.coinName = rawChartData.coin.name;
                miningChart.priceUnit = currency?.code ?? 'USD';
                miningChart.data.push({
                    id: rawChartData.coin.name,
                    data: [],
                });
            }

            let miningPrice = rawChartData.reward * rawChartData.rateByUsd;
            if (currency) {
                miningPrice = miningPrice * currency.priceByUsd;
            }
            miningChart.data[0].data.push({
                x: moment(rawChartData.createAt).format('Y-MM-DD'),
                y: Number(miningPrice.toFixed(2)),
            });
        });
        /////////////////// FORMAT CHART DATA /////////////////////
        if (chartIndex !== -1) {
            newState[chartIndex] = miningChart;
            return {
                isLoading: false,
                data: newState,
            };
        }
        ///// add new state
        newState.push(miningChart);
        return {
            isLoading: false,
            data: newState,
        };
    }

    if (action.type === EMPTY_CHART_DATA) {
        return {
            ...state,
            isLoading: false,
        };
    }

    return state;
};

export const userWallets = (
    state: IWallet[] = [],
    action: ISetWalletAction,
) => {
    if (action.type === SET_WALLET) {
        if (isEmpty(action.payload)) {
            return DEFAULT_WALLETS;
        }
        return action.payload;
    }
    return state;
};

export const userTwoFactor = (
    state: TwoFactor | null = null,
    action: ISetTwoFactorAction,
) => {
    if (action.type === SET_TWO_FACTOR) {
        return action.payload;
    }
    return state;
};
