import {store} from '..';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {IPlan} from '../types/plan';
import {setPlanAction} from '../actions/plan';
import {
    fireErrorNotification,
    // fireSuccessNotification,
} from '../helpers/notification';

export const getPlan = () => {
    Axios.get('/Plan')
        .then((response: AxiosResponse<IPlan[]>) => {
            store.dispatch(setPlanAction(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
