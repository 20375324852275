import React, {useEffect, useState} from 'react';
import {
    Grid,
    Typography,
    Box,
    makeStyles,
    TextField,
    Link,
} from '@material-ui/core';
import {PATH} from '../../constants/path';
import {pushTo} from '../../helpers/history';
import {IWallet} from '../../types/user';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {updateUserWalletsMiddleware} from '../../middlewares/user';
import GoBackIcon from '../../assets/images/icons/angle-left.png';
import EtherIcon from '../../assets/images/ethereum.svg';
import {PageLayout} from '../../components/PageLayout';
import Button from '../../components/Button';
import {MOBILE_WIDTH} from '../../constants/common';

const useStyles = makeStyles((theme) => ({
    addressTitle: {
        marginTop: 60,
        marginBottom: 24,
        '& .wrapper': {
            position: 'relative',
            '& .title': {
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: 32,
                fontWeight: 'bold',
                letterSpacing: 0.8,
                color: theme.palette.neutral.white,
                '&.mobile': {
                    fontSize: 24,
                },
            },
            '& .go-back': {
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 20,
                left: 15,
                '& .img': {
                    marginRight: 8,
                },
                '& span': {
                    fontSize: 14,
                    fontWeight: 500,
                    letterSpacing: 0.24,
                    lineHeight: 1.57,
                    color: theme.palette.neutral.white,
                },
                '&.mobile': {
                    top: -30,
                },
            },
        },
    },
    addressManagement: {
        '& .wrapper': {
            borderRadius: 4,
            backgroundColor: theme.palette.neutral.hashrate,
            padding: '41px 14px 125px',
            minHeight: 400,
            position: 'relative',
            '& .address-wrapper': {
                display: 'inline-block',
                margin: '0 10px 40px',
                width: 'calc(33.33% - 20px)',
                '& .address-item': {
                    padding: '24px 32px',
                    borderRadius: 4,
                    border: `2px solid ${theme.palette.primary.dark}`,
                    backgroundColor: theme.palette.neutral.dark,
                    marginBottom: 8,
                    '& .top': {
                        marginBottom: 16,
                        paddingBottom: 16,
                        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
                        display: 'flex',
                        '& .coin-icon': {
                            width: 32,
                            height: 32,
                            marginRight: 12,
                        },
                        '& .text-1': {
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: 1.88,
                            letterSpacing: 0.4,
                            color: theme.palette.neutral.white,
                        },
                    },
                    '& .form-group': {
                        width: '100%',
                        '& label': {
                            fontFamily: 'Poppins',
                            fontWeight: 500,
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 1.67,
                            letterSpacing: 0.38,
                            color: theme.palette.neutral.gray,
                            fontSize: 12,
                            marginBottom: 8,
                            display: 'block',
                        },
                        '& .MuiTextField-root input': {
                            backgroundColor: theme.palette.neutral.main,
                        },
                        '& .MuiTextField-root fieldset': {
                            borderColor: theme.palette.neutral.main,
                        },
                    },
                },
                '& .text-2': {
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: 1.57,
                    letterSpacing: 0.24,
                    color: theme.palette.neutral.gray,
                    marginBottom: 2,
                },
                '& .link': {
                    textDecoration: 'underline',
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: 1.71,
                    letterSpacing: 0.44,
                },
                '&.mobile': {
                    width: '100%',
                    margin: '0 0 40px',
                },
            },
            '& .action-wrapper': {
                marginTop: 32,
                textAlign: 'center',
                position: 'absolute',
                width: '100%',
                bottom: 48,
                '& .save-btn': {
                    backgroundColor: theme.palette.primary.dark,
                    width: 200,
                    height: 44,
                },
            },
        },
    },
}));

export const Wallets = () => {
    const classes = useStyles();
    const {userWallets} = useSelector((state: StoreState) => state);
    const [wallets, setWallets] = useState<IWallet[]>(userWallets);
    const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
    useEffect(() => {
        if (userWallets) {
            setWallets(userWallets);
        }
    }, [userWallets]);

    const onChangeAddress = (index: number, event: React.ChangeEvent<any>) => {
        const newWallets = [...wallets];
        newWallets[index] = {
            ...newWallets[index],
            address: event.target.value,
        };
        setDisabledSaveBtn(false);
        setWallets([...newWallets]);
    };

    const handleSubmit = () => {
        updateUserWalletsMiddleware(wallets);
    };

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <PageLayout>
            <Grid container spacing={3} className={classes.addressTitle}>
                <Grid item xs={12} className="wrapper">
                    <Typography
                        variant="h2"
                        className={`title ${isMobile ? 'mobile' : ''}`}
                    >
                        Address Managements
                    </Typography>
                    <Typography
                        className={`go-back ${isMobile ? 'mobile' : ''}`}
                        onClick={() => pushTo(PATH.myWallet)}
                    >
                        <img src={GoBackIcon} alt="go back to my wallet" />
                        <span>My Wallets</span>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.addressManagement}>
                <Grid item xs={12}>
                    <Box className="wrapper">
                        {wallets.map((wallet, index) => (
                            <Box
                                className={`address-wrapper ${
                                    isMobile ? 'mobile' : ''
                                }`}
                                key={index}
                            >
                                <Box className="address-item">
                                    <Box className="top">
                                        <img
                                            src={wallet.coin.image ?? EtherIcon}
                                            alt="coin icon"
                                            className="coin-icon"
                                        />
                                        <Typography className="text-1">
                                            {wallet.coin.name} Wallet
                                        </Typography>
                                    </Box>
                                    <Box className="form-group">
                                        <label>Address</label>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            value={wallet.address}
                                            onChange={(e) =>
                                                onChangeAddress(index, e)
                                            }
                                            placeholder="Input wallet address"
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography className="text-2">
                                        You don't have a {wallet.coin.name}{' '}
                                        wallet yet?
                                    </Typography>
                                    <Link
                                        href={wallet.coin.createWalletUrl}
                                        target="_blank"
                                        className="link"
                                    >
                                        Click here to get started!
                                    </Link>
                                </Box>
                            </Box>
                        ))}
                        <Box className="action-wrapper">
                            <Button
                                disabled={disabledSaveBtn}
                                className="save-btn"
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </PageLayout>
    );
};
