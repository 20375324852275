import React from 'react';
import {makeStyles, Select, Input} from '@material-ui/core';
import DropdownIcon from '../assets/images/icons/dropdown.png';
const useStyles = makeStyles((theme) => ({
    selectBox: {
        fontSize: 16,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0.3,
        color: theme.palette.neutral.white,
        textTransform: 'none',
        backgroundColor: `${theme.palette.neutral.hashrate}`,
        padding: '7px 15px',
        minWidth: 120,
        justifyContent: 'space-between',
        borderRadius: 4,
        backgroundImage: `url(${DropdownIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '95%',
        '& .MuiSelect-iconOutlined': {
            display: 'none',
        },
        '& select': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
        },
    },
    paperProps: {
        maxHeight: 208,
        width: 203,
        backgroundColor: theme.palette.neutral.hashrate,
        border: `2px solid ${theme.palette.neutral.lighter}`,
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.neutral.darker,
            width: 9,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.neutral.darker,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.neutral.lighter,
            borderRadius: 15,
            boxShadow: `inset -2px 0px 0px ${theme.palette.neutral.darker}, inset 2px 1px 0px ${theme.palette.neutral.darker}`,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.neutral.lighter,
        },
        '& .MuiMenuItem-root': {
            color: theme.palette.neutral.white,
            '&.Mui-selected': {
                backgroundColor: theme.palette.neutral.dark,
            },
            '&:hover': {
                backgroundColor: theme.palette.neutral.dark,
            },
        },
    },
}));

interface IProps {
    value: any;
    handleChange?: (event: React.ChangeEvent<any>) => void;
    children: any;
    error?: any;
    fullWidth?: boolean;
}

const SelectBox = (props: IProps) => {
    const {value, handleChange, children, error, fullWidth} = props;
    const classes = useStyles();
    return (
        <Select
            fullWidth={fullWidth}
            value={value}
            onChange={handleChange}
            input={<Input error={error} disableUnderline={true} />}
            className={classes.selectBox}
            MenuProps={{PaperProps: {className: classes.paperProps}}}
            variant="outlined"
        >
            {children}
        </Select>
    );
};

export default SelectBox;
