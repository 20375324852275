import React from 'react';

import {Box, Typography, makeStyles} from '@material-ui/core';
import HashPowerIcon from '../../assets/images/icons/hashpower.png';
import BillingIcon from '../../assets/images/icons/billing-info.png';
import ConfirmationIcon from '../../assets/images/icons/panel.png';
import {MOBILE_WIDTH, IPAD_WIDTH} from '../../constants/common';

const useStyles = makeStyles((theme) => ({
    headingWrapper: {
        backgroundColor: theme.palette.neutral.purple3,
        marginTop: 50,
        marginBottom: 16,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        boxShadow: '0 0 5px 0 #0d003e',
        borderRadius: 4,
        '& .item': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 56,
            position: 'relative',
            '&.active': {
                backgroundColor: theme.palette.primary.dark,
                '&:after': {
                    content: "''",
                    position: 'absolute',
                    top: 8,
                    right: -21,
                    height: 40,
                    width: 40,
                    boxShadow: '1px -1px 1px rgba(0,0,0,0.5)',
                    backgroundColor: theme.palette.primary.dark,
                    transform: 'rotate(45deg)',
                    '-ms-transform': 'rotate(45deg)',
                },
                '&:last-child': {
                    '&:after': {
                        content: 'none',
                    },
                },
            },
            '&:after': {
                content: "''",
                position: 'absolute',
                top: 8,
                right: -21,
                height: 40,
                width: 40,
                boxShadow: '1px -1px 1px rgb(0 0 0)',
                backgroundColor: theme.palette.neutral.purple3,
                transform: 'rotate(45deg)',
                '-ms-transform': 'rotate(45deg)',
                zIndex: 2,
            },
            '&:before': {
                content: "''",
                position: 'absolute',
                top: 8,
                left: -21,
                height: 40,
                width: 40,
                boxShadow: '1px -1px 1px rgb(0 0 0)',
                transform: 'rotate(45deg)',
                '-ms-transform': 'rotate(45deg)',
                zIndex: 1,
            },
            '&:first-child': {
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
                '&:before': {
                    content: 'none',
                },
            },
            '&:last-child': {
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                '&:after': {
                    content: 'none',
                },
            },

            '& img': {
                marginRight: 12,
                width: 24,
                height: 24,
            },
            '& p': {
                fontSize: 14,
                fontWeight: 500,
                lineHeight: 1.57,
                letterSpacing: 0.24,
                color: theme.palette.neutral.white,
            },
        },
    },
    mobileHeadingWrapper: {
        backgroundColor: theme.palette.neutral.purple3,
        marginTop: 24,
        marginBottom: 16,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        boxShadow: '0 0 5px 0 #0d003e',
        borderRadius: 4,
        '& .item': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 32,
            position: 'relative',
            '&.active': {
                backgroundColor: theme.palette.primary.dark,
                '&:after': {
                    content: "''",
                    position: 'absolute',
                    top: 7,
                    right: -8,
                    height: 17,
                    width: 17,
                    boxShadow: '1px -1px 1px rgba(0,0,0,0.5)',
                    backgroundColor: theme.palette.primary.dark,
                    transform: 'rotate(45deg) skew(20deg, 20deg)',
                    '-ms-transform': 'rotate(45deg) skew(20deg, 20deg)',
                },
                '&:last-child': {
                    '&:after': {
                        content: 'none',
                    },
                },
            },
            '&:after': {
                content: "''",
                position: 'absolute',
                top: 7,
                right: -8,
                height: 17,
                width: 17,
                boxShadow: '1px -1px 1px rgb(0 0 0)',
                backgroundColor: theme.palette.neutral.purple3,
                transform: 'rotate(45deg) skew(20deg, 20deg)',
                '-ms-transform': 'rotate(45deg) skew(20deg, 20deg)',
                zIndex: 2,
            },
            '&:before': {
                content: "''",
                position: 'absolute',
                top: 7,
                left: -9,
                height: 17,
                width: 17,
                boxShadow: '1px -1px 1px rgb(0 0 0)',
                transform: 'rotate(45deg) skew(20deg, 20deg)',
                '-ms-transform': 'rotate(45deg) skew(20deg, 20deg)',
                zIndex: 1,
            },
            '&:first-child': {
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
                '&:before': {
                    content: 'none',
                },
            },
            '&:last-child': {
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                '&:after': {
                    content: 'none',
                },
            },

            '& img': {
                display: 'none',
            },
            '& p': {
                fontSize: 12,
                fontWeight: 500,
                lineHeight: 1.57,
                letterSpacing: 0.24,
                color: theme.palette.neutral.white,
            },
        },
    },
}));

interface IProps {
    value: number;
}

export const HeadingTab = (props: IProps) => {
    const {value} = props;
    const classes = useStyles();
    const isMobile = window.outerWidth <= MOBILE_WIDTH;
    const isIpad = window.outerWidth <= IPAD_WIDTH;

    return (
        <Box
            className={
                isMobile ? classes.mobileHeadingWrapper : classes.headingWrapper
            }
        >
            <Box className={`item ${value === 1 ? 'active' : ''}`}>
                <img src={HashPowerIcon} alt="heading icon" />
                <Typography>
                    {isIpad ? 'Select' : 'Select Hashpower'}
                </Typography>
            </Box>
            <Box className={`item ${value === 2 ? 'active' : ''}`}>
                <img src={BillingIcon} alt="heading icon" />
                <Typography>Review</Typography>
            </Box>
            <Box className={`item ${value === 3 ? 'active' : ''}`}>
                <img src={BillingIcon} alt="heading icon" />
                <Typography>Payment</Typography>
            </Box>
            <Box className={`item ${value === 4 ? 'active' : ''}`}>
                <img src={ConfirmationIcon} alt="heading icon" />
                <Typography>{isIpad ? 'Confirm' : 'Confirmation'}</Typography>
            </Box>
        </Box>
    );
};
