import {Action} from 'redux';
import {
    IBalance,
    IMiningData,
    IMiningChart,
    IWallet,
    TwoFactor,
} from '../types/user';

export const SET_BALANCE = 'SET_BALANCE';
export type SET_BALANCE = typeof SET_BALANCE;

export interface ISetBalanceAction extends Action<SET_BALANCE> {
    payload: IBalance[];
}

export const setBalanceAction = (payload: IBalance[]): ISetBalanceAction => {
    return {
        type: SET_BALANCE,
        payload,
    };
};

////////////////////////////////////////////////////////
export const SET_MINING_DATA = 'SET_MINING_DATA';
export type SET_MINING_DATA = typeof SET_MINING_DATA;

export interface ISetMiningDataAction extends Action<SET_MINING_DATA> {
    payload: IMiningData[];
}

export const setMiningDataAction = (
    payload: IMiningData[],
): ISetMiningDataAction => {
    return {
        type: SET_MINING_DATA,
        payload,
    };
};
////////////////////////////////////////////////////////
export const SET_MINING_REWARDS = 'SET_MINING_REWARDS';
export type SET_MINING_REWARDS = typeof SET_MINING_REWARDS;

export interface ISetMiningRewardAction extends Action<SET_MINING_REWARDS> {
    payload: IMiningChart;
}

export const setMiningRewardAction = (
    payload: IMiningChart,
): ISetMiningRewardAction => {
    return {
        type: SET_MINING_REWARDS,
        payload,
    };
};

export const EMPTY_CHART_DATA = 'EMPTY_CHART_DATA';
export type EMPTY_CHART_DATA = typeof EMPTY_CHART_DATA;
export interface ISetEmptyChartData extends Action<EMPTY_CHART_DATA> {}
export const setEmptyChartData = (): ISetEmptyChartData => {
    return {
        type: EMPTY_CHART_DATA,
    };
};
////////////////////////////////////////////////////////
export const SET_WALLET = 'SET_WALLET';
export type SET_WALLET = typeof SET_WALLET;

export interface ISetWalletAction extends Action<SET_WALLET> {
    payload: IWallet[];
}

export const setWalletAction = (payload: IWallet[]): ISetWalletAction => {
    return {
        type: SET_WALLET,
        payload,
    };
};

////////////////////////////////////////////////////////
export const SET_TWO_FACTOR = 'SET_TWO_FACTOR';
export type SET_TWO_FACTOR = typeof SET_TWO_FACTOR;

export interface ISetTwoFactorAction extends Action<SET_TWO_FACTOR> {
    payload: TwoFactor | null;
}

export const setTwoFactorAction = (
    payload: TwoFactor | null,
): ISetTwoFactorAction => {
    return {
        type: SET_TWO_FACTOR,
        payload,
    };
};
