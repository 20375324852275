import React from 'react';
import {Grid, makeStyles, Container, Box} from '@material-ui/core';
import LogoImg from '../assets/images/logo.png';
import BannerImage from '../assets/images/crypto-banner.png';
import CheckIconImg from '../assets/images/check-icon.png';
import AngleLeftImg from '../assets/images/icons/angle-left.png';
import {MOBILE_WIDTH} from '../constants/common';
import {PATH} from '../constants/path';
import {pushTo} from '../helpers/history';
import {useHistory} from 'react-router-dom';
interface Props {
    style?: React.CSSProperties;
    children: any;
}

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
        backgroundColor: theme.palette.neutral.main,
        flexWrap: 'initial',
        '& .MuiGrid-grid-md-4': {
            width: '100%',
        },
        '& .MuiGrid-grid-md-6': {
            width: '100%',
        },
        '& .MuiGrid-grid-md-3': {
            width: '100%',
        },
        '& .mb100': {
            marginBottom: 100,
        },
    },
    header: {
        height: 88,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 32,
        '& .logoImg': {
            height: 36,
        },
        '& .backIcon': {
            height: 36,
            marginRight: 12,
            cursor: 'pointer',
        },
    },
    contentLeft: {
        padding: '61px 0',
        display: 'flex',
    },
    bannerImg: {
        maxWidth: 372,
        width: '100%',
    },
    banner2ndImg: {
        maxWidth: 326,
        width: '100%',
        margin: '40px 0',
    },
    cryptoLetter: {
        '& ul': {
            padding: 0,
            margin: 0,
            listStyleType: 'none',
            '& li': {
                color: theme.palette.neutral.gray,
                marginBottom: 30,
                display: 'flex',
                alignItems: 'center',
                '& img': {
                    width: 31,
                    marginRight: 18,
                },
            },
        },
    },
    autoMargin: {
        margin: '0 auto',
    },
}));

export const AuthLayout = (props: Props) => {
    const classes = useStyles();
    const isMobile = window.outerWidth <= MOBILE_WIDTH;
    const history = useHistory();
    const CurrentLocation = history.location.pathname;

    const goBackToLogin = () => {
        pushTo(PATH.login);
    };

    return (
        <Box className={classes.container}>
            <Box
                className={classes.header}
                style={{
                    paddingLeft: isMobile
                        ? 0
                        : CurrentLocation !== PATH.login
                        ? 32
                        : 76,
                }}
            >
                {CurrentLocation !== PATH.login ? (
                    <img
                        src={AngleLeftImg}
                        alt="Expert mining back icon"
                        className="backIcon"
                        style={{display: isMobile ? 'none' : 'initial'}}
                        onClick={goBackToLogin}
                    />
                ) : null}
                <img
                    src={LogoImg}
                    alt="Expert mining"
                    className="logoImg"
                    style={{margin: isMobile ? 'auto' : 'initial'}}
                />
            </Box>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid
                        item
                        md={6}
                        style={{display: isMobile ? 'none' : 'block'}}
                    >
                        <Box className={classes.contentLeft}>
                            <Box className={classes.autoMargin}>
                                <Box>
                                    <img
                                        src={BannerImage}
                                        alt="Expert mining banner"
                                        className={classes.bannerImg}
                                    />
                                </Box>
                                <Box>
                                    <img
                                        src={LogoImg}
                                        alt="Expert mining logo"
                                        className={classes.banner2ndImg}
                                    />
                                </Box>
                                <Box className={classes.cryptoLetter}>
                                    <ul>
                                        <li>
                                            <img
                                                src={CheckIconImg}
                                                alt="Expert mining"
                                            />
                                            We've got the fastest bitcoint
                                            mining for you!
                                        </li>
                                        <li>
                                            <img
                                                src={CheckIconImg}
                                                alt="Expert mining"
                                            />
                                            Mining alternative cryptocurrency
                                        </li>
                                        <li>
                                            <img
                                                src={CheckIconImg}
                                                alt="Expert mining"
                                            />
                                            Get your first mining results today
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        {props.children}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
