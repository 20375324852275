import {Action} from 'redux';
import {UserProfile} from '../types/user';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export type SET_USER_PROFILE = typeof SET_USER_PROFILE;

export interface SetUserProfileAction extends Action<SET_USER_PROFILE> {
    payload: UserProfile;
}

export const setUserProfileAction = (
    payload: UserProfile,
): SetUserProfileAction => {
    return {
        type: SET_USER_PROFILE,
        payload,
    };
};
