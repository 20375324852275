import {store} from '..';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {IPaginationRequest} from '../types/pagination';
import {IWithdrawRequest, IWithdrawHistory} from '../types/withdraw';
import {
    setWithdrawHistories,
    setWithdrawLoadingAction,
} from '../actions/withdraw';
import {
    fireSuccessNotification,
    fireErrorNotification,
} from '../helpers/notification';
import {getBalances} from './user';

export const getTransactions = (params: IPaginationRequest) => {
    store.dispatch(setWithdrawLoadingAction());
    const {PageSize, PageNumber} = params;
    Axios.get(
        `/Withdraw/GetWithdraw?PageSize=${PageSize}&PageNumber=${PageNumber}`,
    )
        .then((response: AxiosResponse<IWithdrawHistory>) => {
            store.dispatch(setWithdrawHistories(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const proceedwithdraw = (params: IWithdrawRequest) => {
    return new Promise((resolve, reject) => {
        Axios.post('/Withdraw', params)
            .then((response: AxiosResponse<{message: string}>) => {
                fireSuccessNotification(response.data.message);
                getBalances();
                resolve(true);
            })
            .catch((error: AxiosError) => {
                fireErrorNotification(error);
                reject(error);
            });
    });
};
