import React, {useState, useEffect} from 'react';
import {PageLayout} from '../../components/PageLayout';
import Table from '../../components/Table';
import {Grid, makeStyles, Box, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {getRefferalRewards} from '../../middlewares/referral';
import moment from 'moment';
import {MOBILE_WIDTH} from '../../constants/common';

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        '& .title': {
            fontSize: 32,
            fontWeight: 'bold',
            letterSpacing: 0.6,
            color: theme.palette.neutral.white,
            margin: '58px 0 24px',
            '&.mobile': {
                fontSize: 24,
            },
        },
    },
}));

export const Referral = () => {
    const classes = useStyles();
    const {referralRewards} = useSelector((state: StoreState) => state);
    const [columns] = useState([
        {
            name: 'createAt',
            title: 'Order Create',
            width: '50%',
            renderer: (_record: any, value: string) =>
                moment(value).format('LLLL'),
        },
        {
            name: 'reward',
            title: 'Rewards',
            width: '50%',
            renderer: (record: any, _value: number) => {
                return `${(record.reward * record.rateByUsd).toFixed(2)} USD`;
            },
        },
    ]);

    useEffect(() => {
        getRefferalRewards();
    }, []);

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <PageLayout>
            <Grid container spacing={3} className={classes.contentWrapper}>
                <Grid item xs={12}>
                    <Box>
                        <Typography
                            className={`title ${isMobile ? 'mobile' : ''}`}
                            variant="h1"
                        >
                            My referral orders
                        </Typography>
                    </Box>
                    <Box>
                        <Table
                            columns={columns}
                            data={referralRewards.data}
                            isLoading={referralRewards.isLoading}
                            dynamicHeight
                            noDataTitle="No referral rewards so far"
                        />
                    </Box>
                </Grid>
            </Grid>
        </PageLayout>
    );
};
