import {store} from '..';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {
    IOrder,
    ICreateOrder,
    IUserOrderData,
    IUserOrderPagination,
} from '../types/order';
import {IPaginationRequest} from '../types/pagination';
import {
    setOrderAction,
    setPendingOrdersAction,
    setActiveOrdersAction,
    setEndedOrdersAction,
    setPendingOrdersLoadingAction,
    setActiveOrdersLoadingAction,
    setEndedOrdersLoadingAction,
} from '../actions/order';
import {fireErrorNotification} from '../helpers/notification';

export const createOrder = (payload: ICreateOrder) => {
    return new Promise((resolve: (data: IOrder) => void, reject) => {
        Axios.post('/Order/ReviewOrder', payload)
            .then((response: AxiosResponse<{data: IOrder}>) => {
                resolve(response.data.data);
            })
            .catch((error: AxiosError) => {
                fireErrorNotification(error.response?.data?.message);
                reject(error);
            });
    });
};

export const getOrder = (payload: {orderId: string}) => {
    Axios.get(`/Order/${payload.orderId}`)
        .then((response: AxiosResponse<IOrder>) => {
            store.dispatch(setOrderAction(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const getPendingOrders = () => {
    store.dispatch(setPendingOrdersLoadingAction());
    Axios.get(`/Order/PendingOrders`)
        .then((response: AxiosResponse<IUserOrderData[]>) => {
            store.dispatch(setPendingOrdersAction(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
export const getActiveOrders = (props: IPaginationRequest) => {
    store.dispatch(setActiveOrdersLoadingAction());
    const {PageSize, PageNumber} = props;
    Axios.get(
        `/Order/ActiveOrders?PageSize=${PageSize}&PageNumber=${PageNumber}`,
    )
        .then((response: AxiosResponse<IUserOrderPagination>) => {
            store.dispatch(setActiveOrdersAction(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
export const getEndedOrders = (props: IPaginationRequest) => {
    store.dispatch(setEndedOrdersLoadingAction());
    const {PageSize, PageNumber} = props;
    Axios.get(
        `/Order/EndedOrders?PageSize=${PageSize}&PageNumber=${PageNumber}`,
    )
        .then((response: AxiosResponse<IUserOrderPagination>) => {
            store.dispatch(setEndedOrdersAction(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const checkPromo = (promoCode: string) => {
    return new Promise((resolve: (data: any) => void, reject) => {
        Axios.get(`/Order/check/${promoCode}`)
            .then(
                (
                    response: AxiosResponse<{
                        data: {check: boolean; rate: number};
                    }>,
                ) => {
                    resolve(response.data.data);
                },
            )
            .catch((error: AxiosError) => {
                fireErrorNotification(
                    error.response?.data?.message ?? 'Something went wrong',
                );
                reject(error);
            });
    });
};
