import {store} from '..';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {IReferralRewardData} from '../types/referral';
import {
    setReferralRewardAction,
    setReferralLoadingAction,
} from '../actions/referral';
import {fireErrorNotification} from '../helpers/notification';

export const getRefferalRewards = () => {
    store.dispatch(setReferralLoadingAction());
    Axios.get('/User/RefferalRewards')
        .then((response: AxiosResponse<{data: IReferralRewardData[]}>) => {
            store.dispatch(setReferralRewardAction(response.data.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
