import React from 'react';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Typography,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import {isFunction, isEmpty, isString} from 'lodash';
import {IPagination} from '../types/pagination';
import EmptyDataImg from '../assets/images/empty-table-data.png';
import {MOBILE_WIDTH} from '../constants/common';

const useStyles = makeStyles((theme) => ({
    tableOverlay: {
        padding: '16px 0',
        backgroundColor: theme.palette.neutral.hashrate,
    },
    tableWrapper: {
        width: '100%',
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.neutral.hashrate,
        borderRadius: 4,
        borderTop: `1px solid ${theme.palette.neutral.purple3}`,
    },
    tableContainer: {
        boxShadow: 'none',
    },
    tableHeadCell: {
        padding: 25,
        fontSize: 12,
        border: 'none',
        color: theme.palette.neutral.lighter,
        fontFamily: 'Poppins',
        fontWeight: 500,
        lineHeight: 1.67,
        letterSpacing: 0.38,
    },
    tableValueCell: {
        padding: 25,
        fontSize: 14,
        color: theme.palette.neutral.white,
        border: 'none',
        fontFamily: 'Poppins',
        fontWeight: 500,
        lineHeight: 1.57,
        letterSpacing: 0.24,
        '& a': {
            color: theme.palette.primary.main,
        },
    },
    tableHeadRow: {
        background: theme.palette.neutral.hashrate,
    },
    tableBodyRow: {
        background: theme.palette.neutral.hashrate,
        '&:nth-child(2n+1)': {
            background: theme.palette.neutral.timber,
        },
    },
    textCenter: {
        textAlign: 'center',
    },
    noDataTitle: {
        fontSize: 16,
        fontWeight: 500,
        margin: '28px 0 33px',
    },
    noDataImg: {
        maxWidth: 234,
        width: '100%',
        // marginRight: 70,
    },
    paginator: {
        backgroundColor: theme.palette.neutral.purple3,
        height: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& li': {
            '& button': {
                backgroundColor: theme.palette.neutral.dark,
                color: theme.palette.neutral.white,
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 1.43,
                letterSpacing: 0.34,
                minWidth: 24,
                height: 24,
                '&.Mui-selected': {
                    backgroundColor: theme.palette.primary.main,
                },
            },
        },
    },
}));

interface IProps {
    columns: IColumn[];
    data: any;
    hideIndex?: boolean;
    noDataTitle?: string;
    noDataImg?: any;
    pageInfo?: IPagination;
    isLoading?: boolean;
    dynamicHeight?: boolean;
    onChangePage?: (page: number) => void;
}
interface IColumn {
    name: string;
    title: string;
    renderer?: (record: any, value: any) => any;
    width?: string | number;
}

const CustomTable = (props: IProps) => {
    const {
        columns,
        data,
        hideIndex,
        noDataTitle,
        noDataImg,
        pageInfo,
        onChangePage,
        dynamicHeight,
        isLoading,
    } = props;
    const classes = useStyles();

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    if (isEmpty(data) && isString(noDataTitle) && !isLoading) {
        return (
            <Box>
                <Table>
                    <TableHead>
                        <TableRow className={classes.tableHeadRow}>
                            {!hideIndex ? (
                                <TableCell className={classes.tableHeadCell}>
                                    #
                                </TableCell>
                            ) : null}
                            {columns.map((column, index) => (
                                <TableCell
                                    className={classes.tableHeadCell}
                                    key={index}
                                    width={column.width}
                                >
                                    {column.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                </Table>
                <Box className={classes.tableWrapper}>
                    <Box className={classes.textCenter}>
                        <Box>
                            <img
                                src={noDataImg ?? EmptyDataImg}
                                alt="no data title"
                                className={classes.noDataImg}
                            />
                            <Typography className={classes.noDataTitle}>
                                {noDataTitle}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
    return (
        <Box className={classes.tableOverlay}>
            <TableContainer
                className={classes.tableContainer}
                style={{minHeight: dynamicHeight ? 'auto' : 780}}
            >
                <Table>
                    <TableHead>
                        <TableRow className={classes.tableHeadRow}>
                            {!hideIndex ? (
                                <TableCell className={classes.tableHeadCell}>
                                    #
                                </TableCell>
                            ) : null}
                            {columns.map((column, index) => (
                                <TableCell
                                    className={classes.tableHeadCell}
                                    key={index}
                                    width={column.width}
                                >
                                    {column.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading
                            ? Array.from({length: 10}).map(
                                  (_num: any, index: number) => (
                                      <TableRow
                                          className={classes.tableBodyRow}
                                          key={index}
                                      >
                                          <LoadingCell
                                              classes={classes}
                                              numOfRowSpan={
                                                  columns.length +
                                                  (hideIndex ? 0 : 1)
                                              }
                                          />
                                      </TableRow>
                                  ),
                              )
                            : data.map((record: any, index: number) => (
                                  <TableRow
                                      className={classes.tableBodyRow}
                                      key={index}
                                  >
                                      {!hideIndex ? (
                                          <TableCell
                                              className={classes.tableValueCell}
                                          >
                                              {pageInfo
                                                  ? pageInfo.pageSize *
                                                        (pageInfo.currentPage -
                                                            1) +
                                                    (index + 1)
                                                  : index + 1}
                                          </TableCell>
                                      ) : null}

                                      {columns.map((column, columnIndex) => (
                                          <TableCell
                                              className={classes.tableValueCell}
                                              key={columnIndex}
                                              style={{
                                                  whiteSpace: isMobile
                                                      ? 'nowrap'
                                                      : 'normal',
                                              }}
                                          >
                                              {isFunction(column.renderer)
                                                  ? column.renderer(
                                                        record,
                                                        record[column.name],
                                                    )
                                                  : record[column.name]}
                                          </TableCell>
                                      ))}
                                  </TableRow>
                              ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {pageInfo && onChangePage ? (
                <Pagination
                    shape="rounded"
                    className={classes.paginator}
                    count={pageInfo.totalPages}
                    page={pageInfo.currentPage}
                    onChange={(_event: React.ChangeEvent<any>, page: number) =>
                        onChangePage(page)
                    }
                    showFirstButton
                    showLastButton
                />
            ) : null}
        </Box>
    );
};

export default CustomTable;

interface LoadingProps {
    classes: any;
    numOfRowSpan: number;
}

const LoadingCell = (props: LoadingProps) => {
    const {classes, numOfRowSpan} = props;
    return (
        <TableCell
            className={classes.tableValueCell}
            colSpan={numOfRowSpan}
            style={{padding: 0}}
        >
            <Skeleton
                variant="rect"
                height={71}
                style={{backgroundColor: `rgb(116 108 146 / 15%)`}}
            />
        </TableCell>
    );
};
