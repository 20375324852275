import React, {useRef, useState, useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {
    Grid,
    AppBar,
    Toolbar,
    MenuItem,
    makeStyles,
    Box,
    Container,
    Typography,
    Fade,
    SwipeableDrawer,
    Divider,
} from '@material-ui/core';
import Dropdown from './Dropdown';
import Button from './Button';
import Modal from './Modal';
////
import {PATH} from '../constants/path';
import {MOBILE_WIDTH} from '../constants/common';
import {pushTo} from '../helpers/history';
import {useSelector} from 'react-redux';
import {StoreState} from '../types/store-state';
import {truncate} from 'lodash';
//////
import LogoImg from '../assets/images/logo.png';
import LogoIcon from '../assets/images/icons/logo.png';
import LogoMinifyImg from '../assets/images/minify-logo.png';
import HamburgerIcon from '../assets/images/icons/hamburger-menu.png';
import CloseIcon from '../assets/images/icons/close.png';
import DashboardIcon from '../assets/images/icons/dashboard.png';
import ActivityIcon from '../assets/images/icons/activities.png';
import MyWalletIcon from '../assets/images/icons/my-wallet.png';
import ReferralOrderIcon from '../assets/images/icons/refer-order.png';
import MyOrderIcon from '../assets/images/icons/my-order.png';
import HashPowerIcon from '../assets/images/icons/hashpower.png';
import SettingIcon from '../assets/images/icons/setting.png';
import CustomerServiceIcon from '../assets/images/icons/customer-service.png';
import SignOutIcon from '../assets/images/icons/sign-out.png';
import AffiliateIcon from '../assets/images/icons/affiliate.png';
import WarningImg from '../assets/images/warning.png';

interface Props {
    style?: React.CSSProperties;
    children: any;
}

interface MenuItem {
    title: string;
    path: string;
    icon?: any;
    params?: any;
}

export const MENU_LIST: MenuItem[] = [
    {
        title: 'Dashboard',
        path: PATH.dashboard,
        icon: DashboardIcon,
    },
    {
        title: 'Activities',
        path: PATH.activity,
        icon: ActivityIcon,
    },
    {
        title: 'My Wallet',
        path: PATH.myWallet,
        icon: MyWalletIcon,
        params: {
            coinCode: '',
        },
    },
    {
        title: 'Referral rewards',
        path: PATH.referral,
        icon: ReferralOrderIcon,
    },
    {
        title: 'My orders',
        path: PATH.myOrder,
        icon: MyOrderIcon,
    },
];

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
        backgroundColor: theme.palette.neutral.main,
        flexWrap: 'initial',
        fontFamily: 'Poppins',
        '& .MuiContainer-root': {
            padding: 0,
        },
    },
    logo: {
        maxWidth: 326,
        width: '100%',
    },
    headerContainer: {
        backgroundColor: theme.palette.neutral.hashrate,
        color: theme.palette.neutral.white,
        boxShadow: 'none',
    },
    headerLogo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 40,
        padding: 0,
    },
    headerNav: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 'auto',
        padding: 0,
    },
    headerNavHeight: {
        height: 60,
        '& .buy-hashpower-btn': {
            width: 200,
            height: 44,
            boxShadow: '0 4px 8px 0 rgba(19, 14, 70, 0.2)',
        },
    },
    navBar: {
        '& ul': {
            listStyleType: 'none',
            padding: 0,
            margin: 0,
            display: 'flex',
            '& li': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: 145,
                height: 60,
                padding: '0 15px',
                position: 'relative',
                cursor: 'pointer',
                '&.active': {
                    borderBottom: `2px solid ${theme.palette.neutral.white}`,
                    '&:after': {
                        content: 'close-quote',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        opacity: 0.15,
                        boxShadow: '0 8px 16px 0 rgba(32, 58, 191, 0.2)',
                        backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), ${theme.palette.neutral.white})`,
                        left: 0,
                        top: 0,
                    },
                },
                '& img': {
                    width: 24,
                    marginRight: 12,
                },
            },
        },
    },
    contentWrapper: {
        color: theme.palette.neutral.white,
        padding: '0px 15px !important',
        '& .full-width': {
            width: '100%',
        },
        '& .MuiGrid-grid-md-4': {
            width: '100%',
        },
        '& .MuiGrid-grid-md-6': {
            width: '100%',
        },
        '& .MuiGrid-grid-md-3': {
            width: '100%',
        },
        '& .MuiGrid-grid-md-10': {
            width: '100%',
        },
        '& .MuiGrid-grid-md-7': {
            width: '100%',
        },
        '& .MuiGrid-grid-md-2': {
            width: '100%',
        },
        '& .MuiGrid-grid-md-5': {
            width: '100%',
        },
    },
    contentHeight: {
        minHeight: 'calc(100vh - 308px)',
    },
    mobileContentHeight: {
        minHeight: 'calc(100vh - 221px)',
    },
    footerWrapper: {
        backgroundColor: theme.palette.neutral.hashrate,
        marginTop: 163,
        '& .wrapper': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 72,
            '&.mobile': {
                justifyContent: 'center',
                flexDirection: 'column',
                height: 91,
                '& .left': {
                    height: 45,
                    borderBottom: `1px solid ${theme.palette.neutral.hashrate}`,
                    justifyContent: 'space-evenly',
                    width: '100%',
                    '& .policy-text': {
                        marginRight: 0,
                    },
                },
                '& .right': {
                    height: 45,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            },
            color: theme.palette.neutral.gray,
            '& .left': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .policy-text': {
                    marginRight: 72,
                    cursor: 'pointer',
                },
                '& .termofuse-text': {
                    cursor: 'pointer',
                },
            },
        },
    },
    paddingContainer: {
        padding: '0px 15px !important',
    },
    logoutConfirm: {
        textAlign: 'center',
        '& img': {
            margin: '32px 0 25px',
        },
        '& .logout-title': {
            fontSize: 20,
            fontWeight: 'bold',
            lineHeight: 1.5,
            letterSpacing: 0.5,
            color: theme.palette.neutral.white,
        },
        '& .logout-subtitle': {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.5,
            letterSpacing: 0.5,
            color: theme.palette.neutral.gray,
            margin: '8px 0 48px',
        },
        '& .mobile-logout-dialog': {
            '& .logout-cancel': {
                width: 120,
                minWidth: 'auto',
            },
            '& .logout-confirm': {
                width: 120,
                minWidth: 'auto',
            },
        },
        '& .logout-cancel': {
            border: `2px solid ${theme.palette.primary.main}`,
            color: `${theme.palette.primary.main} !important`,
            backgroundColor: 'transparent',
            width: 175,
            height: 44,
            marginRight: 12,
        },
        '& .logout-confirm': {
            width: 175,
            height: 44,
            marginLeft: 12,
            color: theme.palette.neutral.white,
        },
    },
    minifyHeader: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: theme.palette.neutral.hashrate,
        color: theme.palette.neutral.white,
        boxShadow: 'none',
        '& .minify-wrapper': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '19px 15px 0',
            '& .minify-logo': {
                width: 61,
                height: 61,
                marginRight: 24,
            },
            '& .minify-nav': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                '& .buy-hashpower-btn': {
                    width: 200,
                    height: 44,
                    boxShadow: '0 4px 8px 0 rgba(19, 14, 70, 0.2)',
                },
            },
        },
    },
    mobileHeader: {
        '& .mobile-wrapper': {
            backgroundColor: theme.palette.neutral.hashrate,
            color: theme.palette.neutral.white,
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 80,
            '& .menu-mobile-left': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .mobile-hamburger': {
                    width: 24,
                    height: 24,
                    marginRight: 18,
                },
                '& .mobile-logo': {
                    height: 24,
                },
            },
            '& .mobile-hamburger': {
                width: 32,
                height: 32,
                marginRight: 18,
                cursor: 'pointer',
            },
            '& .mobile-logo': {
                width: 'auto',
                height: 32,
            },
            '& .mobile-nav': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .buy-hashpower-btn': {
                    width: 200,
                    height: 44,
                    boxShadow: '0 4px 8px 0 rgba(19, 14, 70, 0.2)',
                },
            },
        },
    },
    drawerMenu: {
        '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.neutral.darker,
            color: theme.palette.neutral.white,
            boxShadow: 'none',
            position: 'relative',
            width: 250,
            '& .divider-custom': {
                margin: '0 30px',
                backgroundColor: theme.palette.neutral.purple3,
            },
            '& .menu-custom': {
                display: 'block',
                paddingTop: 60,
                paddingLeft: 30,
            },
            '& .custom-tag': {
                color: theme.palette.semantic.main,
            },
            '& ul.menu-nav': {
                display: 'block',
                paddingTop: 20,
                '& li': {
                    justifyContent: 'flex-start',
                    padding: 0,
                    minWidth: 218,
                    paddingLeft: 30,
                    marginBottom: 8,
                    borderLeft: `2px solid transparent`,
                    '&.active': {
                        borderBottom: 'none',
                        borderColor: theme.palette.neutral.white,
                        '&:after': {
                            backgroundImage: `linear-gradient(to left, rgba(255, 255, 255, 0), ${theme.palette.neutral.white})`,
                        },
                    },
                },
            },
            '& .close-menu-btn': {
                width: 32,
                height: 32,
                position: 'absolute',
                top: 28,
                left: 30,
                cursor: 'pointer',
            },
        },
    },
}));

const DEFAULT_HEADER_HEIGHT = 186;
const BREAK_POINT = 1120;
export const PageLayout = (props: Props) => {
    const classes = useStyles();
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
    const [showMinifyHeader, setShowMinifyHeader] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const gotoBuyHashpower = () => {
        sessionStorage.removeItem('orders');
        pushTo(PATH.selectPlansPurchase);
    };

    const handleSignout = () => {
        localStorage.clear();
        pushTo(PATH.login);
    };

    const gotoPrivacyPage = (event: React.ChangeEvent<any>) => {
        event.stopPropagation();
        event.preventDefault();
        window.open('/pdf/Expert-Mining-Privacy-Policy.pdf', '_blank');
    };

    const gotoTermOfUse = (event: React.ChangeEvent<any>) => {
        event.stopPropagation();
        event.preventDefault();
        window.open('/pdf/Expert-Mining-Terms-of-Service.pdf', '_blank');
    };

    useLayoutEffect(() => {
        let scrollPos = window.scrollY;
        const onScroll = () => {
            scrollPos = window.scrollY;
            if (scrollPos >= DEFAULT_HEADER_HEIGHT) {
                setShowMinifyHeader(true);
                return;
            }
            setShowMinifyHeader(false);
        };

        if (scrollPos >= DEFAULT_HEADER_HEIGHT) {
            setShowMinifyHeader(true);
        } else {
            setShowMinifyHeader(false);
        }
        /////
        let windowWidth = window.outerWidth;
        const onResize = () => {
            windowWidth = window.outerWidth;
            if (windowWidth < BREAK_POINT) {
                setShowMobileMenu(true);
            } else {
                setShowMobileMenu(false);
            }
            if (windowWidth <= MOBILE_WIDTH) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        if (windowWidth < BREAK_POINT) {
            setShowMobileMenu(true);
        } else {
            setShowMobileMenu(false);
        }
        if (windowWidth <= MOBILE_WIDTH) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }

        ///
        window.addEventListener('scroll', onScroll);
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('scroll', onScroll);
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return (
        <Box className={classes.container}>
            <Grid container>
                <Grid item xs={12}>
                    <AppBar
                        position="relative"
                        className={
                            !showMobileMenu
                                ? classes.headerContainer
                                : classes.mobileHeader
                        }
                        elevation={0}
                    >
                        {!showMobileMenu ? (
                            <DefaultHeader
                                gotoBuyHashpower={gotoBuyHashpower}
                                isMobile={isMobile}
                                setShowLogoutConfirmation={
                                    setShowLogoutConfirmation
                                }
                            />
                        ) : (
                            <MobileHeader
                                gotoBuyHashpower={gotoBuyHashpower}
                                isMobile={isMobile}
                                setShowLogoutConfirmation={
                                    setShowLogoutConfirmation
                                }
                            />
                        )}
                    </AppBar>
                </Grid>
                {!showMobileMenu ? (
                    <Fade in={showMinifyHeader} timeout={300}>
                        <MinifyHeader
                            gotoBuyHashpower={gotoBuyHashpower}
                            showMinifyHeader={showMinifyHeader}
                        />
                    </Fade>
                ) : null}

                <Grid
                    item
                    xs={12}
                    className={
                        isMobile
                            ? classes.mobileContentHeight
                            : classes.contentHeight
                    }
                >
                    <Container maxWidth="lg" className={classes.contentWrapper}>
                        {props.children}
                    </Container>
                </Grid>
                <Grid item xs={12} className={classes.footerWrapper}>
                    <Container
                        maxWidth="lg"
                        className={classes.paddingContainer}
                    >
                        <Box className={`wrapper ${isMobile ? 'mobile' : ''}`}>
                            <Box className="left">
                                <Typography
                                    className="policy-text"
                                    onClick={gotoPrivacyPage}
                                >
                                    Privacy Policy
                                </Typography>
                                <Typography
                                    className="termofuse-text"
                                    onClick={gotoTermOfUse}
                                >
                                    Term of Use
                                </Typography>
                            </Box>
                            <Box className="right">
                                <Typography>
                                    Copyright 2020 All Rights Reserved
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                </Grid>
                <Modal open={showLogoutConfirmation} maxWidth="xs">
                    <Box className={classes.logoutConfirm}>
                        <img src={WarningImg} alt="want logout?" />
                        <Typography variant="h4" className="logout-title">
                            You are going to Sign Out!
                        </Typography>
                        <Typography className="logout-subtitle">
                            Are you sure want to sign out?
                        </Typography>
                        <Box className={isMobile ? 'mobile-logout-dialog' : ''}>
                            <Button
                                className="logout-cancel"
                                variant="contained"
                                disableElevation
                                onClick={() => setShowLogoutConfirmation(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="logout-confirm"
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={handleSignout}
                            >
                                Yes
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Grid>
        </Box>
    );
};

interface IDefaultHeaderProps {
    setShowLogoutConfirmation: (active: boolean) => void;
    gotoBuyHashpower: () => void;
    isMobile?: boolean;
}
const DefaultHeader = ({
    setShowLogoutConfirmation,
    gotoBuyHashpower,
    isMobile,
}: IDefaultHeaderProps) => {
    const classes = useStyles();
    return (
        <Container maxWidth="lg" className={classes.paddingContainer}>
            <Toolbar className={classes.headerLogo}>
                <img
                    alt="expert mining"
                    src={LogoImg}
                    className={classes.logo}
                />
                <UserToolbar
                    setShowLogoutConfirmation={setShowLogoutConfirmation}
                    isMobile={isMobile}
                />
            </Toolbar>
            <Toolbar className={classes.headerNav}>
                <Navbar />
                <Box className={classes.headerNavHeight}>
                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        startIcon={
                            <img
                                alt="expert mining hashpower"
                                src={HashPowerIcon}
                            />
                        }
                        className="buy-hashpower-btn"
                        onClick={gotoBuyHashpower}
                    >
                        Buy Hashpower
                    </Button>
                </Box>
            </Toolbar>
        </Container>
    );
};

interface IMinifyHeaderProps {
    gotoBuyHashpower: () => void;
    showMinifyHeader: boolean;
}
const MinifyHeader = ({
    gotoBuyHashpower,
    showMinifyHeader,
}: IMinifyHeaderProps) => {
    const classes = useStyles();
    return (
        <AppBar
            position="fixed"
            className={classes.minifyHeader}
            style={{
                opacity: showMinifyHeader ? 1 : 0,
                pointerEvents: showMinifyHeader ? 'auto' : 'none',
            }}
        >
            <Container maxWidth="lg">
                <Toolbar className="minify-wrapper">
                    <img
                        alt="expert mining"
                        src={LogoIcon}
                        className="minify-logo"
                    />
                    <Box className="minify-nav">
                        <Navbar />
                        <Box className="minify-btn">
                            <Button
                                variant="contained"
                                disableElevation
                                color="primary"
                                startIcon={
                                    <img
                                        alt="expert mining hashpower"
                                        src={HashPowerIcon}
                                    />
                                }
                                className="buy-hashpower-btn"
                                onClick={gotoBuyHashpower}
                            >
                                Buy Hashpower
                            </Button>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

const MobileHeader = ({
    setShowLogoutConfirmation,
    gotoBuyHashpower,
    isMobile,
}: IDefaultHeaderProps) => {
    const classes = useStyles();
    const [openMenu, setOpenMenu] = useState(false);
    const iOS =
        process.platform && /iPad|iPhone|iPod/.test(navigator.userAgent);
    return (
        <Container maxWidth="lg">
            <Toolbar className="mobile-wrapper">
                <Box className={isMobile ? 'menu-mobile-left' : ''}>
                    <img
                        alt="expert mining menubar"
                        src={HamburgerIcon}
                        className="mobile-hamburger"
                        onClick={() => setOpenMenu(!openMenu)}
                    />

                    <img
                        alt="expert mining"
                        src={isMobile ? LogoMinifyImg : LogoImg}
                        className="mobile-logo"
                    />
                </Box>
                {isMobile ? (
                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        startIcon={
                            isMobile ? null : (
                                <img
                                    alt="expert mining hashpower"
                                    src={HashPowerIcon}
                                />
                            )
                        }
                        className="buy-hashpower-btn"
                        onClick={gotoBuyHashpower}
                    >
                        Buy Hashpower
                    </Button>
                ) : (
                    <Box className="mobile-nav">
                        <Box className="mobile-btn">
                            <Button
                                variant="contained"
                                disableElevation
                                color="primary"
                                startIcon={
                                    <img
                                        alt="expert mining hashpower"
                                        src={HashPowerIcon}
                                    />
                                }
                                className="buy-hashpower-btn"
                                onClick={gotoBuyHashpower}
                            >
                                Buy Hashpower
                            </Button>
                        </Box>
                        <UserToolbar
                            setShowLogoutConfirmation={
                                setShowLogoutConfirmation
                            }
                            isMobile={isMobile}
                        />
                    </Box>
                )}
            </Toolbar>
            <SwipeableDrawer
                anchor="left"
                open={openMenu}
                onClose={() => setOpenMenu(false)}
                onOpen={() => setOpenMenu(true)}
                className={classes.drawerMenu}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                disableSwipeToOpen={true}
            >
                <img
                    alt="expert mining close menu"
                    src={CloseIcon}
                    className="close-menu-btn"
                    onClick={() => setOpenMenu(false)}
                />

                <Navbar
                    menu={true}
                    setShowLogoutConfirmation={setShowLogoutConfirmation}
                />
            </SwipeableDrawer>
        </Container>
    );
};

interface INavbarProps {
    setShowLogoutConfirmation?: (active: boolean) => void;
    menu?: boolean;
}
const Navbar = (props: INavbarProps) => {
    const classes = useStyles();
    const {pathname} = useLocation();
    const {setShowLogoutConfirmation, menu} = props;
    const {userProfile} = useSelector((state: StoreState) => state);
    const onClickMenuItem = (item: MenuItem) => {
        pushTo(item.path, item.params ?? {});
    };
    const gotoPath = (path: string) => {
        pushTo(path);
    };

    return (
        <nav className={classes.navBar}>
            {menu && userProfile && (
                <div className="menu-custom">
                    <h4>
                        {userProfile.firstName && userProfile.lastName
                            ? truncate(
                                  `${userProfile.firstName} ${userProfile.lastName}`,
                                  {
                                      length: 11,
                                  },
                              )
                            : truncate(userProfile?.email, {
                                  length: 11,
                              }) ?? 'N/A'}
                    </h4>
                    <div>
                        {truncate(`${userProfile.email}`, {
                            length: 20,
                        })}
                    </div>
                </div>
            )}
            <ul className="menu-nav">
                <Divider className="divider-custom" />
                {MENU_LIST.map((item, index) => (
                    <li
                        className={
                            item.path.indexOf(pathname) >= 0 ? 'active' : ''
                        }
                        onClick={(_e) => onClickMenuItem(item)}
                        key={index}
                    >
                        <img
                            alt={`expert mining dashboard ${item.title}`}
                            src={item.icon}
                        />
                        {item.title}
                    </li>
                ))}
                {menu && (
                    <>
                        <Divider className="divider-custom" />
                        <li
                            className={
                                PATH.generalInformation.indexOf(pathname) >= 0
                                    ? 'active'
                                    : ''
                            }
                            onClick={() => gotoPath(PATH.generalInformation)}
                        >
                            <img
                                alt="expert mining setting"
                                src={SettingIcon}
                            />
                            Setting
                        </li>
                        <li
                            className={
                                PATH.affiliate.indexOf(pathname) >= 0
                                    ? 'active'
                                    : ''
                            }
                            onClick={() => gotoPath(PATH.affiliate)}
                        >
                            <img
                                alt="expert mining affiliate"
                                src={AffiliateIcon}
                            />
                            Affiliate
                        </li>
                        <li
                            className={
                                PATH.customerservice.indexOf(pathname) >= 0
                                    ? 'active'
                                    : ''
                            }
                            onClick={() => gotoPath(PATH.customerservice)}
                        >
                            <img
                                alt="expert mining customer service"
                                src={CustomerServiceIcon}
                            />
                            Customer Service
                        </li>
                        <Divider className="divider-custom" />
                        <li
                            onClick={() => {
                                if (setShowLogoutConfirmation) {
                                    setShowLogoutConfirmation(true);
                                }
                            }}
                        >
                            <img
                                alt="expert mining sign out"
                                src={SignOutIcon}
                            />
                            <span className="custom-tag">Sign Out</span>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    );
};

interface IUserToolbarProps {
    setShowLogoutConfirmation: (active: boolean) => void;
    isMobile?: boolean;
}
const UserToolbar = (props: IUserToolbarProps) => {
    const {setShowLogoutConfirmation, isMobile} = props;
    const {userProfile} = useSelector((state: StoreState) => state);
    const dropDownRef = useRef<any>();

    const gotoPath = (path: string) => {
        pushTo(path);
    };

    let buttonContent =
        userProfile && userProfile.firstName && userProfile.lastName
            ? `${userProfile.firstName} ${userProfile.lastName}`
            : userProfile?.email ?? 'N/A';
    if (isMobile) {
        buttonContent = truncate(buttonContent, {
            length: 11,
        });
    }

    return (
        <Dropdown buttonContent={buttonContent} ref={dropDownRef}>
            <MenuItem onClick={() => gotoPath(PATH.generalInformation)}>
                <Button
                    fullWidth
                    disableElevation
                    className="toolbar-item"
                    startIcon={
                        <img alt="expert mining setting" src={SettingIcon} />
                    }
                >
                    Setting
                </Button>
            </MenuItem>
            <MenuItem onClick={() => gotoPath(PATH.affiliate)}>
                <Button
                    fullWidth
                    disableElevation
                    className="toolbar-item"
                    startIcon={
                        <img
                            alt="expert mining affiliate"
                            src={AffiliateIcon}
                        />
                    }
                >
                    Affiliate
                </Button>
            </MenuItem>
            <MenuItem onClick={() => gotoPath(PATH.customerservice)}>
                <Button
                    fullWidth
                    disableElevation
                    className="toolbar-item"
                    startIcon={
                        <img
                            alt="expert mining customer service"
                            src={CustomerServiceIcon}
                        />
                    }
                >
                    Customer Service
                </Button>
            </MenuItem>
            <MenuItem className="divider" />
            <MenuItem onClick={() => setShowLogoutConfirmation(true)}>
                <Button
                    fullWidth
                    disableElevation
                    className="toolbar-item"
                    startIcon={
                        <img alt="expert mining sign out" src={SignOutIcon} />
                    }
                >
                    Sign Out
                </Button>
            </MenuItem>
        </Dropdown>
    );
};
