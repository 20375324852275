import {SET_COIN, ISetCoinAction} from '../actions/coin';
import {ICoin} from '../types/coin';

export const coins = (state: ICoin[] = [], action: ISetCoinAction) => {
    if (action.type === SET_COIN) {
        return action.payload;
    }
    /// default state
    return state;
};
