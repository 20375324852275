import axios from 'axios';
import {REACT_APP_API_URL} from '../helpers/environment';
import {pushTo} from '../helpers/history';
import {PATH} from '../constants/path';

export const initialInterceptor = () => {
    axios.interceptors.request.use((config) => {
        const token = localStorage.getItem('token') || '';
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        config.url = REACT_APP_API_URL + config.url;
        config.headers['Access-Control-Allow-Origin'] = '*';
        return config;
    });
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                pushTo(PATH.login);
                return Promise.reject('Unauthorized');
            }
            return Promise.reject(error);
        },
    );
};
