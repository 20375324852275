import {store} from '..';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {ICurrency} from '../types/currency';
import {setCurrencyAction} from '../actions/currency';
import {fireErrorNotification} from '../helpers/notification';

export const getCurrency = () => {
    Axios.get('/Currency')
        .then((response: AxiosResponse<ICurrency[]>) => {
            store.dispatch(setCurrencyAction(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
