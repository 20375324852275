import React, {useState, useEffect} from 'react';
import {PageLayout} from '../../components/PageLayout';
import Table from '../../components/Table';
import {Grid, makeStyles, Box, Typography} from '@material-ui/core';
import {
    StatusBalanced,
    StatusSendToWallet,
    MiningMissing,
} from './FrequentlyQuestion';
import {getTransactions} from '../../middlewares/transaction';
import {getMiningData} from '../../middlewares/user';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {ITransactionData} from '../../types/transaction';
import {DEFAULT_PAGE_SIZE} from '../../constants/pagination';
import moment from 'moment';
import {isEmpty, truncate} from 'lodash';
import HashPowerIcon from '../../assets/images/icons/hashpower.png';
import HashpowerImg from '../../assets/images/hashpower.png';
import BitCoinImg from '../../assets/images/bitcoin.svg';
import {pushTo} from '../../helpers/history';
import {toFixedFloor} from '../../helpers/common';
import {PATH} from '../../constants/path';
import Button from '../../components/Button';
import {MOBILE_WIDTH} from '../../constants/common';

const useStyles = makeStyles((theme) => ({
    hashrateContainer: {
        '& h2': {
            fontSize: 32,
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 0.6,
            marginBottom: 9,
            marginTop: 81,
            '&.hashrate-value': {
                letterSpacing: 0.4,
                lineHeight: 1.5,
                fontSize: 24,
                marginBottom: 0,
                marginTop: 0,
            },
            '&.title-mobile': {
                marginBottom: 0,
                marginTop: 45,
                fontSize: 24,
            },
        },
        '& .hashrate-item': {
            backgroundColor: theme.palette.neutral.hashrate,
            borderRadius: 4,
            position: 'relative',
            padding: '25px 32px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            '& .hashrate-icon': {
                backgroundColor: theme.palette.neutral.hashrate,
                width: 48,
                height: 48,
            },
            '& .hashrate-name': {
                fontSize: 16,
                fontWeight: 600,
                lineHeight: 1.88,
                letterSpacing: 0.4,
                marginBottom: 24,
                marginRight: 10,
            },
            '&.mobile': {
                padding: 8,
                '& .hashrate-name': {
                    fontSize: 12,
                },
                '& .hashrate-value': {
                    fontSize: 16,
                },
                '& .hashrate-icon': {
                    width: 25,
                    height: 25,
                },
                '& .iconwrapper-mobile': {
                    position: 'absolute',
                    top: 7,
                    right: 7,
                },
            },
        },
        '& .row': {
            padding: '0 15px',
        },
        '& .empty-hashrate': {
            margin: '50px 0',
            textAlign: 'center',
            '& h4': {
                fontSize: 24,
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.5,
                letterSpacing: 0.4,
                color: theme.palette.neutral.white,
                marginBottom: 12,
            },
            '& h5': {
                fontSize: 16,
                fontWeight: 500,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.5,
                letterSpacing: 0.3,
                color: theme.palette.neutral.gray,
                marginBottom: 16,
            },
        },
    },
    section: {
        '& .wrapper': {
            marginTop: 50,
            marginBottom: 30,
            '& .title': {
                fontSize: 32,
                fontWeight: 'bold',
                letterSpacing: 0.6,
                color: theme.palette.neutral.white,
                '&.mobile': {
                    fontSize: 24,
                },
            },
        },
        '& .amount-style': {
            backgroundColor: 'rgba(255,255,255, 0.36)',
            padding: '4px 11px',
            borderRadius: 16,
            whiteSpace: 'nowrap',
        },
        '& .no-underline': {
            textDecoration: 'none',
        },
        '& .item': {
            backgroundColor: theme.palette.neutral.hashrate,
            padding: '20px 24px 20px 32px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: 4,
            '& .item-name': {
                fontSize: 16,
                fontWeight: 500,
                lineHeight: 1.75,
                letterSpacing: 0.3,
                color: theme.palette.neutral.white,
                marginBottom: 20,
            },
            '& .item-code': {
                fontSize: 16,
                fontWeight: 500,
                lineHeight: 1.5,
                letterSpacing: 0.5,
                color: theme.palette.neutral.white,
                backgroundColor: 'rgba(255,255,255, 0.36)',
                borderRadius: 16,
                padding: '6px 16px',
            },
            '& .item-balance-title': {
                fontSize: 12,
                fontWeight: 500,
                lineHeight: 1.67,
                letterSpacing: 0.38,
                color: theme.palette.neutral.lighter,
                textAlign: 'right',
                marginBottom: 20,
            },
            '& .item-balance': {
                fontSize: 16,
                fontWeight: 500,
                lineHeight: 1.5,
                letterSpacing: 0.5,
                color: theme.palette.neutral.white,
                textAlign: 'right',
            },
        },
    },
    additionalInfo: {
        marginTop: 15,
        borderRadius: 4,
        backgroundColor: theme.palette.neutral.hashrate,
        padding: '20px 24px',
        '& .title': {
            fontWeight: 600,
            lineHeight: 1.88,
            letterSpacing: 0.4,
            color: theme.palette.neutral.white,
            marginBottom: 7,
        },
        '& ul': {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
            '& li': {
                fontSize: 14,
                fontWeight: 500,
                lineHeight: 1.57,
                letterSpacing: 0.24,
                color: theme.palette.neutral.gray,
                '& .highlight': {
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                },
            },
        },
    },
}));

interface ICoinBalance {
    name: string;
    code: string;
    balance: string | number;
}

export const Activity = () => {
    const classes = useStyles();
    const {userBalance, transaction, userMining} = useSelector(
        (state: StoreState) => state,
    );
    const [isEmptyHashrate, setIsEmptyHashrate] = useState(false);
    const [coinsBalance, setCoinsBalance] = useState<ICoinBalance[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [transactionColumns] = useState([
        {
            name: 'createAt',
            title: 'Created At',
            width: '35%',
            renderer: (_record: ITransactionData, value: string) =>
                moment(value).format('LLLL'),
        },
        {
            name: 'amount',
            title: 'Amount',
            width: '15%',
            renderer: (record: ITransactionData, value: number) => {
                return (
                    <span className="amount-style">{`${value} ${
                        record.order?.coin.code ?? record.withdraw?.coin.code
                    }`}</span>
                );
            },
        },
        {
            name: 'hash',
            title: 'TxHash',
            width: '35%',
            renderer: (_record: ITransactionData, value: string) => {
                return truncate(value);
            },
        },
        {
            title: 'Transaction Link',
            name: 'url',
            width: '15%',
            renderer: (_record: ITransactionData, value: string) => {
                if (value) {
                    return (
                        <a
                            href={value}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="no-underline"
                        >
                            Transaction Link
                        </a>
                    );
                }
                return 'N/A';
            },
        },
    ]);

    useEffect(() => {
        getTransactions({
            PageSize: DEFAULT_PAGE_SIZE,
            PageNumber: pageNumber,
        });
    }, [pageNumber]);

    useEffect(() => {
        getMiningData();
    }, []);

    useEffect(() => {
        if (!isEmpty(userMining)) {
            const totalPower = userMining.reduce((total, data) => {
                return (total += data.power);
            }, 0);
            if (totalPower === 0) {
                /// empty hashrate
                setIsEmptyHashrate(true);
            }
        }
    }, [userMining, setIsEmptyHashrate]);

    const onChangePage = (pageNumber: number) => {
        if (pageNumber < 1 || pageNumber > transaction.pageInfo.totalPages) {
            return; /// do nothing
        }
        setPageNumber(pageNumber);
    };

    const gotoBuyHashpower = () => {
        pushTo(PATH.selectPlansPurchase);
    };

    const gotoFAQPage = (event: React.ChangeEvent<any>) => {
        event.stopPropagation();
        event.preventDefault();
        window.open('/pdf/Expert-Mining-FAQs.pdf', '_blank');
    };

    useEffect(() => {
        if (!isEmpty(userBalance)) {
            const balances = userBalance.map((userCoin) => {
                return {
                    name: userCoin.coin.name,
                    code: userCoin.coin.code,
                    balance: toFixedFloor(
                        userCoin.amount,
                        userCoin.coin.decimals,
                    ),
                };
            });
            setCoinsBalance(balances);
        }
    }, [userBalance]);

    const isMobile = window.outerWidth <= MOBILE_WIDTH;

    return (
        <PageLayout>
            <Grid container className={classes.hashrateContainer} spacing={3}>
                <Grid item xs={12} className="row">
                    <Typography
                        variant="h2"
                        className={`${isMobile ? 'title-mobile' : ''}`}
                    >
                        Hashrate
                    </Typography>
                </Grid>
                {isEmptyHashrate ? (
                    <Grid item xs={12}>
                        <Box className="empty-hashrate">
                            <img src={HashpowerImg} alt="buy hashpower" />
                            <Typography variant="h4">
                                {' '}
                                Ready to start your career{' '}
                            </Typography>
                            <Typography variant="h5">
                                {' '}
                                Choose a plan to start expert mining{' '}
                            </Typography>
                            <Button
                                variant="contained"
                                disableElevation
                                color="primary"
                                startIcon={
                                    <img
                                        alt="expert mining hashpower"
                                        src={HashPowerIcon}
                                    />
                                }
                                onClick={gotoBuyHashpower}
                            >
                                Buy Hashpower
                            </Button>
                        </Box>
                    </Grid>
                ) : (
                    userMining.map((data, index) => (
                        <Grid item md={3} xs={6} key={index}>
                            <Box
                                className={`hashrate-item ${
                                    isMobile ? 'mobile' : ''
                                }`}
                            >
                                <Box>
                                    <Typography
                                        variant="h4"
                                        className="hashrate-name"
                                    >
                                        {data.coin.name}{' '}
                                        {data.type ? `(${data.type})` : ''}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        className="hashrate-value"
                                    >
                                        {data.power.toFixed(4)} {data.powerUnit}
                                    </Typography>
                                </Box>
                                <Box
                                    className={`${
                                        isMobile ? 'iconwrapper-mobile' : ''
                                    }`}
                                >
                                    <img
                                        src={data.coin.image ?? BitCoinImg}
                                        className="hashrate-icon"
                                        alt="hashrate-icon"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    ))
                )}
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} className={classes.section}>
                    <Box className="wrapper">
                        <Typography
                            variant="h1"
                            className={`title ${isMobile ? 'mobile' : ''}`}
                        >
                            {isMobile
                                ? 'My pending balance'
                                : `My pending balance of mining outputs (paid with
                            credit card)`}
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={3}>
                            {coinsBalance.map((coin, index) => (
                                <Grid item md={4} key={index}>
                                    <Box className="item">
                                        <Box>
                                            <Typography className="item-name">
                                                {coin.name}
                                            </Typography>
                                            <span className="item-code">
                                                {coin.code}
                                            </span>
                                        </Box>
                                        <Box>
                                            <Typography className="item-balance-title">
                                                Balance
                                            </Typography>
                                            <span className="item-balance">
                                                {coin.balance}
                                            </span>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} className={classes.section}>
                    <Box className="wrapper">
                        <Typography
                            variant="h1"
                            className={`title ${isMobile ? 'mobile' : ''}`}
                        >
                            My activity
                        </Typography>
                    </Box>
                    <Box>
                        <Table
                            columns={transactionColumns}
                            data={transaction.data}
                            noDataTitle="No activities"
                            onChangePage={onChangePage}
                            pageInfo={transaction.pageInfo}
                            isLoading={transaction.isLoading}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} className={classes.section}>
                    <Box className="wrapper">
                        <Typography
                            variant="h1"
                            className={`title ${isMobile ? 'mobile' : ''}`}
                        >
                            Quick Information:
                        </Typography>
                    </Box>
                    <Box>
                        <Box>
                            <StatusBalanced isMobile={isMobile} />
                            <StatusSendToWallet isMobile={isMobile} />
                            <MiningMissing isMobile={isMobile} />
                        </Box>
                    </Box>
                    <Box className={classes.additionalInfo}>
                        <Typography className="title">
                            Additional Information:
                        </Typography>
                        <ul>
                            <li>
                                To get more info about our services, you can
                                visit our{' '}
                                <span
                                    className="highlight"
                                    onClick={gotoFAQPage}
                                >
                                    FAQ
                                </span>
                            </li>
                            <li>
                                For updates, please see our{' '}
                                <span className="highlight">Twitter feed</span>
                            </li>
                        </ul>
                    </Box>
                </Grid>
            </Grid>
        </PageLayout>
    );
};
