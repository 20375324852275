import {store} from '..';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {IRank} from '../types/rank';
import {setRankAction} from '../actions/rank';
import {fireErrorNotification} from '../helpers/notification';

export const getRanks = () => {
    Axios.get('/Rank')
        .then((response: AxiosResponse<IRank[]>) => {
            store.dispatch(setRankAction(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
