import {Notification} from '../types/notification';

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export type UPDATE_NOTIFICATION = typeof UPDATE_NOTIFICATION;

export interface updateNotificationAction {
    type: UPDATE_NOTIFICATION;
    payload: Notification;
}
export const updateNotificationAction = (
    payload: Notification,
): updateNotificationAction => {
    return {
        type: UPDATE_NOTIFICATION,
        payload,
    };
};
