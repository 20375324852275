import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    comboBox: {
        width: '100%',
        '& .MuiAutocomplete-inputRoot': {
            padding: 2,
        },
        '& .MuiAutocomplete-endAdornment': {
            '& .MuiIconButton-root': {
                color: theme.palette.neutral.white,
            },
        },
    },
}));

interface IProps {
    label?: string;
    placeholder?: string;
    freeSolo?: boolean;
    options: IComboBoxOption[];
    onChange: (value: string | number) => void;
    value: any;
    onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    hasError?: boolean;
}
export interface IComboBoxOption {
    label: string;
    value: string | number;
}

export const ComboBox = (props: IProps) => {
    const classes = useStyles();
    const {
        options,
        placeholder,
        label,
        freeSolo,
        value,
        onChange,
        onKeyPress,
        hasError,
        ...others
    } = props;
    return (
        <Autocomplete
            freeSolo={freeSolo}
            id="combo-box-demo"
            options={options}
            inputValue={value}
            onInputChange={(_event: React.ChangeEvent<any>, newInputValue) => {
                onChange(newInputValue);
            }}
            getOptionLabel={(option) => option.label ?? value}
            className={classes.comboBox}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onKeyPress={onKeyPress}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    placeholder={placeholder ?? ''}
                    label={label}
                    variant="outlined"
                    error={hasError}
                />
            )}
            {...others}
        />
    );
};
