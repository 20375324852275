import React from 'react';
import {TreeView, TreeItem} from '@material-ui/lab';
import {useStyles, QuestionInterface} from '../affiliate/FrequentlyQuestion';

export const StatusBalanced = ({isMobile}: QuestionInterface) => {
    const classes = useStyles();
    return (
        <TreeView
            className={isMobile ? classes.mobiletreeView : classes.treeView}
            defaultCollapseIcon={'1'}
            defaultExpandIcon={'1'}
        >
            <TreeItem
                className={isMobile ? classes.mobileQuestion : classes.question}
                nodeId="1"
                label="When Your Mining Output Status Reflects “Balance”"
            >
                <TreeItem
                    className={isMobile ? classes.mobileAnswer : classes.answer}
                    nodeId="2"
                    label={
                        <div>
                            <p>
                                That means your mining output is below the
                                transaction threshold amount you chose when you
                                signed up. The system will add the amount to
                                your overall coin (Ethereum, Bitcoin, Bitcoin
                                Radiant) balance (you can check your total
                                balance when you hover your cursor over the
                                "info" symbol). As soon as your balance reaches
                                the threshold amount, we will transfer the coins
                                to your wallet.
                            </p>
                            <p>
                                We have set the minimum transaction threshold
                                since a large number of small transfers will
                                incur higher fees for our customers when they
                                send coins to third parties (resulting from a
                                larger number of inputs). Moreover, some web
                                wallets do not allow the receipt of small
                                amounts.
                            </p>
                            <p>
                                Remember that the total coin balance amounts are
                                separate for the same type of coin from
                                different contracts (algorithms). We only sum up
                                and send in one transfer (one transaction ID)
                                the outputs that reach the minimum threshold
                                amount on the same day.
                            </p>
                            <p>
                                You can find the minimum transaction thresholds
                                for each coin here.
                            </p>
                            <p>
                                In case you want to adjust the threshold amount,
                                go to the "Settings – Wallets" section, and
                                adjust the amount from there.
                            </p>
                        </div>
                    }
                />
            </TreeItem>
        </TreeView>
    );
};
export const StatusSendToWallet = ({isMobile}: QuestionInterface) => {
    const classes = useStyles();
    return (
        <TreeView
            className={isMobile ? classes.mobiletreeView : classes.treeView}
            defaultCollapseIcon={2}
            defaultExpandIcon={2}
        >
            <TreeItem
                className={isMobile ? classes.mobileQuestion : classes.question}
                nodeId="1"
                label="When Your Mining Output Status Reflects “Sent to Wallet”"
            >
                <TreeItem
                    className={isMobile ? classes.mobileAnswer : classes.answer}
                    nodeId="2"
                    label={
                        <div>
                            <p>
                                That means we have sent your mining output to
                                the last wallet address you entered in the
                                "Wallets" section for that particular coin.
                            </p>
                            <p>
                                Clicking on the "View Transaction" link on the
                                "Transaction Hash" column will allow you to
                                check the transaction on the blockchain.
                            </p>
                            <p>
                                Remember, there will sometimes be delays in the
                                blockchain. So, if you come across an "error"
                                message, please check again at a later time.
                                Rest assured, we have sent out the mining
                                output, and it should arrive in your wallet
                                soon.
                            </p>
                            <p>
                                As soon as we send out the mining output, it
                                stays within the blockchain awaiting
                                confirmation. However, that is a process that we
                                cannot speed up from our end. Also, some wallets
                                only reflect the transactions when they have
                                reached a certain number of blockchain
                                confirmations.
                            </p>
                            <p>
                                In case the issue persists for more than 72
                                hours, please contact our Customer Service.
                            </p>
                        </div>
                    }
                />
            </TreeItem>
        </TreeView>
    );
};
export const MiningMissing = ({isMobile}: QuestionInterface) => {
    const classes = useStyles();
    return (
        <TreeView
            className={isMobile ? classes.mobiletreeView : classes.treeView}
            defaultCollapseIcon={3}
            defaultExpandIcon={3}
        >
            <TreeItem
                className={isMobile ? classes.mobileQuestion : classes.question}
                nodeId="1"
                label="When Your Mining Output Status Reflects “Missing”"
            >
                <TreeItem
                    className={isMobile ? classes.mobileAnswer : classes.answer}
                    nodeId="2"
                    label={
                        <div>
                            <p>
                                That happens rarely, but a mining output can go
                                missing due to several possible reasons. Should
                                that be the case, please contact our Customer
                                Service.
                            </p>
                        </div>
                    }
                />
            </TreeItem>
        </TreeView>
    );
};
