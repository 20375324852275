import {createBrowserHistory} from 'history';
export const history = createBrowserHistory();

export const pushTo = (path: string, params?: {}) => {
    let pathWithParams = path;
    if (params) {
        for (const [key, value] of Object.entries(params)) {
            pathWithParams = pathWithParams.replace(':' + key, value as string);
        }
    }
    history.push(pathWithParams);
};

export const goBack = () => history.goBack();
