import React from 'react';
import {Button, ButtonProps} from '@material-ui/core';
import {PalateColors} from '../theme';

const CustomButton = (props: ButtonProps) => {
    const {disabled, ...other} = props;
    return (
        <Button
            variant="outlined"
            style={{
                fontFamily: 'Poppins',
                fontSize: 16,
                fontWeight: 600,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.88,
                letterSpacing: 0.4,
                textTransform: 'none',
                color: PalateColors.neutral.white,
                opacity: disabled ? 0.5 : 1,
            }}
            disabled={disabled}
            {...other}
        />
    );
};

export default CustomButton;
